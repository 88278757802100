//引入洋蔥字体：已登录
@font-face {
  font-family: 'onion-chinese';
  src: url('./assets/FZLanTYJW_Zhun.woff2') format('woff2'),
    /* chrome, firefox */ url('./assets/FZLanTYJW_Zhun.woff') format('woff'),
    /* chrome, firefox */ url('./assets/FZLanTYJW_Zhun.eot'),
    /* IE9 */ url('./assets/FZLanTYJW_Zhun.ttf') format('truetype'); /* chrome, firefox, opera, Safari, Android, iOS 4.2+ */
}
//@font-face {
//  font-family: 'Noto Sans TC';
//}
@font-face {
  font-family: 'number-bold';
  src: url('./assets/DIN-Bold.ttf') format('truetype'),
    url('./assets/DIN-Bold.otf') format('otf');
}
