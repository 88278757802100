//******************** 班级管理 begin ****************
// 没有学习，添加学习
.no-students {
  padding-top: 20px;
}
// 大标题-2
.headline-2 {
  padding-bottom: 11px;
  text-align: center;
  @extend %font-22;
  @extend %gray-7;
}
// 小标题-2
.subhead-2 {
  text-align: center;
  @extend %font-14;
  @extend %font-normal;
}
.strong-title {
  padding: 0 35px 20px;
  @extend %gray-7;
  @extend %font-16;
}
// 管理内容盒子
.mana-box {
  position: relative;
  .icon-btn-right {
    position: absolute;
    top: 30px;
    right: 32px;
    .button.fab {
      height: 32px;
    }
    button {
      @extend %pull-left;
    }
  }
}
.no-class-box {
  padding-bottom: 70px;
  text-align: center;
  height: 500px;
  padding-top: 80px;
}
.no-class-img {
  margin: 100px auto 51px;
}
.no-class-text-box {
  h3 {
    padding-bottom: 30px;
    @extend %gray-5;
    @extend %font-16;
    &:before {
      width: 0 !important;
    }
  }
}
// 添加学习
.add-students {
  margin: 32px 0 40px 26px;
  @extend %clear-fix;
  li {
    margin: 0 14px;
    width: 306px;
    height: 224px;
    text-align: center;
    @extend %pull-left;
    @extend %white-board-4;
  }
  h3 {
    margin-top: 30px;
    padding-bottom: 12px;
    text-align: center;
    @extend %font-22;
    @extend %gray-7;
  }
  h4 {
    margin: 0 33px;
    line-height: 22px;
    text-align: left;
    @extend %font-14;
    @extend %font-normal;
  }
  .btn-size-16 {
    margin: 23px auto 0;
    width: 266px !important;
  }
}
// 右侧菜单
// 大标题-3
.headline-3 {
  position: relative;
  margin: 30px 25px 11px 30px;
  height: 22px;
  @extend %font-22;
  @extend %gray-7;
  .right-link {
    position: absolute;
    right: 0;
    top: 3px;
    @extend %font-14;
    @extend %blue-5;
    @extend %font-normal;
    @extend %pointer;
  }
}
.no-class {
  padding-left: 30px;
  padding-bottom: 177px;
  @extend %gray-3;
  @extend %font-14;
}
.class-list-tabs {
  padding: 0 32px;
  .ant-tabs-nav .ant-tabs-tab {
    padding-left: 0px;
  }
  .user-nav {
    padding-top: 0px;
  }
  .user-nav li .button {
    padding-left: 0px;
  }
}
// 小标题-3
.subhead-3 {
  margin: 0 0 6px 30px;
  @extend %font-16;
  @extend %font-normal;
}
// 有学生
.have-students {
  margin: 0 35px 40px;
  .icon {
    position: relative;
    top: 2px;
    text-align: center;
    font-size: 19px;
  }
}
// 表格
.table {
  width: 100%;
  th {
    text-align: left;
    border-bottom: 2px solid $gray-2;
    @extend %font-bold;
    @extend %gray-7;
  }
  th:not(:last-child) {
    width: 160px;
    height: 41px;
  }
  th:nth-child(1) {
    padding-left: 15px;
    width: 74px;
    input {
      @extend %pointer;
    }
  }
  td:nth-child(1) {
    padding-left: 15px;
    input {
      @extend %pointer;
    }
  }
  .member-box {
    width: 140px;
    @extend %overflow;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .space-th {
    // padding-left: 10px;
  }
  td {
    height: 42px;
    border-bottom: 1px solid $gray-1;
  }
  tr {
    transition: all 0.5s ease;
  }
  td:last-child {
    padding-left: 10px;
    .icon {
      margin: 0 7px;
      opacity: 0;
      transition: all 0.5s ease;
      @extend %pull-left;
      @extend %gray-5;
      @extend %pointer;
    }
  }
  tr:hover {
    @extend %bg-gray-1;
    td:last-child > .icon {
      opacity: 1;
    }
  }
  // 选中
  .check {
    @extend %blue-5;
  }
  .btn-size-14 {
    position: relative;
    left: -21px;
    @extend %gray-3;
    @extend %font-normal;
    @extend %btn-color-gray-3;
  }
}
// 操作盒子
.operate-box {
  height: 0;
  transition: all 0.5s ease;
  @extend %bg-blue-5;
  @extend %overflow;
  .icon {
    position: relative;
    top: 5px;
  }
  button {
    margin: 3px 8px 0 0;
  }
}
.operate-box.show {
  height: 42px;
}

//******************** 班级管理 end ****************

// ******************** 数据管理-弹出层 begin *********
.sta-b {
  padding-bottom: 16px;
}
// 标题
.state-hd {
  position: relative;
  margin-top: 11px;
  margin-bottom: 10px;
  height: 34px;
  border-radius: 17px;
  @extend %bg-gray-2;
  .icon-hd {
    margin-left: 5px;
    font-size: 34px;
    @extend %gray-4;
    @extend %pull-left;
  }
  .icon-stage {
    position: absolute;
    left: 16px;
    bottom: -10px;
    font-size: 15px;
    @extend %gray-2;
  }
  em {
    line-height: 34px;
    @extend %pull-left;
    @extend %font-16;
    @extend %font-bold;
    @extend %gray-4;
  }
  strong {
    margin-right: 16px;
    line-height: 34px;
    @extend %pull-right;
    @extend %font-16;
    @extend %font-bold;
    @extend %gray-4;
  }
  .help-tea {
    margin-left: 10px;
    padding-top: 8px;
    @extend %pull-left;
    @extend %font-16;
    @extend %font-bold;
    @extend %gray-4;
  }
  &.state-gold {
    @extend %bg-gold-3;
    .icon-hd {
      @extend %gold-5;
    }
    .icon-stage {
      @extend %gold-3;
    }
    em {
      @extend %gold-5;
    }
    strong {
      @extend %gold-5;
    }
    .help-tea {
      @extend %gold-5;
    }
  }
  &.state-blue-3 {
    @extend %bg-blue-3;
    .icon-hd {
      @extend %white;
    }
    .icon-stage {
      @extend %blue-3;
    }
    em {
      @extend %white;
    }
    strong {
      @extend %white;
    }
    .help-tea {
      @extend %white;
    }
  }
  &.state-blue-5 {
    @extend %bg-blue-5;
    .icon-hd {
      @extend %white;
    }
    .icon-stage {
      @extend %blue-5;
    }
    em {
      @extend %white;
    }
    strong {
      @extend %white;
    }
    .help-tea {
      @extend %white;
    }
  }
}
// 列表
.state-box {
  padding-bottom: 5px;
  @extend %clear-fix;
  @extend %overflow;
}
.state-ul {
  position: relative;
  top: -1px;
  @extend %clear-fix;
  li {
    padding: 0 5px 0 11px;
    width: 25%;
    border-top: 1px solid $gray-1;
    height: 35px;
    line-height: 35px;
    @extend %pull-left;
    @extend %overflow;
  }
}

// ******************** 数据管理-弹出层 end *********
// ******************** 数据管理-弹出层-教学目标 begin *********
.dialog-content.dia-practise {
  padding: 30px;
  .task-content {
    margin: 0;
    padding: 0 20px;
    min-height: auto;
    .task-content-left {
      padding-top: 24px;
      width: 290px;
    }
    .task-content-right {
      padding-top: 24px;
      width: 290px;
    }
  }
  //教学目标
  .teach-target {
    min-height: 340px;
    overflow-y: auto;
    transform: translateZ(0);
    @extend %clear-fix;
    @extend %bg-gray-1;
    @extend %radius-12;
  }
  .task-option .svglist {
    left: -36px;
    top: -5px;
  }
  .task-option {
    margin-bottom: 16px;
  }
  .task-num {
    @extend %font-14;
  }
  .answer-div {
    @extend %gray-4;
  }

  // 填空题
  textarea {
    width: 289px;
  }
}
// ************* 多项填空 begin  ***************
// ************* 多项填空 end  ***************
.dia-practise-ft {
  padding: 6px 5px 0 0;
  height: 44px;
  button {
    height: 32px;
    font-size: 14px !important;
    i {
      position: relative;
      left: -6px;
      top: -1px;
      font-size: 26px;
      @extend %pull-left;
    }
  }
}
.dialog-content.dia-practise-2 {
  position: relative;
  padding-bottom: 0;
  .dia-p {
    em {
      margin-right: 10px;
    }
  }
  .practice-sign-box {
    position: absolute;
    top: 30px;
    right: 45px;
    width: 460px;
  }
  .practice-sign {
    margin-left: 20px;
    width: 400px;
  }
  .practice-ul li {
    padding-top: 0;
    text-align: center;
    width: 40px;
    img {
      margin-top: 10px;
      width: 26px;
      height: 26px;
      border: 3px solid $white;
      @extend %radius-50p;
    }
    &.current {
      img[data-color='red'] {
        border: 3px solid $red-2;
      }
      img[data-color='green'] {
        border: 3px solid $green-2;
      }
    }
  }
  .svgteacher {
    font-size: 20px;
  }
}
// ******************** 数据管理-弹出层-教学目标 end *********

// ******************** 提分提示 begin ********************
.concise-img {
  position: relative;
  left: -10px;
  margin-top: 45px;
  width: 270px;
  height: 210px;
  @extend %pull-left;
}
.concise-info {
  width: 360px;

  @extend %pull-right;
  h3 {
    line-height: 24px;
    margin-bottom: 15px;
    @extend %font-22;
    @extend %gray-7;
  }
  p {
    margin-bottom: 10px;
    line-height: 27px;
    @extend %font-16;
  }
  .blue {
    @extend %blue-5;
  }
}

// ******************** 提分提示 end ********************
