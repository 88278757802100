// ************* 视频播放页、视频完成页 begin *************
// 任务板
.task-board {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 860px;
  min-height: 560px;
  //overflow: hidden;
}
// 关闭 文字
.task-close-2 {
  position: absolute;
  right: -56px;
  top: 80px;
  padding-top: 9px;
  width: 56px;
  height: 96px;
  border-radius: 0 12px 12px 0;
  text-align: center;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  @extend %bg-white;
  @extend %pointer;
  i {
    font-size: 26px;
    line-height: 32px;
    @extend %gray-4;
  }
  span {
    line-height: 19px;
    @extend %font-16;
    @extend %gray-4;
    @extend %block;
    @extend %font-bold;
  }
}
// 大内容区域
.task-pos {
  position: relative;
  z-index: 2;
  @extend %clear-fix;
}
// 标题
.task-hd {
  position: relative;
  height: 80px;
  h1 {
    padding-left: 30px;
    width: 420px;
    height: 80px;
    overflow: hidden;
    line-height: 84px;
    @extend %ellipsis;
    @extend %font-24;
    @extend %gray-7;
    @extend %pull-left;
  }
  .test-answer {
    position: absolute;
    top: -18px;
  }
}
// 视频区域
.task-video-box {
  position: relative;
  margin: 0 30px 30px;
  width: 800px;
  min-height: 450px;
  @extend %clear-fix;
}
.finish-box {
  padding-top: 22px;
  height: 480px;
  text-align: center;
  @extend %clear-fix;
  &.add-silk {
    background: url('./assets/finish-bg.svg') no-repeat center center;
  }
  .finish-avatar {
    position: absolute;
    top: 1px;
    left: 50%;
    margin-left: -64px;
    margin-top: 15px;
    width: 128px;
    height: 128px;
    @extend %radius-50p;
  }
  h3 {
    margin-bottom: 40px;
    @extend %font-32;
    @extend %gray-7;
  }
  .blue {
    @extend %blue-5;
  }
  h4 {
    margin-top: 20px;
    margin-bottom: 30px;
    @extend %gray-7;
    @extend %font-22;
  }
  .finish-user-box {
    position: relative;
    width: 160px;
    height: 160px;
    @extend %center;
  }
  .finish-btn-box {
    padding-top: 30px;
    button {
      margin: 0 10px;
    }
  }
  .svg-img {
    position: absolute;
    left: -10px;
    top: -10px;
  }
  .finish-level {
    position: absolute;
    left: 50%;
    margin-left: -38px;
    padding-top: 7px;
    bottom: -8px;
    width: 76px;
    height: 33px;
    line-height: 27px;
    @extend %bg-white;
    @extend %radius-6;
    em {
      width: 62px;
      height: 26px;
      line-height: 27px;
      @extend %bg-blue-5;
      @extend %white;
      @extend %font-16;
      @extend %font-bold;
      @extend %radius-4;
      @extend %block;
      @extend %center;
      @extend %overflow;
    }
  }
  .finish-time-box {
    width: 325px;
    height: 20px;
    @extend %center;
  }
  .finish-com {
    width: 160px;
    height: 20px;
    line-height: 21px;
    @extend %white;
  }
  .finish-time {
    border-radius: 10px 2px 2px 10px;
    @extend %bg-blue-5;
    @extend %pull-left;
  }
  .finish-problem {
    position: relative;
    border-radius: 2px 10px 10px 2px;
    @extend %bg-gray-2;
    @extend %pull-right;
  }
  //积分
  .score-box {
    position: absolute;
    top: 50%;
    margin-top: -13px;
    left: 174px;
    height: 26px;
    width: 100px;
    text-align: left;
    // animation: score-1 .8s easeInOut  1  .4s forwards;
    .icon-score {
      font-size: 28px;
      @extend %blue-5;
    }
    .score-num {
      position: relative;
      top: -7px;
      @extend %font-22;
      @extend %blue-5;
      @extend %font-bold;
    }
  }
}
// 主线视频标题
.study-task-hd {
  position: relative;
  height: 80px;
  h1 {
    padding-left: 30px;
    width: auto;
    height: 80px;
    overflow: hidden;
    line-height: 84px;
    @extend %font-24;
    @extend %gray-7;
    @extend %pull-left;
  }
}
.practice-task-hd {
  position: relative;
  height: 80px;
  h1 {
    padding-left: 30px;
    width: auto;
    height: 80px;
    overflow: hidden;
    line-height: 120px;
    @extend %gray-7;
    @extend %pull-left;
  }
}

#container {
  width: 160px;
  height: 160px;
  transform: rotate(180deg);
}
// 奖励提示
.reward-div {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 300;
  transform: translate3d(-50%, -50%, 0);
  width: 174px;
  height: 96px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @extend %white;
  @extend %black-opcity-8;
  p {
    padding-top: 3px;
  }
  .reward-text {
    padding-top: 2px;
    padding-bottom: 9px;
    @extend %font-18;
  }
  .svgvip {
    position: relative;
    top: -2px;
  }
  .reward-num {
    position: relative;
    top: 2px;
    margin-left: 8px;
    @extend %font-28;
    @extend %font-bold;
    em {
      position: absolute;
      top: -2px;
      right: -14px;
      @extend %font-12;
      @extend %gold-4;
      @extend %font-bold;
    }
  }
}
//继续提示
.proceed-div {
  position: absolute;
  right: -138px;
  top: 50%;
  margin-top: -32px;
  padding: 9px 0 8px 13px;
  width: 127px;
  line-height: 24px;
  text-align: left;
  @extend %font-16;
  @extend %black-opcity-8;
  @extend %white;
  @extend %font-bold;
  &::before {
    position: absolute;
    left: -7px;
    top: 23px;
    width: 7.111111px;
    content: url('./assets/left-horn.svg');
  }
}
// ************* 视频播放页、视频完成页 end *************
