//******************** 添加学生 begin ****************
// 添加学生
// 小标题-4
.subhead-4 {
  padding: 0 0 16px 35px;
  @extend %font-16;
  @extend %font-normal;
}
// 批量添加学生
// 滑块上部与滑块之间的间隙
.mana-box + .slider {
  margin-top: 64px;
  input {
    width: 200px;
  }
}
// 滑块下面的按钮区域
.slider-btn-box {
  padding-top: 82px;
  padding-bottom: 74px;
  text-align: center;
  button {
    margin: 0 auto;
  }
}
// 批量添加学生成功
.blue-box {
  height: 340px;
  border-radius: 10px 10px 0 0;
  @extend %bg-blue-5;
  .headline-1 {
    @extend %white;
  }
  .subhead-4 {
    padding-bottom: 8px;
    @extend %blue-2;
  }
  .mana-box .icon {
    @extend %blue-2;
  }
}
// 焦点图与文字盒子
.img-text-box {
  position: relative;
  height: 230px;
  @extend %clear-fix;
  h3 {
    position: absolute;
    left: 382px;
    top: 47px;
    line-height: 27px;
    @extend %font-22;
    @extend %white;
  }
  p {
    position: absolute;
    left: 382px;
    top: 113px;
    line-height: 22px;
    @extend %white;
  }
  h4 {
    position: absolute;
    top: 87px;
    left: 382px;
    line-height: 28px;
    @extend %font-22;
    @extend %white;
  }
  .num-big {
    position: absolute;
    left: 48px;
    top: 60px;
    width: 250px;
    height: 68px;
    line-height: 73px;
    @extend %font-38;
    @extend %white;
    @extend %text-center;
  }
}
// 图
.add-img-1 {
  position: absolute;
  top: 13px;
  left: 0;
}
// 大标题-4
.headline-4 {
  margin: 40px 0 0 40px;
  line-height: 1;
  @extend %font-26;
  @extend %gray-7;
}
// 小标题-5
.subhead-5 {
  position: relative;
  margin: 37px 40px 0;
  height: 24px;
  h4 {
    line-height: 24px;
    @extend %font-16;
    @extend %gray-7;
    i {
      margin-right: 16px;
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 24px;
      @extend %font-16;
      @extend %white;
      @extend %radius-50p;
      @extend %pull-left;
      @extend %bg-blue-5;
      @extend %font-bold;
    }
  }
  button {
    margin: 0;
    position: absolute;
    right: 0;
    top: -6px;
    @extend %btn-size-16;
  }
}
// 文字盒子
.p-box {
  padding-top: 4px;
  padding-left: 80px;
  p {
    line-height: 26px;
  }
  .blue {
    @extend %blue-5;
  }
}
// 按钮盒子
.btn-box-2 {
  padding-top: 34px;
  padding-bottom: 40px;
  text-align: center;
  button {
    width: 144px;
    @extend %btn-size-16;
  }
}
.hr-1 {
  margin: 34px 40px 0;
}
// 示例 盒子
.example {
  margin-top: 3px;
  width: 600px;
  height: 130px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.18);
  @extend %center;
  .p-box {
    padding-left: 23px;
    margin-top: 13px;
    width: 382px;
    @extend %pull-left;
    p {
      line-height: 24px;
      @extend %font-11;
    }
  }
  .gray {
    @extend %gray-7;
    @extend %font-bold;
  }
}
// 左侧盒子
.example-left {
  margin-top: 15px;
  padding-left: 22px;
  width: 103px;
  height: 92px;
  border-right: 1px dotted $gray-2;
  @extend %pull-left;
  h5 {
    padding-top: 8px;
    @extend %font-11;
  }
  h6 {
    padding-top: 5px;
    @extend %font-13;
    @extend %gray-7;
  }
  h6 + h5 {
    padding-top: 18px;
  }
}
// 二维码
.example-right {
  margin: 18px 0 0 3px;
  width: 100px;
  height: 100px;
  @extend %overflow;
  @extend %pull-left;
  .add-student-img {
    width: 100px;
    height: 100px;
    @extend %block;
  }
}
//批量添加学生成功页二维码
.example-right.add-example {
  margin: 17px 0 0 3px;
}
//******************** 添加学生 end ****************

//******************** 添加学生&账号列表 begin ****************
// 间隙
.space-fp {
  padding-bottom: 22px;
}
// 指南上部
.fingerpost {
  margin: 40px 48px 57px 40px;
  height: 34px;
  h2 {
    @extend %font-28;
    @extend %pull-left;
    @extend %gray-7;
  }
}
// 上右角按钮区域
.fingerpost-right {
  @extend %pull-right;
  button {
    @extend %btn-size-16;
  }
}
// 方法块
.method-box {
  position: relative;
  width: 100%;
  padding-left: 114px;
  @extend %clear-fix;
  strong {
    position: absolute;
    top: 0;
    left: 0;
    width: 94px;
    text-align: right;
    @extend %font-18;
    @extend %gray-7;
  }

  // 文字示例
  fieldset {
    margin-top: 2px;
    padding: 5px 20px 14px 20px;
    width: 492px;
    border: 1px solid $gray-1;
    @extend %radius-10;
    p {
      padding-left: 112px;
      line-height: 25px;
      @extend %gray-7;
    }
  }
  legend {
    padding: 0 18px;
    @extend %font-12;
    @extend %gray-3;
  }
}
// 文字盒子
.method-div {
  padding-bottom: 20px;
  p {
    line-height: 22px;
  }
}

// 示列
.example-2 {
  margin-bottom: 20px;
  width: 640px;
  height: 140px;
  border: 1px solid $gray-1;
  @extend %clear-fix;
  @extend %center;
  .p-box {
    width: 410px;
    padding-top: 22px;
    padding-left: 25px;
    @extend %pull-left;
    p {
      line-height: 21px;
      @extend %font-12;
    }
  }
  .example-left {
    margin-top: 19px;
    width: 110px;
    height: 102px;
  }
  .example-left h5 {
    padding-left: 2px;
    @extend %font-12;
  }
  .example-left h6 {
    padding-left: 2px;
    @extend %font-14;
  }
  .martspace {
    margin-top: 27px;
  }
  .gray {
    @extend %gray-7;
  }
}
// 虚线
.dotted {
  margin: 40px;
  border-bottom: 1px dotted $gray-2;
}

//******************** 添加学生&账号列表 end ****************
