.container {
  width: 1090px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 8px 16px 0 rgba(58, 78, 115, 0.06);
  border-radius: 12px;
  border: 1px solid rgba(237, 239, 243, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  .content {
    width: 500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .errorImage {
      display: block;
      width: 217px;
      height: 132px;
      margin-bottom: 24px;
    }
    .error_text {
      font-size: 22px;
      font-family: NotoSansTC-Medium;
      font-weight: 500;
      color: rgba(183, 192, 207, 1);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
    .button {
      margin-top: 64px;
      width: 149px;
      height: 48px;
      background: rgba(0, 130, 255, 1);
      box-shadow: 0 4px 9px 0 rgba(0, 130, 255, 0.4);
      border-radius: 24px;
      font-size: 18px;
      font-family: NotoSansTC-Bold;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      line-height: 48px;
      text-align: center;
    }
  }
}
