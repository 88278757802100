.a {
  font-size: 28px;
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/deliberate-practice/detail_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.common_image {
  display: block;
  width: 100%;
  height: 100%;
}
.common_btn {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
}
.common_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.model {
  width: 940px;
  height: 678px;
  background-image: url('../../assets/deliberate-practice/model-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .close {
    width: 108px;
    height: 55px;
    background-image: url('../../assets/deliberate-practice/close.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .model_detail {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 37px;
      img {
        display: block;
        width: 216px;
        height: 32px;
      }
    }
  }
  .show_item {
    margin-top: 46px;
    padding: 0 40px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .attr {
        font-size: 24px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
      }
      .score {
        font-size: 28px;
        font-family: DINAlternate-Bold;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
      }
      .score1 {
        display: flex;
        align-items: center;
        img {
          width: 120px;
          height: 16px;
          display: block;
        }
        .sum {
          font-size: 32px;
          font-family: DINAlternate-Bold;
          font-weight: normal;
          color: rgba(255, 215, 45, 1);
          margin-left: 10px;
        }
      }
    }
    .dash_line {
      background-image: url('../../assets/deliberate-practice/line.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      margin-bottom: 20px;
      height: 1px;
    }
  }
  .show_error_right {
    margin: 40px;
    height: 258px;
    background: rgba(216, 216, 216, 0);
    border: 2px solid rgba(82, 134, 176, 1);
    max-height: 258px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2px 25px 25px;
    &::-webkit-scrollbar {
      display: none;
    }
    .error_right {
      color: white;
      font-size: 23.058823529px;
      display: flex;
      float: left;
      max-width: calc((860px - 4px - 50px) / 3);
      width: calc((860px - 4px - 50px) / 3);
      margin-top: 25px;
      justify-content: flex-start;
      &:nth-child(3n + 1) {
        //justify-content: flex-start;
      }
      &:nth-child(3n + 2) {
        //justify-content: center;
        padding-left: 20px;
      }
      &:nth-child(3n) {
        //justify-content: flex-end;
        padding-left: 40px;
      }
      .right {
        width: 50px;
        height: 34px;
        border: 2px solid rgba(20, 220, 155, 1);
        transform: skewX(165deg);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px;
      }
      .error {
        width: 50px;
        height: 34px;
        border: 2px solid rgba(255, 95, 105, 1);
        margin-right: 10px;
        transform: skewX(165deg);
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5px;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 80%;
          height: 2.5px;
          background-color: white;
          left: 10%;
          bottom: 10px;
        }
      }
    }
  }
}
.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 181px;
  height: 44px;
  background: rgba(28, 37, 62, 1);
  box-shadow: 0px -2px 0px 0px rgba(21, 30, 55, 1);
  border-radius: 22px;
  font-size: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin-left: 50px;
  visibility: hidden;
  img {
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 9px;
  }
  .big {
    font-size: 24px;
    font-family: DINAlternate-Bold;
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  margin-top: 96px;
  .icon {
    width: 356px;
    height: 43px;
    margin-bottom: 65px;
    img {
      display: flex;
      width: 356px;
      height: 43px;
    }
  }
}
.time_block {
  background-image: url('../../assets/deliberate-practice/entry-a-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 262px;
  height: 276px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  .number {
    font-family: DIN;
    font-size: 100px;
    font-weight: normal;
    color: rgba(29, 180, 255, 1);
  }
  .intro {
    background-image: url('../../assets/deliberate-practice/d-timer.png');
    width: 118px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-top: 18px;
  }
}
.quit {
  background-image: url('../../assets/deliberate-practice/quit-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 940px;
  height: 298px;
  position: relative;
  display: flex;
  justify-content: center;
  .quit_close {
    background-image: url('../../assets/deliberate-practice/quit-close.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 108px;
    height: 55px;
    cursor: pointer;
  }
  .quit_text {
    background-image: url('../../assets/deliberate-practice/sure.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100px;
    height: 29px;
    cursor: pointer;
    margin-top: 185px;
  }
}
@media screen and (max-width: 1440px) {
  .center,
  .model,
  .time_block,
  .quit {
    transform: scale(0.7);
  }
  .center {
    margin-top: 64px;
  }
}
@media screen and (max-height: 700px) {
  .center,
  .model,
  .time_block,
  .quit {
    transform: scale(0.7);
  }
  .center {
    margin-top: 64px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .center,
  .model,
  .quit {
    transform: scale(0.5);
  }
  .center {
    margin-top: 56px;
  }
  .timer {
    transform: scale(0.6);
    margin-left: -10px;
  }
  .time_block {
    transform: scale(0.6);
  }
}
