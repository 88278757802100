.part-div {
  position: relative;
  padding-left: 90px;
  margin-bottom: 30px;
  width: 860px;
  height: 65px;
  @extend %radius-10;
  @extend %center;
  @extend %bg-gray-5;
  h4 {
    padding: 15px 15px 8px 0;
    @extend %white;
  }
  p {
    @extend %white;
  }
  .part-img {
    position: absolute;
    left: 30px;
    top: 50%;
    margin-top: -21px;
    width: 42px;
    height: 42px;
  }
}
.mart60 {
  margin-top: 60px;
}
.do-task {
  @extend %clear-fix;
}
// 主线 做题模块
.topic-practice {
  margin: 0 35px;
  .task-content-left {
    width: 50%;
    padding-left: 0;
    .task-topic div {
      line-height: 1.5;
    }
  }
  .task-content-right {
    width: 45%;
    .show-answer {
      width: 100%;
    }
  }
}
.practice-noGoal {
  margin-top: -54px;
  position: relative;
  padding-bottom: 10px;
  //overflow: hidden;
}
.practice-goal {
  margin-top: -50px;
  position: relative;
}
.problem-label {
  display: block;
  width: 120px;
  height: 50px;
  position: absolute;
  top: -20px;
  left: -11px;
  z-index: 10;
  background: url('./assets/Group 2.png') no-repeat center center;
  text-align: center;
  line-height: 42px;
  color: #fff;
  font-size: 18px;
}
.topic-task {
  margin-bottom: 20px;
}
// 习题加积分
.plus-box {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 300;
  margin-top: -34px;
  margin-left: -76px;
  width: 152px;
  height: 68px;
  text-align: center;
  @extend %white;
  @extend %black-opcity-8;
  .score {
    position: relative;
    top: 18px;
  }
  .num-int {
    position: relative;
    top: 23px;
    left: 5px;
    @extend %inline-block;
    @extend %font-24;
    @extend %font-bold;
  }
}
.pattern {
  position: relative;
  top: 6px;
  margin-right: 2px;
  height: 22px;
  border-radius: 2px 0 0 2px;
  @extend %inline-block;
  @extend %white;
  strong {
    padding: 4px 7px 0;
    @extend %pull-left;
    @extend %font-14;
    @extend %font-normal;
  }
  i {
    @extend %pull-right;
  }
  &.bg-red {
    @extend %bg-red-5;
  }
}
// 习题提示tip
.warn {
  position: absolute;
  left: 0;
  bottom: 70px;
  z-index: 300;
  width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;

  .svgchapter {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -29px;
    width: 17px;
    height: 58px;
  }
  .horn-2 {
    width: 21px;
    margin-top: -36px;
    height: 72px;
  }
  .icon-wrong {
    position: absolute;
    top: 50%;
    right: 32px;
    margin-top: -12px;
    font-size: 24px;
    @extend %pointer;
  }
  p {
    padding: 16px 98px 15px 20px;
    line-height: 21px;
    @extend %font-16;
    @extend %font-bold;
  }
  &.bg-red {
    @extend %red-5;
    @extend %bg-red-2;
  }
  &.bg-blue {
    @extend %blue-5;
    @extend %bg-blue-2;
  }
  &.bg-green {
    @extend %green-5;
    @extend %bg-green-2;
  }
}

//高中键盘
.keyboard-ul-hight {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .keyboard__li-math {
    width: 60px;
    border-radius: 15px;
    i {
      width: 52px;
    }

    &:hover {
      background-color: #eaeaea;
    }
    &:last-child {
      margin-right: 10px;
    }
  }
  .keyboard__li-include {
    width: 60px;
    border-radius: 15px;
    i {
      width: 79px;
    }
    &:hover {
      background-color: #eaeaea;
    }
  }

  li {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-left: 3px;
    width: 30px;
    height: 30px;
    text-align: center;
    transition: background-color 0.2s linear;
    background-color: #f5f5f5;
    @extend %radius-50p;
    @extend %pointer;
    i {
      position: relative;
      font-size: 18px;
      width: 30px;
      height: 30px;
      line-height: 1.6;
      @extend %radius-50p;
      @extend %gray-5;
      @extend %inline-block;
    }

    &:hover {
      background-color: #eaeaea;
    }
  }

  li:last-child i,
  li:nth-child(10n) i {
    &:after {
      display: none;
    }
  }
}
.keyword__div-handle-changeKeyword {
  margin: 15px 10px;
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 14px;
  font-weight: bold;
}
.keyword__div-handle-changeKeyword .close {
  width: 11px;
  height: 11px;
  position: absolute;
  top: 0px;
  right: 20px;
  list-style: none;
  cursor: pointer;
}
.highKeyboard {
  display: none;
  position: absolute;
  left: 0;
  bottom: -370px;
  z-index: 100;
  width: 100%;
  height: 370px;
  overflow: hidden;
  @extend %white-board;
}
.keyboard-delete-box {
  position: absolute;
  bottom: 0;
  right: 14px;
  i {
    width: 70px;
    height: 30px;
    line-height: 1.9;
    font-size: 14px;
    text-align: center;
    @extend %font-16;
    @extend %gray-5;
    @extend %font-bold;
    @extend %inline-block;
    @extend %pointer;
  }
}
.keyboard-delete {
  width: 70px;
  height: 30px;
  background-color: #f5f5f5;
  overflow: hidden;
  border-radius: 15px;
  .icon-zdelete:hover {
    background-color: #eaeaea;
  }
}
