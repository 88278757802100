.svgcomm {
  font-size: 16px;
}

.svgcomm:before {
  content: ' ';
  vertical-align: middle;
  display: inline-block;
  background-image: url('./assets/svgcomm1479352030672-inner.svg');
  background-repeat: no-repeat;
  background-size: 3.25em 32em;
}

.no-svg .svgcomm:before {
  background-image: url('./assets/svgcomm1479352030672-inner.svg');
}

.svgcomm.ability:before {
  background-position: -0.625em -0.625em;
  width: 1.75em;
  height: 1.75em;
}

.svgcomm.cert-2:before {
  background-position: -0.625em -3.625em;
  width: 1.5em;
  height: 1.6875em;
}

.svgcomm.cert-2-sma:before {
  background-position: -0.625em -6.5625em;
  width: 1.0625em;
  height: 1.1875em;
}

.svgcomm.cert-blue:before {
  background-position: -0.625em -9em;
  width: 1.5625em;
  height: 1.75em;
}

.svgcomm.cert-gray:before {
  background-position: -0.625em -12em;
  width: 1.5625em;
  height: 1.75em;
}

.svgcomm.coin:before {
  background-position: -0.625em -15em;
  width: 1.25em;
  height: 1.25em;
}

.svgcomm.finish-blue:before {
  background-position: -0.625em -17.5em;
  width: 1.4375em;
  height: 1.4375em;
}

.svgcomm.finish-gold:before {
  background-position: -0.625em -20.1875em;
  width: 1em;
  height: 1em;
}

.svgcomm.integral-2:before {
  background-position: -0.625em -22.4375em;
  width: 1.25em;
  height: 1.25em;
}

.svgcomm.score:before {
  background-position: -0.625em -24.9375em;
  width: 2em;
  height: 2em;
}

.svgcomm.unfinish:before {
  background-position: -0.625em -28.1875em;
  width: 1em;
  height: 1em;
}

.svgcomm.window:before {
  background-position: -0.625em -30.4375em;
  width: 0.9375em;
  height: 0.9343750000000002em;
}
.user-treasure .svgcomm:before {
  transform: scale(0.8);
}
.user-treasure .svgcomm {
  margin: 0 6px 0 8px;
}
