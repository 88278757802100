//清除浮动
%clear-fix,
.clear-fix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}
// 超出隐藏
%overflow {
  overflow: hidden;
}
// 阴影
.shadow-1,
%shadow-1 {
  box-shadow: $box-shadow;
}
.shadow-3,
%shadow-3 {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
}
//白背景+阴影+圆角 1
%white-board,
.white-board {
  box-shadow: $box-shadow;
  background-color: $white;
  border-radius: $radius;
  @extend %clear-fix;
}
//白背景+阴影+圆角 2
%white-board-2 {
  box-shadow: 0 3px 15px rgba(100, 100, 100, 0.1);
  background-color: $white;
  border-radius: $radius;
  @extend %clear-fix;
}
//白背景+阴影+无圆角
%white-board-3 {
  box-shadow: 0 3px 15px rgba(100, 100, 100, 0.1);
  background-color: $white;
  @extend %clear-fix;
}
//白背景+阴影10+圆角10
%white-board-4 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: $white;
  border-radius: $radius;
  @extend %clear-fix;
}
// 顶部白条及下部阴影
%white-board-bottom {
  box-shadow: $box-shadow-bottom;
  background-color: $white;
}
//顶部条的下部阴影
%board-bottom {
  box-shadow: $box-shadow-bottom;
}
//省略号
%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //&:after {
  //  content: '...';
  //}
}

//blue
%blue-1 {
  color: $blue-1;
}
%blue-2 {
  color: $blue-2;
}
%blue-3 {
  color: $blue-3;
}
%blue-4 {
  color: $blue-4;
}
%blue-5 {
  color: $blue-5;
}
%blue-6 {
  color: $blue-6;
}

//green
%green-1 {
  color: $green-1;
}
%green-2 {
  color: $green-2;
}
%green-3 {
  color: $green-3;
}
%green-4 {
  color: $green-4;
}
%green-5 {
  color: $green-5;
}
%green-6 {
  color: $green-6;
}

//red
%red-1 {
  color: $red-1;
}
%red-2 {
  color: $red-2;
}
%red-3 {
  color: $red-3;
}
%red-4 {
  color: $red-4;
}
%red-5 {
  color: $red-5;
}
%red-6 {
  color: $red-6;
}

//gold
%gold-1 {
  color: $gold-1;
}
%gold-2 {
  color: $gold-2;
}
%gold-3 {
  color: $gold-3;
}
%gold-4 {
  color: $gold-4;
}
%gold-5 {
  color: $gold-5;
}
%gold-6 {
  color: $gold-6;
}

//gray
%gray-1 {
  color: $gray-1;
}
%gray-2 {
  color: $gray-2;
}
%gray-3 {
  color: $gray-3;
}
%gray-4 {
  color: $gray-4;
}
%gray-5 {
  color: $gray-5;
}
%gray-6 {
  color: $gray-6;
}
%gray-7 {
  color: $gray-7;
}

//white,black
%white {
  color: $white;
}
%black {
  color: $black;
}
%black-opcity-8 {
  background-color: rgba(59, 59, 59, 0.9);
  @extend %radius-10;
}
//白色背景
%bg-white {
  background-color: $white;
}
//黑色背景
%bg-black {
  background-color: $black;
}
//蓝色背景
%bg-blue-1 {
  background-color: $blue-1;
}
%bg-blue-2 {
  background-color: $blue-2;
}
%bg-blue-3 {
  background-color: $blue-3;
}
%bg-blue-4 {
  background-color: $blue-4;
}
%bg-blue-5 {
  background-color: $blue-5;
}
%bg-blue-6 {
  background-color: $blue-6;
}
//绿色背景
%bg-green-1 {
  background-color: $green-1;
}
%bg-green-2 {
  background-color: $green-2;
}
%bg-green-4 {
  background-color: $green-4;
}
%bg-green-5 {
  background-color: $green-5;
}
%bg-green-6 {
  background-color: $green-6;
}
//red色背景
%bg-red-2 {
  background-color: $red-2;
}
%bg-red-5 {
  background-color: $red-5;
}
%bg-red-6 {
  background-color: $red-6;
}
//金色背景
%bg-gold-1 {
  background-color: $gold-1;
}

%bg-gold-3 {
  background-color: $gold-3;
}
%bg-gold-5 {
  background-color: $gold-5;
}
//灰色背景
%bg-gray-1 {
  background-color: $gray-1;
}
%bg-gray-2 {
  background-color: $gray-2;
}
%bg-gray-3 {
  background-color: $gray-3;
}
%bg-gray-4 {
  background-color: $gray-4;
}
%bg-gray-5 {
  background-color: $gray-5;
}
%bg-gray-6 {
  background-color: $gray-6;
}
%bg-gray-7 {
  background-color: $gray-7;
}
//font-size
%font-10 {
  font-size: $font-10;
}
%font-11 {
  font-size: $font-11;
}
%font-12 {
  font-size: $font-12;
}
%font-13 {
  font-size: $font-13;
}
%font-14 {
  font-size: $font-14;
}
%font-15 {
  font-size: $font-15;
}
%font-16 {
  font-size: $font-16;
}
%font-18 {
  font-size: $font-18 !important;
}
%font-19 {
  font-size: $font-19 !important;
}
%font-20 {
  font-size: $font-20;
}
%font-21 {
  font-size: $font-21;
}
%font-22 {
  font-size: $font-22;
}
%font-23 {
  font-size: $font-23;
}
%font-24 {
  font-size: $font-24;
}
%font-25 {
  font-size: $font-25;
}
%font-26 {
  font-size: $font-26;
}
%font-27 {
  font-size: $font-27;
}
.font-28,
%font-28 {
  font-size: $font-28;
}
%font-29 {
  font-size: $font-29;
}
%font-30 {
  font-size: $font-30;
}
%font-31 {
  font-size: $font-31;
}
%font-32 {
  font-size: $font-32;
}
%font-34 {
  font-size: $font-34;
}
.font-36,
%font-36 {
  font-size: $font-36;
}
%font-38 {
  font-size: $font-38;
}
%font-46 {
  font-size: $font-46;
}
%font-48 {
  font-size: $font-48;
}
.font-12 {
  font-size: 12px;
}
// 加粗
%font-bold {
  font-weight: bold;
}
// 正常
%font-normal {
  font-weight: normal !important;
}
//浮动
%pull-right {
  float: right;
}
%pull-left {
  float: left;
}
//光标默认状态
%cursor-auto {
  cursor: auto;
}
%pointer {
  cursor: $pointer;
}
//块左右居中
%center {
  margin-left: auto;
  margin-right: auto;
}
// 圆角
%radius-50p {
  border-radius: 50%;
}
%radius-2 {
  border-radius: 2px;
}
%radius-4 {
  border-radius: 4px;
}
%radius-5 {
  border-radius: 5px;
}
%radius-6 {
  border-radius: 6px;
}
%radius-8 {
  border-radius: 8px;
}
%radius-10 {
  border-radius: 10px;
}
%radius-12 {
  border-radius: 12px;
}
%radius-15 {
  border-radius: 15px;
}
%radius-18 {
  border-radius: 18px;
}
%radius-20 {
  border-radius: 20px;
}
%radius-24 {
  border-radius: 24px;
}
//显示、隐藏
%show,
.show,
%block {
  display: block;
}
%inline-block,
.inline-block {
  display: inline-block;
}
%hidden,
.hidden {
  display: none;
}
%text-center,
.text-center {
  text-align: center;
}
// 相对窗口 固定
%affix {
  position: fixed;
}
%topic-h1 {
  font-size: $font-28;
  color: $gray-6;
}
//radius
%radius {
  border-radius: $radius;
}

//版心宽
%width-1 {
  width: $width-1;
  margin-left: auto;
  margin-right: auto;
}
%width-2 {
  width: $width-2;
}
%width-3 {
  width: $width-3;
}

/* *********************按钮文字大小************* */
//版心盒子 1090
.main {
  margin-bottom: 70px;
  @extend %width-1;
  @extend %clear-fix;
}
.multi-menu-main {
  margin-top: 60px;
  margin-bottom: 70px;
  @extend %width-1;
  @extend %clear-fix;
}
// 宽720px 居中 盒子
.main-2 {
  width: 720px;
  @extend %center;
  @extend %clear-fix;
}
// 宽720px 左浮动 盒子
.left-board {
  @extend %width-2;
  @extend %pull-left;
  @extend %clear-fix;
}
.big-right-board {
  @extend %width-2;
  @extend %pull-right;
  @extend %clear-fix;
}
//新版面宽
.wrape-main {
  width: 990px;
  margin-left: auto;
  margin-right: auto;
  @extend %clear-fix;
}
.wrape-mid {
  width: 620px;
  @extend %pull-left;
  @extend %clear-fix;
}
// 宽340px 右浮动 盒子
.right-board {
  @extend %width-3;
  @extend %pull-right;
  @extend %clear-fix;
}
.small-left-board {
  @extend %width-3;
  @extend %pull-left;
  @extend %clear-fix;
}
// 宽860px 居中 盒子
.main-3 {
  width: 860px;
  @extend %center;
  @extend %clear-fix;
  @extend %white-board;
}
.marb30 {
  margin-bottom: $marb30;
}
hr {
  border: none;
  border-bottom: 1px solid #f0f0f0;
}

//加载中  左右居中
md-progress-circular {
  @extend %center;
}

//********************小于1090 时1024 begin*********************
/*<=10240px*/
@media screen and (max-width: 1090px) {
  .header {
    margin-left: 20px;
    margin-right: 6px;
    width: auto;
  }
  // 下拉菜单区域
  .persona-div {
    margin-right: 30px;
  }
}
//********************小于1090 时1024 end*********************

.pdf {
  color: red;
  cursor: 'pointer';
}

// 打印时候不显示
@media print {
  .pdf {
    display: none;
  }
}
