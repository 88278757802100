.container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 452px;
  height: 142px;
  position: absolute;
  margin-bottom: 16px;
  margin-top: 76px;
  transform: scale(0.9);
  left: calc((100vw - 452px) / 2);
}
.left {
  overflow: hidden;
  position: absolute;
  left: 102px;
  top: 55px;
  .left_item {
    float: left;
    width: 6px;
    height: 11px;
    border: 2px solid rgba(29, 180, 255, 1);
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }
    &:nth-of-type(1) {
      animation: first-opacity1 1.5s linear infinite;
    }
    &:nth-of-type(2) {
      animation: first-opacity2 1.5s linear infinite;
    }
    &:nth-of-type(3) {
      animation: first-opacity3 1.5s linear infinite;
    }
  }
}
.right {
  overflow: hidden;
  position: absolute;
  right: 102px;
  top: 55px;
  .right_item {
    float: left;
    width: 6px;
    height: 11px;
    border: 2px solid rgba(29, 180, 255, 1);
    margin-right: 4px;
    &:last-child {
      margin-right: 0;
    }
    &:nth-of-type(3) {
      animation: first-opacity1 1.5s linear infinite;
    }
    &:nth-of-type(2) {
      animation: first-opacity2 1.5s linear infinite;
    }
    &:nth-of-type(1) {
      animation: first-opacity3 1.5s linear infinite;
    }
  }
}
.rotate {
  background-image: url('../../../../assets/deliberate-practice/rotate.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 142px;
  height: 142px;
  position: absolute;
  top: 0;
  left: 155px;
  animation: rotate 3s linear infinite;
}
.time {
  width: 142px;
  height: 142px;
  position: absolute;
  top: 0;
  left: 155px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: normal;
  color: rgba(29, 180, 255, 1);
  font-family: DIN;
}
@keyframes first-opacity1 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}
@keyframes first-opacity2 {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
@keyframes first-opacity3 {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}
@media screen and (max-width: 1440px) {
  .container {
    transform: scale(0.7);
    margin-top: 64px;
  }
}
@media screen and (max-height: 700px) {
  .container {
    transform: scale(0.7);
    margin-top: 64px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .container {
    transform: scale(0.5);
    margin-top: 12px;
  }
}
