.model {
  width: 942px;
  height: 520px;
  background-image: url('../../../../assets/deliberate-practice/modal_code_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 41px;
  .close {
    width: 108px;
    height: 55px;
    background-image: url('../../../../assets/deliberate-practice/close.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: 1px;
    top: 0;
    cursor: pointer;
  }
  .title {
    width: 237px;
    height: 35px;
    margin: 38px auto 33px;
  }
  .code_block {
    width: 100%;
    text-align: center;
    .code_intro {
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
    .tag {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
    .code {
      width: 244px;
      height: 244px;
      margin: 23px 0 14px;
    }
  }
}
@media screen and (max-width: 1440px) {
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-height: 700px) {
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .model {
    transform: scale(0.5);
  }
}
