//公共header
header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4000;
  width: 100%;
  height: 60px;
  @extend %white-board-bottom;
}
// header
.header {
  position: relative;
}
.logo-div {
  @extend %pull-left;
  @extend %pointer;
  margin: 18px 40px 0 2px;
  width: 140px;
}
.menu {
  @extend %pull-left;
  // width: 596px;
  ul {
    font-size: 0;
    padding-top: 15px;
  }
  li {
    position: relative;
    display: inline-block;
    @extend %font-14;
    button {
      padding-left: 14px;
      padding-right: 13px;
      @extend %btn-color-gray;
      @extend %btn-size-14;
    }
    .unRead {
      position: absolute;
      top: -6px;
      right: -6px;
      padding: 3px 5px;
      height: 17px;
      background: #ef5e45;
      border-radius: 10px;
      @extend %font-12;
      @extend %white;
    }
  }
  .current {
    button {
      @extend %btn-bg-blue;
    }
  }
}
//右侧菜单
.header-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 340px;
  @extend %pull-right;
  // 下拉菜单区域
  .select-wrap {
    position: relative;
    margin-right: 50px;
    margin-top: 19px;
    @extend %pull-left;
    @extend %pointer;
  }
}

//金币菜单
.integral {
  position: absolute;
  top: 0;
  right: 0;
  @extend %pull-left;
  @extend %pointer;
  padding: 19px 10px 0 0;
  li {
    margin: 0 9px;
    @extend %font-bold;
    @extend %pull-left;
    @extend %gray-4;
    em {
      line-height: 24px;
    }
    i {
      position: relative;
      top: -1px;
      margin-right: 5px;
      display: inline-block;
      width: 30px;
      height: 30px;
      @extend %font-26;
      @extend %pull-left;
    }
    &:nth-child(1) i {
      @extend %blue-4;
    }
    &:nth-child(2) i {
      top: 1px;
      font-size: 15px !important;
      @extend %gold-4;
    }
  }
  .svglist.onion_coin_sma:before {
    width: 2.5em;
  }
}
