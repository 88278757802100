// ************** 教师引导 begin ************
.news-div {
  margin-bottom: 30px;
  height: 50px;
  @extend %bg-gray-5;
  @extend %white;
  @extend %radius-10;
  @extend %overflow;
  p {
    margin: 0 70px;
    line-height: 50px;
  }
}
.understand-box {
  position: relative;
  width: 100%;
  height: 620px;
  border-radius: 10px 10px 0 0;
  @extend %overflow;
}
.understand-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.understand-1 {
  position: absolute;
  left: 296px;
  top: 90px;
  width: 500px;
  height: 377px;
}
.understand-2 {
  position: absolute;
  left: 78px;
  top: 116px;
  width: 240px;
  height: 220px;
}
.understand-3 {
  position: absolute;
  right: 78px;
  top: 116px;
  width: 240px;
  height: 220px;
}
.us-pre {
  position: absolute;
  left: 327px;
  top: 220px;
  width: 38px;
  height: 38px;
  transform: rotate(-180deg);
}
.us-next {
  position: absolute;
  right: 327px;
  top: 220px;
  width: 38px;
  height: 38px;
}
.us-text-box {
  position: absolute;
  left: 0;
  top: 484px;
  width: 100%;
  text-align: center;
  @extend %clear-fix;
  h3 {
    margin-bottom: 18px;
    @extend %font-28;
    @extend %gray-7;
    .blue {
      @extend %blue-5;
    }
  }
}
// ************** 教师引导 begin ************
