// 练习统计
.top-tabs-box {
  .ant-tabs-bar {
    margin: 0 0 30px;
    padding: 20px 40px 0;
  }
}
// 学生用
.wrap-center-720 {
  margin: 0 auto 70px;
  width: 720px;
  .ant-tabs-bar {
    margin: 0 0 30px;
    padding: 10px 40px 0;
    @extend %white-board;
  }
}
// 作业列表
.hw-cell {
  position: relative;
  padding: 30px 40px;
  margin-bottom: 30px;
  &.ai-practice {
    h3 {
      &:before {
        width: 76px;
        background: url('./assets/Tag_ai.svg') no-repeat center center;
      }
    }
  }
  &.video-practice {
    h3 {
      &:before {
        background: url('./assets/Tag_video.svg') no-repeat center center;
      }
    }
  }
  &.pure-practice {
    h3 {
      &:before {
        background: url('./assets/Tag_homework.svg') no-repeat center center;
      }
    }
  }
  h3 {
    &:before {
      content: '';
      position: relative;
      top: 2px;
      margin-right: 6px;
      width: 57px;
      height: 18px;
      @extend %inline-block;
    }
    strong {
      color: #2c2c2c;
      @extend %font-18;
      @extend %font-bold;
      @extend %pointer;
      &:hover {
        color: #5e5e5e;
      }
    }
  }
  .btn-state {
    position: absolute;
    top: 25px;
    right: 40px;
  }
}
.hw-icon {
  height: 18px;
  @extend %inline-block;
}
.icon-preview {
}
.hw-expiration {
  padding-top: 10px;
  color: #b3b3b3;
  @extend %font-12;
  span {
    margin-right: 11px;
  }
  .red {
    color: #ff5f69;
  }
}
.hw-leave-message {
  padding-top: 10px;
  color: #b3b3b3;
  line-height: 16px;
  @extend %font-13;
  @extend %font-normal;
}
.hw-detail {
  margin-top: 20px;
  border: 2px solid #f4f4f4;
  border-radius: 10px;
  h5 {
    padding: 14px 25px 0;
    height: 45px;
    color: #818181;
    background: #f4f4f4;
    @extend %font-14;
    @extend %font-bold;
  }
}
.hw-detail-ul {
  padding: 15px 15px 15px 20px;
  // 每个知识点的进度
  li {
    height: 41px;
    &:before {
      position: relative;
      top: 8px;
      margin-right: 5px;
      content: '';
      width: 26px;
      height: 26px;
      @extend %inline-block;
    }
    &:after {
      margin-left: 2px;
      content: '';
      width: 30px;
      height: 26px;
      @extend %inline-block;
    }
    &.video {
      &:before {
        background: url('./assets/icon_Video_ing.svg') no-repeat center center;
      }
    }
    &.practice {
      &.before {
        background: url('./assets/icon_Work_ing.svg') no-repeat center center;
      }
    }
    &.undo.video {
      &:after {
        background: none;
      }
      &:before {
        background: url('./assets/icon_Video_ing.svg') no-repeat center center;
      }
    }
    &.doing.video {
      &:after {
        background: url('./assets/icon_ing.svg') no-repeat center center;
      }
      &:before {
        background: url('./assets/icon_Video_ing.svg') no-repeat center center;
      }
    }
    &.done.video {
      &:after {
        background: url('./assets/icon_finish.svg') no-repeat center center;
      }
      &:before {
        background: url('./assets/icon_Video-list.svg') no-repeat center center;
      }
    }
    &.undo.practice {
      &:after {
        background: none;
      }
      &:before {
        background: url('./assets/icon_Work_ing.svg') no-repeat center center;
      }
    }
    &.doing.practice {
      &:after {
        background: url('./assets/icon_ing.svg') no-repeat center center;
      }
      &:before {
        background: url('./assets/icon_Work_ing.svg') no-repeat center center;
      }
    }
    &.done.practice {
      &:after {
        background: url('./assets/icon_finish.svg') no-repeat center center;
      }
      &:before {
        background: url('./assets/icon_Work.svg') no-repeat center center;
      }
    }
  }
  h4 {
    color: #2c2c2c;
    @extend %font-14;
    @extend %font-bold;
    @extend %inline-block;
    @extend %pointer;
    &:hover {
      color: #5e5e5e;
    }
    &.free:after {
      margin-left: 2px;
      margin-right: 5px;
      content: '';
      width: 40px;
      height: 26px;
      @extend %inline-block;
      background: url('./assets/free-icon.svg') no-repeat center center;
    }
    &.vip:after {
      margin-left: 2px;
      margin-right: 5px;
      content: '';
      width: 16px;
      height: 16px;
      @extend %inline-block;
      background: url('./assets/pay-icon.svg') no-repeat center center;
    }
  }
}
.hw-progress-ul {
  position: absolute;
  bottom: 50px;
  right: 55px;
  li {
    margin-left: 10px;
    white-space: nowrap;
    text-align: right;
    @extend %inline-block;
    i {
      color: #818181;
      @extend %font-12;
    }
    span {
      color: #818181;
      @extend %font-24;
      @extend %font-bold;
    }
    .green {
      color: #6fb906;
    }
    .blue {
      color: #1797eb;
    }
  }
}
.more-hw {
  padding: 20px 20px;
  text-align: center;
  @extend %pointer;
}
.noMore-hw {
  color: #b3b3b3;
  text-align: center;
  @extend %font-12;
}
.inline-loading {
  position: absolute;
}
// *********** 会员到期了等 begin *********
.modal-img-text {
  padding: 20px 0 0 35px;
}
.alert-div {
  position: relative;
  padding-top: 10px;
  padding-bottom: 35px;
  width: 100%;
  @extend %clear-fix;
  &.alert-app {
    padding-bottom: 13px;
  }
  .alert-ft {
    position: absolute;
    right: -25px;
    bottom: 25px;
  }
}
.desktop {
  height: 140px;
}
.alert-l {
  margin-right: 30px;
  width: 170px;
  height: 170px;
  @extend %pull-left;
  &.box150-150 {
    position: relative;
    top: -17px;
    margin-right: 25px;
    width: 150px;
    height: 150px;
    @extend %overflow;
    img {
      position: absolute;
      left: -5px;
      width: 150px;
    }
  }
}
.alert-r {
  width: 270px;
  @extend %pull-left;
  .alert-p {
    line-height: 1.6;
    @extend %font-16;
  }
  .the-title {
    position: relative;
    top: -10px;
    left: 0;
    @extend %gray-7;
    @extend %font-bold;
    @extend %font-22;
  }
}

// *********** 会员到期了等 end *********

// ************************************进度条 begin*********
.task-status {
  position: absolute;
  top: 58px;
  right: 36px;
  margin-top: -25px;
  height: 50px;
  progress-bar {
    @extend %pull-right;
  }
}
.progress {
  position: relative;
  top: 15px;
  @extend %pull-right;
}
.progress-text {
  font-family: DINAlternate-Bold;
  margin-right: 12px;
  @extend %pull-left;
  @extend %font-bold;
  @extend %gray-7;
  @extend %font-16;
  color: #94A1B8 !important;
  em {
    @extend %font-22;
    font-family: DINAlternate-Bold;
    font-size: 24px;
    font-weight: bold;
    color: rgba(0, 130, 255, 1);
  }
}
.progress-div {
  position: relative;
  width: 300px;
  height: 20px;
  background: url('./assets/progress-bg-normal.svg') no-repeat 0 0;
  background-size: 100%;
  @extend %pull-left;
  .flag-middle,
  .flag-middle-blue {
    position: absolute;
    top: -6px;
    width: 32px;
    height: 32px;
    background: url('./assets/flag-middle-normal.svg') no-repeat 0 0;
    background-size: 100%;
    @extend %inline-block;
  }
  .flag-middle-blue {
    background: url('./assets/flag-middle-blue.svg') no-repeat 0 0;
    background-size: 100%;
  }
  .flag-last,
  .flag-last-blue {
    position: absolute;
    top: -6px;
    right: 0;
    width: 32px;
    height: 32px;
    background: url('./assets/flag-last-normal.svg') no-repeat 0 0;
    background-size: 100%;
    @extend %inline-block;
  }
  .flag-last-blue {
    background: url('./assets/flag-last-blue.svg') no-repeat 0 0;
    background-size: 100%;
  }
  .progress-cell {
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;
    height: 20px;
    @extend %overflow;
    @extend %radius-10;
  }
  .progress-div-blue {
    position: absolute;
    top: 0;
    left: -300px;
    width: 300px;
    height: 20px;
    background: url('./assets/progress-bg-blue.svg') no-repeat 0 0;
    background-size: 100%;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transition-property: left;
  }
}

.progress-bar {
  position: relative;
  width: 302px;
  height: 20px;
  border-radius: 20px;
  @extend %pull-left;
  @extend %overflow;
  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 302px;
    height: 20px;
    border-radius: 20px;
    @extend %overflow;
    &.show-right {
      .progress-right {
        display: block;
      }
    }
  }
  i {
    position: absolute;
    right: 15px;
    top: 0;
    width: 18px;
    height: 20px;
    @extend %overflow;
  }
  .progress-gray {
    background: url('./assets/progress-bg-normal.svg') no-repeat right top;
  }
  .progress-right {
    opacity: 0;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: opacity;
    &.show {
      opacity: 1;
    }
  }
  .pro-bg {
    width: 0;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transition-property: width;
    background: url('./assets/progress-blue.svg') no-repeat right top;
  }
}
// ************************************进度条 end*********
// 任务底部
.task-ft {
  position: relative;
  margin: 16px 0;
  @extend %clear-fix;
  &.finished-problems-ft {
    li {
      padding-left: 10px;
    }
  }
}

// 做题  提交
.task-btn-box {
  padding-right: 30px;
  @extend %pull-right;
  li {
    @extend %pull-left;
  }
  button {
    width: 126px;
    @extend %btn-size-16;
    @extend %overflow;
  }
}
// 做题盒子
.problems-box {
  margin: 0 35px;
}
//  已完成作业
.finished-problems-box {
  .show-answer {
    width: auto;
  }
}
