//******************** 使用指南 begin ****************
// 标题盒子
.caption-box {
  @extend %clear-fix;
  .caption-left {
    padding-top: 62px;
    @extend %pull-left;
    &.pad-manual {
      padding-top: 52px;
    }
  }
  .caption-right {
    padding-top: 62px;
    padding-right: 30px;
    @extend %pull-right;
    button {
      margin-left: 10px;
      margin-right: 10px;
      @extend %btn-size-16;
    }
  }
}
//头部
.caption-blue {
  height: 160px;
  border-radius: 10px 10px 0 0;
  @extend %bg-blue-5;
  @extend %clear-fix;
  .headline-ma {
    @extend %white;
  }
  .subhead-ma {
    @extend %blue-1;
  }
}
//大标题
.headline-ma {
  margin-bottom: 11px;
  padding-left: 40px;
  @extend %font-28;
  @extend %gray-7;
}
// 副标题
.subhead-ma {
  width: 506px;
  line-height: 24px;
  padding-left: 40px;
  @extend %font-16;
  @extend %gray-5;
  @extend %font-normal;
}
//大图1
.manual-img-1 {
  height: 540px;
  background-size: 100%;
  @extend %bg-blue-5;
}

// 盒子5
.manual-box-5 {
  height: 562px;
  @extend %bg-gray-1;
}
//学校区域
.manual-school {
  padding-top: 36px;
  width: 100%;
  @extend %clear-fix;
  li {
    margin: 0 10px 40px 40px;
    width: 480px;
    height: 150px;
    @extend %radius-10;
    @extend %bg-white;
    @extend %pull-left;
    @extend %clear-fix;
  }
  h3 {
    margin-top: 30px;
    margin-bottom: 5px;
    padding-left: 30px;
    @extend %font-22;
    @extend %gray-7;
  }
  p {
    padding-left: 30px;
    margin-bottom: 19px;
  }
  // 学校区域右图
  .svgmanual {
    width: 310px;
    height: 150px;
    border-radius: 0 10px 10px 0;
    @extend %block;
    @extend %pull-right;
  }
}
//学校区域左
.manual-school-left {
  width: 170px;
  @extend %pull-left;
}
//按钮盒子
.manual-school-btn {
  font-size: 0;
  margin-left: 9px;
  button {
    margin: 0;
    @extend %btn-size-14;
  }
}

// 盒子6
.manual-box-6 {
  height: 506px;
}
.manual-6-content {
  text-align: center;
  padding-top: 47px;
  @extend %clear-fix;
  @extend %text-center;
  .my-class-2 {
    margin: 0;
    padding: 0;
    width: 230px;
    @extend %center;
    li {
      margin: 0;
    }
  }
}

//******************** 教师 begin ****************
// 主页

.my-className-2 {
  margin-left: auto;
  margin-right: auto;
  width: 230px;
  @extend %clear-fix;
  li {
    margin: 0 15px 40px;
    width: 230px;
    height: 250px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.18);
    @extend %bg-blue-5;
    @extend %radius-8;
    @extend %pull-left;
  }
  h4 {
    margin-top: 60px;
    margin-bottom: 20px;
    height: 26px;
    overflow: hidden;
    @extend %white;
    @extend %font-22;
  }
  h5 {
    line-height: 26px;
    @extend %blue-3;
    @extend %font-16;
  }
  .btn-size-16 {
    margin-top: 28px;
    width: 190px;
    height: 34px !important;
    overflow: hidden;
  }

  .add-className {
    box-shadow: none;
    padding-top: 76px;
    @extend %bg-gray-1;
    h5 {
      margin-top: 18px;
      @extend %gray-3;
    }
    .button.fab.bg-white {
      @extend %overflow;
    }
  }
}

//******************** 使用指南 end ****************
