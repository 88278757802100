// 补充练习-hd
.data-sel-box.practice-sel {
  padding-bottom: 17px;
}
.practice-hd {
  padding: 40px 0 36px 40px;
  height: 104px;
  @extend %clear-fix;
  h3 {
    @extend %gray-7;
    @extend %font-28;
    @extend %pull-left;
  }
  .button {
    position: relative;
    top: -9px;
    margin-right: 33px;
    height: 34px;
    @extend %pull-right;
    i {
      position: relative;
      top: 0;
      left: -5px;
      margin-right: 2px;
      font-size: 34px;
      @extend %pull-left;
    }
  }
}
//补充练习表格
.practice-box {
  margin: 0 40px 60px;
  @extend %clear-fix;
}
.practice-table {
  width: 100%;
  text-align: left;
  td {
    padding: 0 5px;
    height: 40px;
    border-bottom: 1px solid $gray-1;
  }
  tbody tr td:first-child {
    // word-break: break-all;
  }
  // 排序
  .sorting {
    .sort-text i {
      transform: rotate(180deg);
    }
  }
  .member-div {
    padding-top: 2px;
    line-height: 18px;
    height: 20px;
    width: 86px;
    @extend %overflow;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  tbody tr:last-child td {
    border-bottom: none;
  }
  tbody tr {
    transition: background 0.3s ease-in-out;
    &:hover {
      @extend %bg-gray-1;
    }
  }
  .no-padding {
    padding: 0;
  }
  th {
    padding: 0 5px;
    height: 40px;
    border-bottom: 2px solid $gray-2;
    @extend %gray-7;
    @extend %font-16;
  }
  .table-pad-no {
    padding: 0 5px 0 0;
  }
  .th-center {
    text-align: center;
  }
  .topic-log {
    position: relative;
    top: 13px;
    @extend %inline-block;
  }
  .help-tea {
    position: relative;
    top: 15px;
    @extend %inline-block;
  }

  .text-r {
    text-align: right;
    @extend %block;
  }
  .sort-text {
    position: relative;
    @extend %block;
    @extend %pointer;
    i {
      position: absolute;
      right: -7px;
      top: -4px;
      font-size: 24px;
      transform-origin: 12px 12px;
      transition: transform 0.4s ease;
    }
  }
}
.button.fab.w-32.hidden {
  display: none;
}
//对错 动画
.practice-sign-box {
  position: relative;
  width: 460px;
  height: 48px;
  .pre {
    position: absolute;
    left: -16px;
    top: 50%;
    margin-top: -16px;
    z-index: 10;
    // opacity: 0;
    // transition:  400ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    // transition-property: opacity,color;
  }
  .next {
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -16px;
    z-index: 10;
    // opacity: 0;
    // transition:  400ms cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    // transition-property: opacity;
  }
  // &:hover {
  //     .pre {
  //         opacity: 1;
  //     }
  //     .next {
  //         opacity: 1;
  //     }
  // }
  .left-shadow {
    position: absolute;
    left: 0;
    top: 0;
    width: 8px;
    height: 48px;
    background: url(./assets/img_left.png) 0 0;
    background-repeat: repeat-y;
    background-size: 100%;
  }
  .right-shadow {
    position: absolute;
    right: 0;
    top: 0;
    width: 8px;
    height: 48px;
    background: url(./assets/img_right.png) 0 0;
    background-repeat: repeat-y;
    background-size: 100%;
  }
}
.practice-sign {
  position: absolute;
  left: 0;
  width: 460px;
  height: 48px;
  @extend %overflow;
}
.practice-ul {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 1000px;
  height: 48px;
  @extend %clear-fix;
  @extend %overflow;
  li {
    width: 100px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    @extend %pull-left;
    @extend %pointer;
  }
  .blue-3 {
    @extend %blue-3;
  }
  .blue-5 {
    @extend %blue-5;
  }
  .gold-5 {
    @extend %gold-5;
  }
  h4 {
    padding-top: 10px;
    padding-bottom: 3px;
    @extend %gray-7;
  }
  h5 {
    font-weight: normal;
    @extend %gray-4;
    @extend %font-12;
  }
}
.practice-ul-transition {
  transition: left cubic-bezier(0, 0.21, 0.62, 1) 0.5s;
}
.condition {
  width: 22px;
  height: 22px;
  background-color: #ccc;
  @extend %center;
  @extend %radius-50p;
  @extend %block;
  &.to-be-continued {
  }
  &.not-master {
  }
  &.have-mastered {
  }
}
// 关键词含义
.keyword-meaning {
  margin: 0 0 30px;
  @extend %clear-fix;
  // 单个问题
  .issue {
    width: 100%;
    padding-right: 19px;
    height: 100%;
    padding-bottom: 13px;
    border-bottom: none;
    margin-left: -9px;
    @extend %clear-fix;
    @extend %overflow;
    @extend %pointer;
    transition: height 0.3s ease-in-out, background 0.3s ease-in-out;
    &:hover {
      @extend %bg-gray-1;
    }
    .h3 {
      display: inline-block;
    }
    .icon {
      margin: -20px 0;
      display: inline-block;
      width: 26px;
      height: 26px;
      line-height: 26px;
      font-size: 26px;
      transition: all 0.4s ease;
      @extend %pull-right;
    }
  }
  // 单个问题 答案
  .answer {
    padding: 0;
    margin-left: 38px;
    position: relative;
    top: -3px;
    line-height: 22px;
    @extend %font-14;
  }
}
