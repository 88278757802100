//== Colors  ##onionMath3.0 colors
//blue
$blue-1: #e5f8ff;
$blue-2: #bbe7f6;
$blue-3: #60bdf1;
$blue-4: #2da9ee;
$blue-5: #1696ea;
$blue-6: #117ed1;

//green
$green-1: #f3ffdb;
$green-2: #d9f1a6;
$green-3: #b6df76;
$green-4: #8fcb31;
$green-5: #6eb805;
$green-6: #549a40;

//red
$red-1: #fff1ef;
$red-2: #ffd1cb;
$red-3: #ff9576;
$red-4: #f1572e;
$red-5: #e14116;
$red-6: #c82a00;

//gold
$gold-1: #fff8ce;
$gold-2: #ffec93;
$gold-3: #ffde59;
$gold-4: #fbcf00;
$gold-5: #f6c115;
$gold-6: #eeaf00;

//purple
// $purple-1: #bd72cc;
// $purple-2: #bd72cc;
// $purple-3: #bd72cc;
$purple-4: #bd72cc;
// $purple-5: #bd72cc;
// $purple-6: #bd72cc;

//gray
$gray-1: #f3f3f3;
$gray-2: #dadada;
$gray-3: #bebebe;
$gray-4: #a2a2a2;
$gray-5: #888;
$gray-6: #6a6a6a;
$gray-7: #3b3b3b;
$gray-8: #909090;

//white,black
$white: #fff;
$black: #000;

//Settings global styles
$body-bg: $gray-1 !default;

//Typography
//#Font, line-height,
$font-family-sans-serif: 'onion-chinese', Arial, 'Hiragino Sans GB',
  \5fae\8f6f\96c5\9ed1, sans-serif;
$font-family-base: $font-family-sans-serif !default;

//font-size
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-19: 19px;
$font-20: 20px;
$font-21: 21px;
$font-22: 22px;
$font-23: 23px;
$font-24: 24px;
$font-25: 25px;
$font-26: 26px;
$font-27: 27px;
$font-28: 28px;
$font-29: 29px;
$font-30: 30px;
$font-31: 31px;
$font-32: 32px;
$font-34: 34px;
$font-36: 36px;
$font-38: 38px;
$font-46: 46px;
$font-48: 48px;

// num
$num-10: 10px;
$num-11: 11px;
$num-12: 12px;
$num-13: 13px;
$num-14: 14px;
$num-16: 16px;
$num-18: 18px;
$num-19: 19px;
$num-20: 20px;
$num-21: 21px;
$num-22: 22px;
$num-23: 23px;
$num-24: 24px;
$num-25: 25px;
$num-26: 26px;
$num-27: 27px;
$num-28: 28px;
$num-29: 29px;
$num-30: 30px;
$num-31: 31px;
$num-32: 32px;
$num-34: 34px;
$num-36: 36px;
$num-38: 38px;
$num-46: 46px;
$num-48: 48px;

//line-height

//backgroundColor, box-shadow
$box-shadow: 0 0 15px rgba(100, 100, 100, 0.1);
$box-shadow-bottom: 0 0 10px rgba(120, 120, 120, 0.1);

//radius
$radius: 10px;

//margin
$marb30: 30px;

//cursor
$pointer: pointer;

//版心宽
$width-1: 1090px;
$width-2: 720px;
$width-3: 340px;
