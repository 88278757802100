.forward-from-right-enter {
  z-index: 2;
  opacity: 0;
  transform: translateX(100%);
}

.forward-from-right-enter-active {
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.forward-from-right-exit {
  z-index: 1;
  opacity: 1;
}

.forward-from-right-exit-active {
  z-index: 1;
  opacity: 0.3;
  transition: all 500ms;
}

.back-to-right-enter {
  z-index: 1;
  opacity: 0.3;
}

.back-to-right-enter-active {
  z-index: 1;
  opacity: 1;
  transform: translateX(0);
  transition: all 500ms;
}

.back-to-right-exit {
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}

.back-to-right-exit-active {
  z-index: 2;
  opacity: 0;
  transform: translate(100%);
  transition: all 500ms;
}
