@function computerEM($px) {
  @return $px / 28 * 1em;
}
.common_image {
  display: block;
  width: 100%;
  height: 100%;
}
.common_btn {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
}
.common_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dp_nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: computerEM(68);
  background: rgba(28, 37, 62, 1);
  box-shadow: 0px 6px 16px 0px rgba(28, 37, 62, 0.5);
  font-size: 28px;
  .back {
    width: computerEM(179);
    height: computerEM(60);
    background: rgba(35, 46, 77, 1);
    box-shadow: 0px 4px 4px 0px rgba(19, 26, 43, 0.3);
    border-radius: 0px 0px 16px 0px;
    margin-right: 8px;
    position: relative;
    @extend .common_center;
    .btn {
      @extend .common_btn;
      left: 0;
      top: 0;
      img {
        @extend .common_image;
      }
    }
    .back {
      width: computerEM(79);
      height: computerEM(44);
      background: rgba(28, 37, 62, 1);
      box-shadow: 0px -2px 0px 0px rgba(21, 30, 55, 1);
      border-radius: computerEM(22);
      @extend .common_center;
      img {
        display: block;
        width: computerEM(40);
        height: computerEM(40);
      }
      cursor: pointer;
    }
  }
  .logo {
    width: computerEM(207);
    height: computerEM(60);
    background: rgba(35, 46, 77, 1);
    box-shadow: 0px 4px 4px 0px rgba(19, 26, 43, 0.3);
    border-radius: 0px 0px 0px 16px;
    margin-left: 8px;
    position: relative;
    @extend .common_center;
    .btn {
      @extend .common_btn;
      left: 0;
      top: 0;
      img {
        @extend .common_image;
      }
    }
    .empty {
      width: computerEM(107);
    }
  }
  .nav {
    flex: 1;
    @extend .common_center;
    height: computerEM(60);
    background: rgba(35, 46, 77, 1);
    box-shadow: 0px 4px 4px 0px rgba(19, 26, 43, 0.3);
    border-radius: 0px 0px 16px 16px;
    position: relative;
    .btn {
      @extend .common_btn;
      left: 0;
      top: 0;
      img {
        @extend .common_image;
      }
    }
    .nav_name {
      width: computerEM(294);
    }
  }
}
.insert {
  height: computerEM(44);
  background: rgba(28, 37, 62, 1);
  box-shadow: 0px -2px 0px 0px rgba(21, 30, 55, 1);
  border-radius: computerEM(22);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: computerEM(24);
  font-weight: bold;
  .insert_1,
  .insert_2 {
    img {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
  .insert_1 {
    margin-left: computerEM(10);
  }
  .insert_2 {
    transform: rotate(108deg);
    margin-right: computerEM(10);
  }
}
@media screen and (max-width: 1440px) {
  .dp_nav {
    font-size: 24px;
  }
}
@media screen and (max-height: 700px) {
  .dp_nav {
    font-size: 24px;
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .dp_nav {
    font-size: 16px;
  }
  .insert {
    .insert_1,
    .insert_2 {
      padding-top: 3px;
    }
  }
}
