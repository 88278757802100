[data-balloon] {
  position: relative;
}
[data-balloon]:before,
[data-balloon]:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.18s ease-out 0.18s;
  transition: all 0.18s ease-out 0.18s;
  bottom: 100%;
  left: 50%;
  position: absolute;
  z-index: 10;
  -webkit-transform: translate3d(-50%, 10px, 0);
  -ms-transform: translate3d(-50%, 10px, 0);
  transform: translate3d(-50%, 10px, 0);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
}
[data-balloon]:after {
  background-color: rgba(0,0,0,.5);
  border-radius: 10px;
  color: #fff;
  content: attr(data-balloon);
  font-size: 14px;
  font-weight: bold;
  padding: 11px 14px 8px;
  white-space: nowrap;
  margin-bottom: 11px;
}
/*[data-balloon]:before {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="rgba(0, 0, 0, .5)" transform="rotate(0)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>') no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  content: "";
  margin-bottom: 5px;
}*/
[data-balloon]:hover:before,
[data-balloon]:hover:after {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
[data-balloon][data-balloon-break]:after {
  white-space: normal;
}
[data-balloon-pos="down"]:before,
[data-balloon-pos="down"]:after {
  bottom: auto;
  left: 50%;
  top: 100%;
  -webkit-transform: translate3d(-50%, -10px, 0);
  -ms-transform: translate3d(-50%, -10px, 0);
  transform: translate3d(-50%, -10px, 0);
}
[data-balloon-pos="down"]:after {
  margin-top: 8px;
}
/*[data-balloon-pos="down"]:before {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="12px"><path fill="rgba(0, 0, 0, 0.5)" transform="rotate(180 18 6)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>') no-repeat;
  background-size: 100% auto;
  height: 6px;
  width: 18px;
  margin-top: 5px;
  margin-bottom: 0;
}*/
[data-balloon-pos="down"]:hover:before,
[data-balloon-pos="down"]:hover:after {
  -webkit-transform: translate3d(-50%, 0, 0);
  -ms-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
}
[data-balloon-pos="left"]:before,
[data-balloon-pos="left"]:after {
  bottom: auto;
  left: auto;
  right: 100%;
  top: 50%;
  -webkit-transform: translate3d(10px, -50%, 0);
  -ms-transform: translate3d(10px, -50%, 0);
  transform: translate3d(10px, -50%, 0);
}
[data-balloon-pos="left"]:after {
  margin-right: 11px;
}
/*[data-balloon-pos="left"]:before {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="rgba(0, 0, 0, 0.5)" transform="rotate(-90 18 18)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>') no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-right: 5px;
  margin-bottom: 0;
}*/
[data-balloon-pos="left"]:hover:before,
[data-balloon-pos="left"]:hover:after {
  -webkit-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
[data-balloon-pos="right"]:before,
[data-balloon-pos="right"]:after {
  bottom: auto;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(-10px, -50%, 0);
  -ms-transform: translate3d(-10px, -50%, 0);
  transform: translate3d(-10px, -50%, 0);
}
[data-balloon-pos="right"]:after {
  margin-left: 11px;
}
/*[data-balloon-pos="right"]:before {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12px" height="36px"><path fill="rgba(0, 0, 0, 0.5)" transform="rotate(90 6 6)" d="M2.658,0.000 C-13.615,0.000 50.938,0.000 34.662,0.000 C28.662,0.000 23.035,12.002 18.660,12.002 C14.285,12.002 8.594,0.000 2.658,0.000 Z"/></svg>') no-repeat;
  background-size: 100% auto;
  height: 18px;
  width: 6px;
  margin-bottom: 0;
  margin-left: 5px;
}*/
[data-balloon-pos="right"]:hover:before,
[data-balloon-pos="right"]:hover:after {
  -webkit-transform: translate3d(0, -50%, 0);
  -ms-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
[data-balloon-length]:after {
  white-space: normal;
}
[data-balloon-length="small"]:after {
  width: 80px;
}
[data-balloon-length="medium"]:after {
  width: 150px;
  line-height: 18px;
}
[data-balloon-align='center']:after {
  text-align: center;
}
[data-balloon-length="large"]:after {
  z-index: 3000;
  width: 260px;
  line-height: 21px;
}
[data-balloon-length="xlarge"]:after {
  width: 90vw;
}
@media screen and (min-width: 768px) {
  [data-balloon-length="xlarge"]:after {
    width: 380px;
  }
}
[data-balloon-length="fit"]:after {
  width: 100%;
}