.enter-layer-hd {
  position: relative;
  @extend %clear-fix;
  .icon-btn-right {
    position: absolute;
    right: 40px;
    top: 31px;
    .button.fab {
      font-size: 34px;
    }
  }
}
.headline-big {
  padding: 40px 0 10px 40px;
  @extend %gray-7;
  @extend %font-28;
}
.subhead-big {
  padding: 0 0 40px 40px;
  @extend %font-16;
  @extend %font-normal;
}
//菜单
.enter-layer.practice-sign-box {
  margin: 0 40px;
  width: 640px;
  border-bottom: 3px solid #f3f3f3;
  .practice-sign {
    width: 640px;
  }
  .line-blue {
    position: absolute;
    transition: left cubic-bezier(0, 0.21, 0.62, 1) 0.5s;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 3px;
    @extend %bg-blue-5;
    @extend %overflow;
  }
  li {
    h4 {
      padding: 6px 0 3px;
      @extend %font-16;
    }
    h5 {
      @extend %font-12;
      @extend %gray-4;
      @extend %font-normal;
    }
    &.current {
      h4 {
        @extend %blue-5;
      }
      h5 {
        @extend %blue-4;
      }
    }
  }
}
// 状态标识
.state-mark {
  margin: 30px 40px;
  text-align: center;
  height: 38px;
  line-height: 38px;
  @extend %radius-10;
  @extend %font-18;
  @extend %font-bold;
  @extend %white;
  &.blue-5 {
    @extend %bg-blue-5;
  }
  &.blue-3 {
    @extend %bg-blue-3;
  }
  &.gold-5 {
    @extend %bg-gold-5;
  }
}
// 题盒
.enter-layer-div {
  @extend %clear-fix;
  .task-explains {
    //margin: -10px 40px 30px;
  }
  .task-content {
    margin: 0 40px 30px;
    .task-content-left {
      width: 250px;
      img {
        width: 250px;
      }
    }
    .task-content-right {
      width: 290px;
    }
  }
  .task-topic {
    div {
      line-height: 28px;
    }
  }
  .katex {
    //班级数据-梯子题要有此属性，不然3行大括号会乱
    white-space: nowrap;
  }
}
//更多
.enter-layer-btn {
  margin: 0 40px 0;
  text-align: center;
}
.pra-space-div {
  padding-bottom: 60px;
}
