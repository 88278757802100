.onion_bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-180deg, #002fef 3%, #0085ff 91%);
  overflow: hidden;
  z-index: -9999;
}
.img1 {
  position: absolute;
  height: 47.22%;
  left: 0;
  top: 52.78%;
  opacity: 0.5;
  animation: yundong1 6s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong1 {
  50% {
    top: 65%;
    opacity: 1;
    transform: translate3d(0px, -80px, 0);
  }
}
.img2 {
  position: absolute;
  width: 45.36%;
  left: 4.06%;
  bottom: 0%;
  opacity: 0.5;
  animation: yundong2 5s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong2 {
  50% {
    opacity: 1;
    transform: translate3d(-40px, 0px, 0);
  }
}
.img3 {
  position: absolute;
  width: 32.66%;
  right: 6.56%;
  bottom: 0;
  opacity: 0.5;
  animation: yundong3 5s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong3 {
  50% {
    opacity: 1;
    transform: translate3d(40px, 0px, 0);
  }
}
.img4 {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: -999;
}
.img5 {
  position: absolute;
  width: 5.52%;
  left: 10.78%;
  bottom: 23.52%;
  opacity: 0.5;
  animation: yundong4 8s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong4 {
  50% {
    opacity: 1;
    transform: translate3d(50px, 50px, -20px);
  }
}
.img6 {
  position: absolute;
  width: 5.21%;
  z-index: -999;
  animation: star1 3s ease-in 1s infinite;
}
@keyframes star1 {
  0% {
    opacity: 0;
    transform: translate3d(300%, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-150%, 400%, 0);
  }
}
.img7 {
  position: absolute;
  width: 5.21%;
  z-index: -999;
  animation: star2 5s ease-in-out infinite;
}
@keyframes star2 {
  0% {
    opacity: 0;
    transform: translate3d(600%, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-150%, 800%, 0);
  }
}
.img8 {
  position: absolute;
  width: 5.21%;
  z-index: -999;
  animation: star3 7s ease-in-out infinite;
}
@keyframes star3 {
  0% {
    opacity: 0;
    transform: translate3d(1500%, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(300%, 1200%, 0);
  }
}
.img9 {
  position: absolute;
  width: 6.77%;
  left: 81.77%;
  bottom: 83.43%;
  opacity: 0.5;
  animation: yundong6 7s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong6 {
  50% {
    opacity: 1;
    transform: translate3d(50px, 30px, -10px);
  }
}
.img10 {
  position: absolute;
  width: 2.81%;
  left: 93.7%;
  bottom: 79.07%;
  opacity: 0.5;
  animation: yundong7 9s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong7 {
  50% {
    opacity: 1;
    transform: translate3d(-50px, 30px, -10px);
  }
}
.img11 {
  position: absolute;
  width: 20%;
  left: 78.8%;
  bottom: 17.96%;
  opacity: 0.5;
  animation: yundong8 7s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong8 {
  50% {
    opacity: 1;
    transform: translate3d(-30px, -30px, -10px);
  }
}
.img12 {
  position: absolute;
  height: 53.8%;
  left: 86.56%;
  bottom: 22.78%;
  animation: yundong10 9s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong10 {
  50% {
    transform: translate3d(-40px, -60px, -10px);
  }
}
.img121 {
  position: absolute;
  width: 4.53%;
  left: 82.55%;
  bottom: 44.63%;
  opacity: 0.5;
  animation: yundong9 5s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong9 {
  50% {
    opacity: 1;
    transform: translate3d(-20px, -20px, -10px);
  }
}
.img13 {
  position: absolute;
  /*     width: 32.4%; */
  height: 33.7%;
  right: 0;
  /*     left: 67.6%; */
  bottom: 0;
  z-index: -999;
}
.img14 {
  position: absolute;
  width: 13.33%;
  left: 74.64%;
  bottom: 53.33%;
  opacity: 0.5;
  animation: yundong11 7s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong11 {
  50% {
    opacity: 1;
    transform: translate3d(-30px, 10px, -10px);
  }
}
.img15 {
  position: absolute;
  width: 1.09%;
  left: 86.46%;
  bottom: 66.48%;
  opacity: 0.5;
  animation: yundong12 8s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong12 {
  50% {
    opacity: 1;
    transform: translate3d(-50px, -50px, -10px);
  }
}
.img16 {
  position: absolute;
  width: 1.82%;
  left: 81.35%;
  bottom: 41.48%;
  opacity: 0.5;
  animation: yundong13 8s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong13 {
  50% {
    opacity: 1;
    transform: translate3d(-30px, 20px, -10px);
  }
}
.img17 {
  position: absolute;
  width: 1.35%;
  left: 84.58%;
  top: 64.91%;
  right: 14.06%;
  bottom: 32.69%;
  opacity: 0.5;
  animation: yundong14 8s ease-in-out infinite;
  z-index: -999;
}
@keyframes yundong14 {
  50% {
    opacity: 1;
    transform: translate3d(-20px, 10px, -10px);
  }
}
