//btn:文字大小、border宽度。
@mixin btn-size($font-size, $border-width) {
  @if $font-size < 20 {
    padding-top: ($font-size / 2 + 2) * 1px;
    padding-bottom: ($font-size / 2) * 1px;
    border-radius: (2 * $font-size + 2) * 1px;
  } @else {
    padding-top: ($font-size / 2 + 2) * 1px;
    padding-bottom: ($font-size / 2) * 1px;
    border-radius: (2 * $font-size + 4) * 1px;
  }
  line-height: $font-size * 1px !important;
  font-size: $font-size * 1px !important;
  padding-left: $font-size * 1.5 * 1px;
  padding-right: $font-size * 1.5 * 1px;
  font-weight: bold;
}
//icon按钮:ICON大小。
@mixin icon-size($font-size) {
  font-size: $font-size * 1px !important;
}

//btn:文字颜色、背景色、hover背景色、active背景色、border颜色、disabled背景色、disabled文字色。
@mixin btn-color(
  $font-color,
  $back-color,
  $hover-color,
  $active-color,
  $border-color,
  $dis-color,
  $dis-color-2
) {
  color: $font-color !important;
  background-color: $back-color !important;
  border-color: $border-color !important;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: background-color, color;
  &:not([disabled]):hover {
    background-color: $hover-color !important;
  }
  .circle {
    background-color: $active-color !important;
  }
  &[disabled] {
    background-color: $dis-color !important;
    color: $dis-color-2 !important;
  }
}
//btn:文字颜色、背景色、hover背景色、active背景色、border颜色、disabled背景色、disabled文字色。
@mixin btn-color-2(
  $font-color,
  $back-color,
  $hover-color-text,
  $hover-color,
  $active-color,
  $border-color,
  $dis-color,
  $dis-color-2
) {
  color: $font-color !important;
  background-color: $back-color !important;
  border-color: $border-color !important;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: background-color, color;
  &:not([disabled]):hover {
    background-color: $hover-color !important;
    color: $hover-color-text !important;
  }
  .circle {
    background-color: $active-color !important;
  }
  &[disabled] {
    background-color: $dis-color !important;
    color: $dis-color-2 !important;
  }
}
//btn:border样式、阴影样式。
@mixin btn-shadow($border-style, $box-shadow) {
  border-style: $border-style;
  box-shadow: none !important;
  &:not([disabled]):active {
    box-shadow: 0 $box-shadow / 2 * 1px $box-shadow * 1px rgba(0, 0, 0, 0.15) !important;
  }
}

//button from 10 to 20 by step 2;
@for $i from 5 through 12 {
  $num: $i * 2;
  .ob-size-#{$num} {
    @include btn-size($num, 1);
  }
}

//btn:公共样式
%onion-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  will-change: box-shadow, transform;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
    background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  outline: none;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
}

//**********************不带背景的按钮**************
//btn:无背景+蓝色文字。
//下拉菜单选中
md-select-menu md-option[selected],
.btn-color-blue,
%btn-color-blue {
  @extend %onion-btn;
  @include btn-color(
    $blue-5,
    transparent,
    rgba($blue-5, 0.1),
    rgba($blue-5, 0.1),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btn:无背景+绿色文字。
.btn-color-green,
%btn-color-green {
  @extend %onion-btn;
  @include btn-color(
    $green-5,
    transparent,
    rgba($green-5, 0.1),
    rgba($green-5, 0.1),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btn:无背景+红色文字。
.btn-color-red,
%btn-color-red {
  @extend %onion-btn;
  @include btn-color(
    $red-5,
    transparent,
    rgba($red-5, 0.1),
    rgba($red-5, 0.1),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btn:无背景+金色文字。
.btn-color-gold,
%btn-color-gold {
  @extend %onion-btn;
  @include btn-color(
    $gold-5,
    transparent,
    rgba($gold-5, 0.1),
    rgba($gold-5, 0.1),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btn:无背景+灰色文字
//下拉菜单里的按钮
md-option,
.btn-color-gray,
%btn-color-gray {
  @extend %onion-btn;
  @include btn-color(
    $gray-5,
    transparent,
    rgba($gray-5, 0.1),
    rgba($gray-5, 0.1),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btn:无背景+白色文字
.btn-color-white,
%btn-color-white {
  @extend %onion-btn;
  @include btn-color(
    $white,
    transparent,
    rgba($white, 0.1),
    rgba($gray-3, 0.1),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//**********************带背景的按钮**************
//btn:蓝背景+白字
.btn-bg-blue,
%btn-bg-blue {
  @extend %onion-btn;
  @include btn-color($white, $blue-5, $blue-4, $blue-2, none, $gray-2, $gray-3);
  @include btn-shadow(none, 2);
}
//btn:绿背景+白字
.btn-bg-green,
%btn-bg-green {
  @extend %onion-btn;
  @include btn-color(
    $white,
    $green-5,
    $green-4,
    $green-2,
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
//btn:红背景+白字
.btn-bg-red,
%btn-bg-red {
  @extend %onion-btn;
  @include btn-color($white, $red-5, $red-4, $red-2, none, $gray-2, $gray-3);
  @include btn-shadow(none, 2);
}
//btn:金背景+白字
.btn-bg-gold,
%btn-bg-gold {
  @extend %onion-btn;
  @include btn-color($white, $gold-5, $gold-4, $gold-2, none, $gray-2, $gray-3);
  @include btn-shadow(none, 2);
}
//btn:灰背景+白字
.btn-bg-gray,
%btn-bg-gray {
  @extend %onion-btn;
  @include btn-color($white, $gray-5, $gray-4, $gray-2, none, $gray-2, $gray-3);
  @include btn-shadow(solid, 2);
}
//btn:灰-2 背景+白字
.btn-bg-gray-2,
%btn-bg-gray-2 {
  @extend %onion-btn;
  @include btn-color($white, $gray-2, $gray-2, $white, none, $gray-2, $gray-3);
  @include btn-shadow(solid, 2);
}
//btn:灰-1 背景+灰5字
.btn-bg-gray-1,
%btn-bg-gray-1 {
  @extend %onion-btn;
  @include btn-color($gray-5, $gray-1, $gray-1, $white, none, $gray-2, $gray-3);
  // @include btn-shadow(solid, 2);
}
//**********************带背景的按钮->白背景+彩色字**************
// btn:白背景+灰字
.btn-bg-white-gray,
%btn-bg-white-gray {
  @extend %onion-btn;
  @include btn-color(
    $gray-5,
    $white,
    $white,
    rgba($gray-5, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
.btn-bg-white-light-gray,
%btn-bg-white-light-gra {
  @extend %onion-btn;
  @include btn-color(
    $gray-8,
    $white,
    $white,
    rgba($gray-8, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}

// btn:白背景+灰6ICON, hover时blue-5背景+白icon;
.btn-bg-white-to-blue,
%btn-bg-white-to-blue {
  @extend %onion-btn;
  @include btn-color-2(
    $gray-6,
    $white,
    $white,
    $blue-5,
    rgba($gray-5, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
//($font-color, $back-color, $hover-color-text, $hover-color, $active-color, $border-color, $dis-color, $dis-color-2)

// btn:白背景+蓝字
.btn-bg-white-blue,
%btn-bg-white-blue {
  @extend %onion-btn;
  @include btn-color(
    $blue-5,
    $white,
    $white,
    rgba($blue-5, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
// btn:白背景+绿字
.btn-bg-white-green,
%btn-bg-white-green {
  @extend %onion-btn;
  @include btn-color(
    $green-5,
    $white,
    $white,
    rgba($green-5, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
// btn:白背景+红字
.btn-bg-white-red,
%btn-bg-white-red {
  @extend %onion-btn;
  @include btn-color(
    $red-5,
    $white,
    $white,
    rgba($red-5, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
// btn:白背景+金字
.btn-bg-white-gold,
%btn-bg-white-gold {
  @extend %onion-btn;
  @include btn-color(
    $gold-5,
    $white,
    $white,
    rgba($gold-5, 0.1),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 2);
}
//**********************不带背景btnicon的按钮->无背景+icon**************
//btnicon:无背景+蓝色文字。
.btn-color-blue-2,
%btn-color-blue-2 {
  @extend %onion-btn;
  @include btn-color(
    $blue-5,
    transparent,
    transparent,
    rgba($blue-5, 0.2),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btnicon:无背景+绿色文字。
.btn-color-green-2,
%btn-color-green-2 {
  @extend %onion-btn;
  @include btn-color(
    $green-5,
    transparent,
    transparent,
    rgba($green-5, 0.2),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btnicon:无背景+红色文字。
.btn-color-red-2,
%btn-color-red-2 {
  @extend %onion-btn;
  @include btn-color(
    $red-5,
    transparent,
    transparent,
    rgba($red-5, 0.2),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btnicon:无背景+金色文字。
.btn-color-gold-2,
%btn-color-gold-2 {
  @extend %onion-btn;
  @include btn-color(
    $gold-5,
    transparent,
    transparent,
    rgba($gold-5, 0.2),
    none,
    $gray-2,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btnicon:无背景+灰色文字
.btn-color-gray-2,
%btn-color-gray-2 {
  @extend %onion-btn;
  @include btn-color-2(
    $gray-4,
    transparent,
    $gray-5,
    transparent,
    rgba($gray-5, 0.2),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}
//btnicon:无背景+灰色文字
.btn-color-gray-3,
%btn-color-gray-3 {
  @extend %onion-btn;
  @include btn-color-2(
    $gray-3,
    transparent,
    $gray-5,
    transparent,
    rgba($gray-5, 0.2),
    none,
    none,
    $gray-3
  );
  @include btn-shadow(none, 0);
}

//**********************带背景ICON的按钮->白背景+灰icon**************
//icon-btn:48*48
.md-button.md-fab {
  position: relative;
  width: 48px;
  height: 48px;
  @include icon-size(32);
  ////icon->font-size=32
  .icon {
    position: relative;
    top: 2px;
    // transition: color .4s linear;
  }
  //icon->font-size=36
  .font-36 {
    position: relative;
    top: -1px;
  }
}
//icon-btn:40*40
.md-button.md-fab.size-40 {
  width: 40px;
  height: 40px;
  //icon->font-size=28
  .icon {
    position: relative;
    top: 0;
    @extend %font-28;
  }
}

.btn-notice-box {
  position: absolute;
  bottom: 0;
  display: inline-block;
  @extend %bg-gray-5;
}
.md-button.md-icon-button {
  width: 48px;
  height: 48px;
  line-height: 46px !important;
}

.md-button.md-icon-button .icon {
  position: relative;
  top: 2px;
  @extend %font-32;
}

//**********************按钮文字大小**************
//按钮文字大小：12、14、16、18、22、28
.btn-size-12,
%btn-size-12 {
  @include btn-size(12, 0);
}
.btn-size-14,
%btn-size-14 {
  @include btn-size(14, 0);
}
.btn-size-16,
%btn-size-16 {
  @include btn-size(16, 0);
}
.btn-size-18,
%btn-size-18 {
  @include btn-size(18, 0);
}
.btn-size-20,
%btn-size-20 {
  @include btn-size(20, 0);
}
.btn-size-22,
%btn-size-22 {
  @include btn-size(22, 0);
}
.btn-size-28,
%btn-size-28 {
  @include btn-size(28, 0);
}

.btn-size-32,
%btn-size-32 {
  @include btn-size(32, 0);
}
.btn-w-112 {
  width: 112px;
}
.btn-w-280 {
  width: 280px;
}
.btn-border-gray {
  padding: 0;
  height: 34px;
  border: 1px solid #e5e5e5 !important;
}
.btn-border-gray-2 {
  padding: 0;
  height: 34px;
  border: 2px solid #e5e5e5 !important;
  i {
    position: fixed;
    left: 0;
    padding-left: 7px;
  }
}
//**********************md-tooltip**************
md-tooltip {
  border-radius: 10px;
  padding: 8px 14px;
  @extend %font-14;
  @extend %white;
  @extend %font-bold;
}
//**********************md-dialog begin**************
// 弹出层交替显示的盒子
.alternate {
  @extend %clear-fix;
}
md-dialog {
  width: 540px;
  border-radius: 8px;
  md-dialog-content {
    display: block;
    padding: 35px 35px 18px;
    overflow: hidden;
    @extend %clear-fix;
    p {
      line-height: 26px;
      @extend %font-16;
    }
    p + input {
      margin-top: 20px;
      width: 100%;
    }
    .blue {
      @extend %blue-5;
    }
  }
  .md-actions {
    padding-bottom: 16px;
    .md-button {
      margin: 0 10px;
      @extend %btn-size-16;
    }
  }
}
md-dialog.md-content-overflow .md-actions {
  border: none;
}
.dialog-dxb {
  margin-bottom: 20px;
  text-align: left;
  @extend %font-22;
  @extend %gray-7;
  .innner-subtitle {
    margin-left: 10px;
    @extend %font-14;
    @extend %gray-5;
    @extend %font-normal;
  }
}
.dialog-tip {
  @extend %font-14;
  @extend %gray-5;
  @extend %font-normal;
  text-align: left;
}

//**********************md-dialog end**************
//**********************下拉菜单 begin**************

//错误原因下拉菜单
.task-ft {
  .md-select-value {
    transition: color 0.4s ease;
    @extend %gray-4;
    &:hover {
      @extend %gray-5;
    }
  }
}

//下拉菜单里的按钮
md-select-menu {
  .md-button {
    margin: 0;
    width: 100%;
    border-radius: 0;
    text-align: left;
    @extend %btn-color-gray;
  }
}
// 下拉菜单
md-select-menu {
  border-radius: 10px;
}
md-select-menu md-content {
  @extend %white-board;
}
// 下拉菜单选中的菜单项
md-select-menu md-option[selected] {
  @extend %blue-5;
  button {
    margin: 0;
    @extend %btn-color-blue;
  }
}
md-select-menu.md-default-theme,
md-select-menu md-option[selected] {
  @extend %blue-5;
}
// 下拉菜单盖上菜单
.fixed-select-box {
  top: 128px !important;
}
// 下拉菜单颜色
md-select.md-default-theme:not([disabled]):focus,
md-select:not([disabled]):focus .md-select-value {
  @extend %gray-4;
}

//下拉菜单
md-option {
  margin: 0;
  width: 100%;
  display: block;
  // @extend %gray-5;
  &.btn-size-16,
  &.btn-size-14 {
    margin: 0;
    border-radius: 0;
  }
}
md-select-menu md-option[selected] {
  margin: 0;
  width: 100%;
  &.btn-size-16,
  &.btn-size-14 {
    margin: 0;
    border-radius: 0;
    width: 100%;
  }
}
md-select.md-default-theme,
md-select .md-select-value.md-select-placeholder {
  @extend %gray-4;
}

//**********************下拉菜单 end**************

//**********************video-layer end**************
.video-layer {
  width: 860px;
  height: 560px;
  min-height: 560px;
  @extend %radius-12;
  md-dialog-content {
    padding: 0;
    margin: 30px 30px 19px;
    height: 450px;
    @extend %bg-gray-1;
  }
  .md-actions {
    .md-button {
      @extend %btn-size-16;
    }
  }
}

//**********************video-layer end**************
//**********************form begin**************
//**********************复选框**************
//复选框
md-checkbox {
  &.md-checked {
    .md-icon {
      background-color: $blue-5;
      &:after {
        border-color: $white;
        border-width: 3px;
      }
    }
  }
  .md-icon {
    border-color: $gray-3;
  }
  &[disabled] {
    .md-icon {
      border-color: $gray-3;
      background-color: $gray-2;
    }
    &.md-checked .md-icon {
      background-color: $gray-2;
      &:after {
        border-color: $gray-3;
      }
    }
  }
}
//**********************開关**************
//開关:关
md-switch {
  .md-thumb {
    background-color: $gray-1;
  }
  .md-bar {
    background-color: $gray-3;
  }
  &.md-checked {
    .md-bar {
      background-color: $blue-3;
    }
    .md-thumb {
      background-color: $blue-5;
    }
  }
  &[disabled] {
    .md-thumb {
      background-color: $gray-3;
    }
    .md-bar {
      background-color: $gray-2;
    }
  }
}
//**********************单选按钮 begin**************
//单选按钮：
md-radio-button {
  .md-off {
    border-color: $gray-3;
  }
}
md-radio-group:not([disabled]) .md-primary .md-on,
md-radio-group:not([disabled]).md-primary .md-on,
md-radio-button:not([disabled]) .md-primary .md-on,
md-radio-button:not([disabled]).md-primary .md-on {
  background-color: $white;
}
md-radio-group:not([disabled]) .md-primary .md-checked .md-off,
md-radio-group:not([disabled]) .md-primary.md-checked .md-off,
md-radio-group:not([disabled]).md-primary .md-checked .md-off,
md-radio-group:not([disabled]).md-primary.md-checked .md-off,
md-radio-button:not([disabled]) .md-primary .md-checked .md-off,
md-radio-button:not([disabled]) .md-primary.md-checked .md-off,
md-radio-button:not([disabled]).md-primary .md-checked .md-off,
md-radio-button:not([disabled]).md-primary.md-checked .md-off {
  border-color: $blue-5;
  background-color: $blue-5;
}
md-radio-group[disabled] .md-container .md-off,
md-radio-button[disabled] .md-container .md-off {
  border-color: $gray-3;
  background-color: $gray-2;
}
//**********************单选按钮 end**************

//默认输入框 .input-text
input[type='text'],
input[type='password'],
input[type='number'] {
  padding-left: 19px;
  width: 296px;
  height: 36px;
  line-height: 36px;
  border: 2px solid $gray-2;
  transition: border 0.1s;
  @extend %font-16;
  @extend %font-bold;
  @extend %radius-18;
  @extend %gray-5;
}
input[type='text']:disabled {
  @extend %bg-gray-2;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: rgb(255, 255, 255);
  background-image: none;
  color: rgb(0, 0, 0);
}
//默认文本输入域
textarea {
  border: none;
  padding: 2px 15px;
  width: 325px;
  min-height: 150px;
  line-height: 28px !important;
  border-radius: 10px;
  transition: all 0.5s linear;
  @extend %gray-3;
  &:focus {
    @extend %gray-5;
  }
}
//placeholder color
::-webkit-input-placeholder {
  color: $gray-3;
  letter-spacing: 1px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $gray-3;
  letter-spacing: 1px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $gray-3;
  letter-spacing: 1px;
}
:-ms-input-placeholder {
  color: $gray-3;
  letter-spacing: 1px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
// 错误提示
input.error {
  border-color: $red-5;
  @extend %gray-5;
}
//错误提示 文本
.error-text {
  display: none;
  @extend %red-5;
}
// 获取焦点
input:focus {
  border-color: $gray-4;
  @extend %gray-5;
}
//失去焦点 且 内容不是空或提示文字
// .blur-text {
//   @extend %gray-5;
// }
//**********************form end**************

//**********************滑块 begin**************
// 滑块
.slider {
  position: relative;
  margin: 0 40px;
  height: 48px;
  text-align: center;
  input {
    width: 102px;
    text-align: center;
    @extend %font-16;
  }
  .error-text {
    padding-top: 10px;
  }
}

//**********************滑块 end**************

// 商城 设置成功提示
.show-tip {
  // position: absolute;
  // bottom: 30px;
  padding: 8px 14px;
  background-color: rgba(0, 0, 0, 0.6);
  @extend %radius-10;
  @extend %white;
  @extend %font-bold;
}

//**********************加载中 begin**************
md-progress-circular.md-default-theme,
md-progress-circular .md-inner .md-left .md-half-circle {
  border-left-color: $blue-5;
}
md-progress-circular.md-default-theme,
md-progress-circular .md-inner .md-left .md-half-circle,
md-progress-circular.md-default-theme,
md-progress-circular .md-inner .md-right .md-half-circle {
  border-top-color: $blue-5;
}
md-progress-circular.md-default-theme,
md-progress-circular .md-inner .md-right .md-half-circle {
  border-right-color: $blue-5;
}
//**********************加载中 end**************

//**********************tab begin**************
md-toolbar.md-warn > md-tabs.md-default-theme,
md-tabs
  > md-tabs-wrapper
  > md-tabs-canvas
  > md-pagination-wrapper
  > md-tab-item:not([disabled]) {
  @extend %gray-3;
  @extend %font-bold;
}
md-toolbar.md-warn > md-tabs.md-default-theme,
md-tabs
  > md-tabs-wrapper
  > md-tabs-canvas
  > md-pagination-wrapper
  > md-tab-item:not([disabled]).md-active,
md-toolbar.md-warn > md-tabs.md-default-theme,
md-tabs
  > md-tabs-wrapper
  > md-tabs-canvas
  > md-pagination-wrapper
  > md-tab-item:not([disabled]).md-focused {
  @extend %green-5;
}

md-tabs.md-default-theme,
md-tabs md-tabs-wrapper {
  border-color: $gray-2;
  @extend %bg-white;
}
md-toolbar.md-accent > md-tabs.md-default-theme,
md-tabs
  > md-tabs-wrapper
  > md-tabs-canvas
  > md-pagination-wrapper
  > md-ink-bar {
  background: $green-5;
}

//**********************tab end**************

// 弹出层
.texts-box {
  p {
    margin-bottom: 20px;
    line-height: 24px;
  }
}
//**********************loading-box begin**************
.loading-box {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -70px;
  margin-left: -100px;
  width: 200px;
  height: 114px;
  md-progress-circular {
    position: static;
    margin: 0 auto;
  }
  p {
    position: relative;
    top: -5px;
    line-height: 1;
    @extend %white;
  }
}
//**********************loading-box end**************
//**********************弹出层 问卷 begin**************
.radio-layer {
  margin-top: 18px;
  @extend %block;
  @extend %clear-fix;
  md-radio-button {
    width: 30%;
    margin-bottom: 14px;
    @extend %pull-left;
    @extend %font-16;
    @extend %font-bold;
    // &:nth-child(3n) {
    //   width: 40%;
    // }
  }
}
//2列
.large-row {
  md-radio-button {
    width: 50%;
  }
}
