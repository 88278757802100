.task-content {
  background-color: #f3f3f3;
  border-radius: 12px;
  position: relative;
  padding: 0 30px;
  min-height: auto;
  border-radius: 12px;
  transform: translateZ(0);
  transition: min-height 1s cubic-bezier(0.08, 0.93, 0.23, 1);
  overflow-y: auto !important;
  @extend %overflow;
  @extend %bg-gray-1;
  @extend %clear-fix;
}
//练习左侧
.task-content-left {
  padding-top: 30px;
  padding-left: 22px;
  margin-bottom: 40px;
  width: 355px;
  @extend %pull-left;
  img {
    width: 75%;
    @extend %center;
    @extend %block;
  }
}
.width-100 {
  width: 100% !important;
}
.problem-footer {
  position: absolute;
  left: 22px;
  bottom: 15px;
  font-size: 16px;
  width: 100%;

  .star {
    font-size: 14px;
    .ant-rate-star {
      margin-right: 6px;
    }
  }

  .difficulty {
    float: left;
    font-size: 14px;
    margin: 3px 6px 0 11px;
    display: inline-block;
    color: #a2a2a2;
    font-weight: bold;
  }

  .explains {
    display: inline-block;
    cursor: pointer;
    float: right;
    color: #b3b3b3;
    padding-right: 31px;
    @extend %font-16;
    font-weight: 500;
    &:hover {
      color: #1696ea;
    }
    .icon-arrow {
      padding-left: 8px;
    }
  }
  .custom-btn-span {
    margin-right: 15px;
  }
  .btn-icon {
    position: relative;
    top: -2px;
    margin-left: 8px;
    @extend %inline-block;
  }
  .check-box {
    margin-top: -2px;
    margin-right: 21px;
    color: #1797eb;
  }
  .del-problem {
    margin-right: 21px;
  }
}
.do-problem-footer {
  margin: 20px;
  @extend %clear-fix;

  button {
    float: right;
    font-size: 18px;
    color: rgba(112, 129, 160, 1);
    font-weight: bold;
  }
}
.fixed_button {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 30px;
  background: #fff;
  box-shadow: 0px -5px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px 12px 0px 0px;
  margin: 0;
  z-index: 10000;
}
.task-explains {
  position: relative;
  padding: 20px;
  display: unset;
  border: 2px solid #eaeaea;
  border-radius: 12px;
  margin-top: 20px;
  .explains-title {
    color: #5e5e5e;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .correct-answer-explains {
    color: #818181;
    margin-bottom: 16px;
  }
  .explain-content {
    color: #818181;
    line-height: 2em;
  }
}
.task-topic {
  position: relative;
  margin-bottom: 20px;
  line-height: 28px;
  @extend %gray-6;
  div {
    line-height: 28px;
  }
  .num-problem {
    position: absolute;
    left: -20px;
    top: 0;
    @extend %inline-block;
    @extend %gray-6;
  }
}
// 右侧内容
.task-content-right {
  padding-top: 30px;
  width: 325px;
  margin-bottom: 111px;
  @extend %pull-right;
  @extend %clear-fix;
}
.do-problem-task-option {
  @extend %pointer;
  &:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.12);
  }
}
.task-option {
  position: relative;
  margin-bottom: 20px;
  padding: 12px 40px 12px 50px;
  border-radius: 10px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s;
  @extend %bg-white;
  &.current {
    @extend %white;
    @extend %bg-gray-5;
    .task-num {
      border-color: $white;
      @extend %white;
    }
  }
  .wrong_answer,
  .right_answer {
    opacity: 0;
    transform: scale(0.8) translateY(-42%);
    transition: opacity 0.3s ease,
      transform 0.3s cubic-bezier(0.25, 0.1, 0.14, 2.63);
  }
  .show.wrong_answer,
  .show.right_answer {
    opacity: 1;
    transform: scale(1) translateY(-42%);
    z-index: 999;
  }
}
.task-option .svglist,
.task-answer .svglist {
  position: absolute;
  left: -41px;
  // top: -2px;
  top: 42%;
  // transform: translateY(-50%);
  width: 60px;
  height: 60px;
}
.task-answer {
  position: relative;
  margin-bottom: 20px;
  border-radius: 10px;
  @extend %bg-white;
  @extend %pointer;
  .textarea-div {
    padding: 10px 20px;
  }
  .single-input-box {
    line-height: 1.4;
    padding: 13px 0 41px 20px !important;
    width: 86%;
  }
  .single-input {
    position: absolute;
    left: 0;
    top: 17px;
    line-height: 1.4;
    padding: 0 0 0 20px !important;
    width: 86%;
    @extend %font-16;
    @extend %gray-2;
    @extend %overflow;
  }
}
.task-answer .svglist {
  position: absolute;
  left: -41px;
  top: -2px;
  width: 60px;
  height: 60px;
}
.multi-answer {
  margin-bottom: 20px;
  @extend %font-16;
}
//选择题答案内容
.answer-div {
  padding: 6px 0 4px 17px;
  border-left: 2px solid $gray-2;
  @extend %clear-fix;
}
// 键盘
.clear-all {
  display: none;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  line-height: 30px;
  &:after {
    content: '';
    width: 30px;
    height: 30px;
    background: url(./assets/iconDeleteAll.svg) no-repeat center center;
    @extend %inline-block;
  }
}
.keyboard-ul {
  padding: 5px 2px 0 4px;
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-left: 3px;
    width: 9.088888%;
    height: auto;
    text-align: center;
    transition: background-color 0.2s linear;
    @extend %bg-white;
    @extend %radius-50p;
    @extend %pointer;
    i {
      position: relative;
      font-size: 18px;
      width: 30px;
      height: 30px;
      line-height: 1.6;
      @extend %radius-50p;
      @extend %gray-5;
      @extend %inline-block;
      &:after {
        position: absolute;
        right: -2px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 1px;
        height: 20px;
        @extend %bg-gray-1;
        @extend %block;
      }
    }
    &:hover i {
      @extend %bg-gray-1;
    }
  }
  li:last-child i,
  li:nth-child(10n) i {
    &:after {
      display: none;
    }
  }
}
.handle-keyboard {
  position: absolute;
  bottom: 16px;
  right: 14px;
  i {
    width: 70px;
    height: 30px;
    line-height: 1.9;
    font-size: 14px;
    text-align: center;
    @extend %font-16;
    @extend %gray-5;
    @extend %font-bold;
    @extend %inline-block;
    @extend %pointer;
  }
}
.correct-answer {
  padding: 10px 0;
}
.task-num {
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -15px;
  display: block;
  width: 47px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  @extend %font-16;
  @extend %gray-6;
  @extend %font-bold;
}
.multi-line {
  width: 44px;
  border-bottom: 2px solid $gray-3;
  text-align: center;
  @extend %inline-block;
}
.multi {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  @extend %inline-block;
  @extend %radius-50p;
  @extend %bg-gray-3;
  @extend %gray-1;
  @extend %font-14;
  @extend %font-bold;
}
.multi-div {
  cursor: auto !important;
  .answer-div {
    padding-left: 0;
    width: 100%;
    border: none;
    @extend %pointer;
    &.multi-input-blank {
      position: relative;
      z-index: 10;
      display: flex;
      align-items: center;
    }
  }
  .no-display {
    @extend %hidden;
  }
  .input-placeholder {
    position: absolute;
    top: 50%;
    transform: translateY(-43%);
    z-index: 1;
    width: 80%;
    white-space: nowrap;
    @extend %gray-2;
  }
  &.current {
    background: white !important;
    .multi-num {
      @extend %bg-gray-5;
    }
    .multi-input {
      @extend %gray-5;
    }
  }
}
.multi-num {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 15px;
  width: 20px;
  height: 20px;
  line-height: 21px;
  text-align: center;
  @extend %radius-50p;
  @extend %inline-block;
  @extend %bg-gray-2;
  @extend %white;
  @extend %font-14;
}
.show-answer {
  font-size: 16px;
  width: 355px;
  height: 60px;
}
.keyboard,
.multiple-choice {
  display: none;
  position: absolute;
  left: 0;
  bottom: -220px;
  z-index: 100;
  width: 100%;
  height: 221px;
  @extend %white-board;
  &.show {
    display: block;
    // translateX 此动画用的右下角二维码显示效果的动画
    animation: translateX 0.5s ease 1 forwards;
  }
}
.multiple-choice-ul {
  margin: 14px 0;
  li {
    padding: 8px 20px;
    transition: background-color linear 0.4s;
    @extend %bg-white;
    &:hover {
      @extend %bg-gray-1;
    }
  }
}
.problem-icon {
  font-size: 24px;
  position: fixed;
  bottom: 10px;
}
.do-problem-div {
  min-width: 335px;
}
