.active_info {
  width: 1145px;
  height: 149px;
  background: rgba(35, 46, 77, 1);
  box-shadow: inset 0px -6px 0px 0px rgba(28, 37, 62, 1);
  border-radius: 16px;
  z-index: 2;
  margin: -20px auto 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  position: relative;
  .left {
    .intro_title {
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
    .watch {
      width: 306px;
      height: 44px;
      background: rgba(65, 90, 255, 1);
      box-shadow: inset 0px -3px 0px 0px rgba(38, 63, 228, 1);
      border-radius: 22px;
      border: 2px solid rgba(28, 37, 62, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 31px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      img {
        width: 28px;
        height: 28px;
        display: block;
        margin-right: 10px;
      }
    }
    .small {
      width: 166px;
      margin-right: 10px;
    }
    .green {
      width: 166px;
      background: rgba(0, 210, 128, 1);
      box-shadow: inset 0px -3px 0px 0px rgba(0, 158, 97, 1);
    }
    .button_block {
      display: flex;
    }
  }
  .right {
    display: flex;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 170px;
      height: 92px;
      background: rgba(255, 255, 255, 1);
      border-radius: 12px;
      border: 2px solid rgba(28, 37, 62, 1);
      position: relative;
      margin-right: 20px;
      .icon {
        width: 87px;
        height: 62px;
        display: block;
      }
      img {
        position: absolute;
        right: -20%;
        top: -30%;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .people {
      .num {
        font-size: 40px;
        font-weight: bold;
        color: rgba(45, 60, 99, 1);
        font-family: DINAlternate-Bold;
        .unit {
          font-size: 24px;
        }
      }
      .num_intro {
        font-size: 16px;
        font-weight: 500;
        color: rgba(112, 129, 160, 1);
        margin-top: 6px;
      }
    }
    .days {
      .time {
        font-size: 40px;
        font-weight: bold;
        color: rgba(45, 60, 99, 1);
        font-family: DINAlternate-Bold;
        .unit {
          font-size: 24px;
        }
      }
      .num_intro {
        font-size: 16px;
        font-weight: 500;
        color: rgba(112, 129, 160, 1);
        margin-top: 6px;
      }
    }
  }
}
@media screen and (min-height: 700px) {
  .active_info {
    margin-top: -40px !important;
  }
}
