.task-content .do-problem-task-option.task-option.current {
  background: rgba(192, 242, 251, 0.3);
  /*border-radius:9px;*/
  border: 2px solid rgba(0, 130, 255, 0.6);
  color: rgba(0, 130, 255, 1);
}
.task-content .do-problem-task-option.task-option.current .task-num {
  color: rgba(0, 130, 255, 1);
}
.task-content .do-problem-task-option.current .answer-div {
  border-left: 2px solid rgba(0, 130, 255, 1);
}
.router-enter {
  opacity: 0;
  transform: translateX(-1080px);
  z-index: 1;
}

.router-enter.router-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 250ms ease-in, transform 250ms linear;
}
.router-exit {
  opacity: 1;
  transform: translateX(0);
}
.router-exit-active {
  opacity: 0;
  transform: translateX(1080px);
  transition: opacity 250ms ease-in, transform 250ms linear;
}
/*body.vjs-full-window {*/
/*  padding: 90px !important;*/
/*}*/
.main-block__show-private-tips {
  padding-top: 114px;
}
.nav-block__show-private-tips {
  position: absolute !important;
  top: 114px !important;
}
/*.ant-select-selection:hover {*/
/*  border-right-width: 0 !important;*/
/*}*/
.enter-layer-div .task-content {
  margin: 0 !important;
}
.enter-practice .task-content {
  margin: 0 !important;
}
.ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0);
}
.ant-select-selection {
  border-radius: 20px !important;
  border: 2px solid rgba(237, 239, 243, 0.6) !important;
  padding: 0 13px;
  display: flex !important;
  align-items: center;
}
.ant-select-selection__rendered {
  display: flex !important;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-family: NotoSansTC-Bold;
  font-weight: bold;
  color: rgba(66, 86, 124, 1);
  padding: 5px 0 !important;
  width: 148px;
}
.ant-select-arrow {
  top: calc(50% - 6.5px) !important;
}
.ant-timeline-item {
  min-height: 95px;
}
.ant-timeline-item-tail {
  border-left: 2px solid #f9fafb !important;
}
.ant-modal-content .ant-modal-header {
  border-radius: 0 !important;
  border: 0 !important;
}
.ant-modal-body > div {
  padding: 0 51px;
}
.paddingTB {
  padding-top: 90px;
}
.practice_common_button {
  width: 130px;
  height: 38px;
  border-radius: 19px;
  font-size: 20px;
  font-weight: bold;
  line-height: 38px;
  text-align: center;
}
.practice_no_answer_button {
  background-color: #dbe0e7;
  color: #7081a0 !important;
}
.practice_have_answer_button {
  background: rgba(0, 130, 255, 1);
  box-shadow: 0 4px 9px 0 rgba(0, 130, 255, 0.4);
  color: rgba(255, 255, 255, 1) !important;
}
.practice_have_answer_commit_button {
  background: rgba(0, 130, 255, 1);
  box-shadow: 0 4px 9px 0 rgba(0, 130, 255, 0.4);
  color: rgba(255, 255, 255, 1) !important;
}
.new-practice-blank {
  text-align: center;
  min-width: 93px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(216, 165, 122, 1);
  display: inline-block;
  vertical-align: -4px;
  margin: 0 5px;
  line-height: 32px;
  padding: 0 8px;
}
.new-practice-blank-right {
  color: #00d280;
  border-color: #00d280;
}
.new-practice-blank-error {
  color: #ff7a7d;
  border-color: #ff7a7d;
}
