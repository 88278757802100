.rate {
  background-image: url('../../../../assets/deliberate-practice/rank.png');
  width: 860px;
  height: 228px;
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 17px auto 15px;
  padding: 30px 0 35px;
  display: flex;
  .show_rate {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    .none {
      width: 23px;
      height: 10px;
      background-color: white;
      margin: 16px 0;
    }
    .rate_score {
      font-size: 56px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      font-family: DINAlternate-Bold;
      margin-top: 23px;
    }
    .rate_text {
      font-size: 18px;
      font-weight: 500;
      color: rgba(148, 161, 184, 1);
      margin: 10px 0;
    }
    .rate_name {
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
  }
}
.bottom1 {
  position: absolute;
  bottom: 26px;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  img {
    width: 532px;
    height: 30px;
    display: block;
  }
  .b {
    width: 860px;
    height: 60px;
    z-index: 1;
    position: relative;
    .h {
      width: 860px;
      height: 60px;
      transform: skewX(165deg);
      backdrop-filter: blur(7px);
      background: rgba(69, 81, 112, 0.3);
      box-shadow: 0px 0px 40px 0px rgba(28, 37, 63, 0.6);
    }
    .d {
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      height: 100%;
      top: 0;
      left: 0;
    }
    .bb {
      font-size: 28px;
      font-weight: bold;
      color: rgba(255, 180, 0, 1);
      font-family: DINAlternate-Bold;
    }
    .bbb {
      .ab {
        font-size: 28px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        font-family: DINAlternate-Bold;
      }
      .ac {
        font-size: 24px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        margin-left: 16px;
      }
    }
  }
}
.scroll {
  max-height: 225px;
  height: 225px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 860px;
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .s_item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 50px;
    }
    .s_rate {
      .s_a {
        font-size: 28px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        font-family: DINAlternate-Bold;
      }
      .s_b {
        font-size: 24px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        margin-left: 26px;
      }
    }
    .s_c {
      font-size: 28px;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      font-family: DINAlternate-Bold;
    }
  }
}
