.a {
  background-image: url('../../assets/deliberate-practice/entry-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.common_image {
  display: block;
  width: 100%;
  height: 100%;
}
.common_btn {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
}
.common_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav_name {
  width: 294px;
}
.entry {
  margin: 166px auto 0;
  position: relative;
  width: 1680px;
  height: 800px;
  .item {
    width: 360px;
    height: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    img {
      display: block;
      width: 139px;
      height: 36px;
    }
    .compose {
      position: relative;
      .flag {
        display: block;
        width: 139px;
        height: 36px;
      }
      .activity {
        width: 109px;
        height: 78px;
        display: block;
        margin: 0 auto;
        position: absolute;
        left: 15px;
        top: 18px;
      }
    }
    &:hover {
      animation-play-state: paused;
    }
  }
  .item1 {
    position: absolute;
    top: 0;
    left: 0;
    animation: entry-animate1 5s linear infinite;
    transform: translateZ(0);
    height: 420px;
    @keyframes entry-animate1 {
      0% {
        transform: translate3d(0, 0, 0);
      }
      25% {
        transform: translate3d(0, -15px, 0);
      }
      50% {
        transform: translate3d(0, 0, 0);
      }
      75% {
        transform: translate3d(0, 15px, 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .item2 {
    position: absolute;
    top: 0;
    left: 920px;
    animation: entry-animate2 5s linear 1s infinite;
    transform: translateZ(0);
    @keyframes entry-animate2 {
      0% {
        transform: translate3d(0, 0, 0);
      }
      25% {
        transform: translate3d(0, -15px, 0);
      }
      50% {
        transform: translate3d(0, 0, 0);
      }
      75% {
        transform: translate3d(0, 15px, 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .item3 {
    position: absolute;
    top: 357px;
    right: 920px;
    animation: entry-animate3 3s linear 500ms infinite;
    transform: translateZ(0);
    @keyframes entry-animate3 {
      0% {
        transform: translate3d(0, 0, 0);
      }
      25% {
        transform: translate3d(0, -15px, 0);
      }
      50% {
        transform: translate3d(0, 0, 0);
      }
      75% {
        transform: translate3d(0, 15px, 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  .item4 {
    position: absolute;
    top: 357px;
    right: 0;
    animation: entry-animate4 3s linear 1.5s infinite;
    transform: translateZ(0);
    @keyframes entry-animate4 {
      0% {
        transform: translate3d(0, 0, 0);
      }
      25% {
        transform: translate3d(0, -15px, 0);
      }
      50% {
        transform: translate3d(0, 0, 0);
      }
      75% {
        transform: translate3d(0, 15px, 0);
      }
      100% {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}
.broadcast {
  width: 610px;
  height: 64px;
  background: rgba(255, 120, 103, 1);
  box-shadow: 0px 4px 24px 0px rgba(255, 120, 103, 0.5);
  border-radius: 16px;
  font-size: 26px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 64px;
  position: absolute;
  top: -90px;
  left: calc((940px - 610px) / 2);
}
.model {
  width: 940px;
  height: 678px;
  background-image: url('../../assets/deliberate-practice/model-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  .close {
    width: 108px;
    height: 55px;
    background-image: url('../../assets/deliberate-practice/close.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .tab {
    position: absolute;
    left: -40px;
    top: 80px;
  }
  .head {
    padding: 33px 0 0 18px;
    width: 940px;
    overflow: hidden;
    display: flex;
    .icon {
      display: block;
      width: 252px;
      height: 243px;
      margin-right: 30px;
    }
    .title_block {
      margin-top: 59px;
      img {
        display: block;
        width: 139px;
        height: 36px;
      }
      .intro {
        margin-top: 22px;
        width: 600px;
        font-size: 24px;
        font-weight: 500;
        color: rgba(183, 192, 207, 1);
        line-height: 1.5;
      }
    }
  }
  .dashed {
    width: 860px;
    margin: 9px auto 25px;
    background-image: url('../../assets/deliberate-practice/line.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 1px;
  }
  .entry_choose {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    .one {
      background-image: url('../../assets/deliberate-practice/one.png');
    }
    .two,
    .three,
    .one {
      width: 253px;
      height: 307px;
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      .tool {
        width: 101px;
        height: 87px;
        margin: 0 auto 71px;
      }
      .score {
        font-size: 56px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        font-family: DINAlternate-Bold;
      }
      .score_text {
        font-size: 18px;
        font-weight: 500;
        color: rgba(148, 161, 184, 1);
        margin-top: 10px;
        margin-bottom: 30px;
      }
      .go {
        margin-bottom: 17px;
        width: 212px;
        height: 49px;
        display: block;
        cursor: pointer;
      }
    }
    .two {
      background-image: url('../../assets/deliberate-practice/two.png');
    }
    .three {
      background-image: url('../../assets/deliberate-practice/three.png');
    }
  }

  .ww {
    width: 940px;
    overflow: hidden;
    .tas {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 366px;
      margin: 60px auto 0;
    }
  }
  .bottom {
    position: absolute;
    bottom: 26px;
    left: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    img {
      width: 532px;
      height: 30px;
      display: block;
    }
    .b {
      width: 860px;
      height: 60px;
      z-index: 1;
      position: relative;
      .h {
        width: 860px;
        height: 60px;
        transform: skewX(165deg);
        backdrop-filter: blur(7px);
        background: rgba(69, 81, 112, 0.3);
        box-shadow: 0px 0px 40px 0px rgba(28, 37, 63, 0.6);
      }
      .d {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        height: 100%;
        top: 0;
        left: 0;
      }
      .bb {
        font-size: 28px;
        font-weight: bold;
        color: rgba(255, 180, 0, 1);
        font-family: DINAlternate-Bold;
      }
      .bbb {
        .ab {
          font-size: 28px;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
          font-family: DINAlternate-Bold;
        }
        .ac {
          font-size: 24px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          margin-left: 16px;
        }
      }
    }
  }
}

.nulls {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  img {
    width: 120px;
  }
}
.no_rank {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 570px;
  img {
    display: block;
    width: 141.4px;
    height: 121.8px;
  }
}
.entry_container {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .entry {
    transform: scale(0.7);
    margin: 0;
    width: 1440px;
    .item2 {
      left: 720px;
    }

    .item3 {
      right: 765px;
    }
  }
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-height: 700px) {
  .entry {
    transform: scale(7);
    margin: 0;
    width: 1440px;
    .item2 {
      left: 720px;
    }
    .item3 {
      right: 765px;
    }
  }
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .entry {
    transform: scale(0.5);
    margin-top: -130px;
    width: 100%;
    .item2 {
      left: 720px;
    }

    .item3 {
      right: 765px;
    }
  }
  .model {
    transform: scale(0.5);
  }
  .nav_name {
    width: 294 / 28 * 16px;
  }
}
@media screen and (min-width: 1441px) {
  .entry {
    transform: scale(0.8);
    margin-top: 56px;
  }
}
@media screen and (min-height: 701px) and (max-height: 860px) {
  .entry {
    margin-top: 0;
    transform: scale(0.65);
  }
}
