@font-face {
  font-family: 'icon1550140048992';
  src: url('./assets/icon1550140048992.eot');
  src: url('./assets/icon1550140048992.eot?#iefix') format('eot'),
    url('./assets/icon1550140048992.woff') format('woff'),
    url('./assets/icon1550140048992.ttf') format('truetype'),
    url('./assets/icon1550140048992.svg#icon1550140048992') format('svg');
}

.icon:before {
  font-family: 'icon1550140048992';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  /* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
  text-decoration: none;
  text-transform: none;
}

.icon-KeyFractional:before {
  content: '\E001';
}

.icon-KeyPowerFunction:before {
  content: '\E002';
}

.icon-KeyRootSign:before {
  content: '\E003';
}

.icon-VIP:before {
  content: '\E004';
}

.icon-actiivty:before {
  content: '\E005';
}

.icon-add_student:before {
  content: '\E006';
}

.icon-answer_video:before {
  content: '\E007';
}

.icon-arrow-back:before {
  content: '\E008';
}

.icon-arrow-down:before {
  content: '\E009';
}

.icon-arrow-forward:before {
  content: '\E00A';
}

.icon-autorenew:before {
  content: '\E00B';
}

.icon-binding:before {
  content: '\E00C';
}

.icon-book:before {
  content: '\E00D';
}

.icon-bookmark:before {
  content: '\E00E';
}

.icon-certified:before {
  content: '\E00F';
}

.icon-check-box-outline:before {
  content: '\E010';
}

.icon-check-box:before {
  content: '\E011';
}

.icon-check:before {
  content: '\E012';
}

.icon-circle:before {
  content: '\E013';
}

.icon-class_manage:before {
  content: '\E014';
}

.icon-close:before {
  content: '\E015';
}

.icon-cloud-outline:before {
  content: '\E016';
}

.icon-coin-2:before {
  content: '\E017';
}

.icon-coins:before {
  content: '\E018';
}

.icon-complete_list:before {
  content: '\E019';
}

.icon-copy:before {
  content: '\E01A';
}

.icon-correction:before {
  content: '\E01B';
}

.icon-dashboard:before {
  content: '\E01C';
}

.icon-dashed:before {
  content: '\E01D';
}

.icon-data:before {
  content: '\E01E';
}

.icon-delete:before {
  content: '\E01F';
}

.icon-delete_student:before {
  content: '\E020';
}

.icon-download-2:before {
  content: '\E021';
}

.icon-download:before {
  content: '\E022';
}

.icon-edit:before {
  content: '\E023';
}

.icon-exit:before {
  content: '\E024';
}

.icon-expand_less:before {
  content: '\E025';
}

.icon-expand_more:before {
  content: '\E026';
}

.icon-face:before {
  content: '\E027';
}

.icon-fast-forward:before {
  content: '\E028';
}

.icon-fast-rewind:before {
  content: '\E029';
}

.icon-flag:before {
  content: '\E02A';
}

.icon-folder:before {
  content: '\E02B';
}

.icon-full-screen:before {
  content: '\E02C';
}

.icon-full_screen_exit:before {
  content: '\E02D';
}

.icon-group:before {
  content: '\E02E';
}

.icon-help:before {
  content: '\E02F';
}

.icon-help_circle:before {
  content: '\E030';
}

.icon-history:before {
  content: '\E031';
}

.icon-ic_add:before {
  content: '\E032';
}

.icon-ic_calendar:before {
  content: '\E033';
}

.icon-ic_link:before {
  content: '\E034';
}

.icon-ic_message:before {
  content: '\E035';
}

.icon-ic_minus:before {
  content: '\E036';
}

.icon-ic_quit:before {
  content: '\E037';
}

.icon-ic_target:before {
  content: '\E038';
}

.icon-icon_calendar:before {
  content: '\E039';
}

.icon-icon_teacher:before {
  content: '\E03A';
}

.icon-increase:before {
  content: '\E03B';
}

.icon-info-outline:before {
  content: '\E03C';
}

.icon-key-a:before {
  content: '\E03D';
}

.icon-key-b:before {
  content: '\E03E';
}

.icon-key-c:before {
  content: '\E03F';
}

.icon-key-m:before {
  content: '\E040';
}

.icon-key-n:before {
  content: '\E041';
}

.icon-key-x:before {
  content: '\E042';
}

.icon-key-y:before {
  content: '\E043';
}

.icon-key-z:before {
  content: '\E044';
}

.icon-key:before {
  content: '\E045';
}

.icon-key0:before {
  content: '\E046';
}

.icon-key1:before {
  content: '\E047';
}

.icon-key2:before {
  content: '\E048';
}

.icon-key3:before {
  content: '\E049';
}

.icon-key4:before {
  content: '\E04A';
}

.icon-key5:before {
  content: '\E04B';
}

.icon-key6:before {
  content: '\E04C';
}

.icon-key7:before {
  content: '\E04D';
}

.icon-key8:before {
  content: '\E04E';
}

.icon-key9:before {
  content: '\E04F';
}

.icon-keyA:before {
  content: '\E050';
}

.icon-keyB:before {
  content: '\E051';
}

.icon-keyC:before {
  content: '\E052';
}

.icon-keyD:before {
  content: '\E053';
}

.icon-keyE:before {
  content: '\E054';
}

.icon-keyF:before {
  content: '\E055';
}

.icon-keyG:before {
  content: '\E056';
}

.icon-keyH:before {
  content: '\E057';
}

.icon-keyLessThan:before {
  content: '\E058';
}

.icon-keyLessThanOrEqual:before {
  content: '\E059';
}

.icon-keyM:before {
  content: '\E05A';
}

.icon-keyMinus:before {
  content: '\E05B';
}

.icon-keyMoreThan:before {
  content: '\E05C';
}

.icon-keyMoreThanOrEqual:before {
  content: '\E05D';
}

.icon-keyMultiply:before {
  content: '\E05E';
}

.icon-keyN:before {
  content: '\E05F';
}

.icon-keyO:before {
  content: '\E060';
}

.icon-keyP:before {
  content: '\E061';
}

.icon-keyParallel:before {
  content: '\E062';
}

.icon-keyPlus:before {
  content: '\E063';
}

.icon-keyPlusMinus:before {
  content: '\E064';
}

.icon-keyPoint:before {
  content: '\E065';
}

.icon-keyQ:before {
  content: '\E066';
}

.icon-keyVertical:before {
  content: '\E067';
}

.icon-left:before {
  content: '\E068';
}

.icon-list-view:before {
  content: '\E069';
}

.icon-lock:before {
  content: '\E06A';
}

.icon-low-definition:before {
  content: '\E06B';
}

.icon-management:before {
  content: '\E06C';
}

.icon-menu:before {
  content: '\E06D';
}

.icon-message:before {
  content: '\E06E';
}

.icon-more-vert:before {
  content: '\E06F';
}

.icon-more_vert:before {
  content: '\E070';
}

.icon-not-begin:before {
  content: '\E071';
}

.icon-not-master:before {
  content: '\E072';
}

.icon-pass:before {
  content: '\E073';
}

.icon-pause-2:before {
  content: '\E074';
}

.icon-pause:before {
  content: '\E075';
}

.icon-person:before {
  content: '\E076';
}

.icon-phone_signal:before {
  content: '\E077';
}

.icon-place-outline:before {
  content: '\E078';
}

.icon-place:before {
  content: '\E079';
}

.icon-play-arrow:before {
  content: '\E07A';
}

.icon-qq:before {
  content: '\E07B';
}

.icon-refresh:before {
  content: '\E07C';
}

.icon-return-back:before {
  content: '\E07D';
}

.icon-right:before {
  content: '\E07E';
}

.icon-ring:before {
  content: '\E07F';
}

.icon-school_bag:before {
  content: '\E080';
}

.icon-score:before {
  content: '\E081';
}

.icon-search:before {
  content: '\E082';
}

.icon-select_all:before {
  content: '\E083';
}

.icon-service:before {
  content: '\E084';
}

.icon-setting:before {
  content: '\E085';
}

.icon-share:before {
  content: '\E086';
}

.icon-skip-next:before {
  content: '\E087';
}

.icon-skip-pre:before {
  content: '\E088';
}

.icon-snow:before {
  content: '\E089';
}

.icon-stage:before {
  content: '\E08A';
}

.icon-star:before {
  content: '\E08B';
}

.icon-store:before {
  content: '\E08C';
}

.icon-student_list:before {
  content: '\E08D';
}

.icon-teachers:before {
  content: '\E08E';
}

.icon-ticket-2:before {
  content: '\E08F';
}

.icon-ticket:before {
  content: '\E090';
}

.icon-tree-view:before {
  content: '\E091';
}

.icon-user_setting_push:before {
  content: '\E092';
}

.icon-user_setting_share:before {
  content: '\E093';
}

.icon-video-library:before {
  content: '\E094';
}

.icon-video:before {
  content: '\E095';
}

.icon-vip-2:before {
  content: '\E096';
}

.icon-vip-video:before {
  content: '\E097';
}

.icon-visibility:before {
  content: '\E098';
}

.icon-visibility_off:before {
  content: '\E099';
}

.icon-volume:before {
  content: '\E09A';
}

.icon-volume_off:before {
  content: '\E09B';
}

.icon-windows:before {
  content: '\E09C';
}

.icon-wrong:before {
  content: '\E09D';
}

.icon-z-close:before {
  content: '\E09E';
}

.icon-z0:before {
  content: '\E09F';
}

.icon-z1:before {
  content: '\E0A0';
}

.icon-z2:before {
  content: '\E0A1';
}

.icon-z3:before {
  content: '\E0A2';
}

.icon-z4:before {
  content: '\E0A3';
}

.icon-z5:before {
  content: '\E0A4';
}

.icon-z6:before {
  content: '\E0A5';
}

.icon-z7:before {
  content: '\E0A6';
}

.icon-z8:before {
  content: '\E0A7';
}

.icon-z9:before {
  content: '\E0A8';
}

.icon-zA:before {
  content: '\E0A9';
}

.icon-zAllinclude:before {
  content: '\E0AA';
}

.icon-zAmazing:before {
  content: '\E0AB';
}

.icon-zArrow:before {
  content: '\E0AC';
}

.icon-zB:before {
  content: '\E0AD';
}

.icon-zC:before {
  content: '\E0AE';
}

.icon-zCap:before {
  content: '\E0AF';
}

.icon-zCos:before {
  content: '\E0B0';
}

.icon-zCup:before {
  content: '\E0B1';
}

.icon-zD:before {
  content: '\E0B2';
}

.icon-zE:before {
  content: '\E0B3';
}

.icon-zEqual:before {
  content: '\E0B4';
}

.icon-zF:before {
  content: '\E0B5';
}

.icon-zFractional:before {
  content: '\E0B6';
}

.icon-zG:before {
  content: '\E0B7';
}

.icon-zH:before {
  content: '\E0B8';
}

.icon-zI:before {
  content: '\E0B9';
}

.icon-zIinfty:before {
  content: '\E0BA';
}

.icon-zJ:before {
  content: '\E0BB';
}

.icon-zK:before {
  content: '\E0BC';
}

.icon-zL:before {
  content: '\E0BD';
}

.icon-zLeftallinclude:before {
  content: '\E0BE';
}

.icon-zLessThan:before {
  content: '\E0BF';
}

.icon-zLessThanOrEqual:before {
  content: '\E0C0';
}

.icon-zLg:before {
  content: '\E0C1';
}

.icon-zM:before {
  content: '\E0C2';
}

.icon-zMinus:before {
  content: '\E0C3';
}

.icon-zMoreThan:before {
  content: '\E0C4';
}

.icon-zMoreThanOrEqual:before {
  content: '\E0C5';
}

.icon-zMultiply:before {
  content: '\E0C6';
}

.icon-zN:before {
  content: '\E0C7';
}

.icon-zNotallinclude:before {
  content: '\E0C8';
}

.icon-zO:before {
  content: '\E0C9';
}

.icon-zP:before {
  content: '\E0CA';
}

.icon-zPlus:before {
  content: '\E0CB';
}

.icon-zPlusMinus:before {
  content: '\E0CC';
}

.icon-zPoint:before {
  content: '\E0CD';
}

.icon-zPowerFunction:before {
  content: '\E0CE';
}

.icon-zQ:before {
  content: '\E0CF';
}

.icon-zR:before {
  content: '\E0D0';
}

.icon-zRightallinclude:before {
  content: '\E0D1';
}

.icon-zRootSign:before {
  content: '\E0D2';
}

.icon-zRootSignStrong:before {
  content: '\E0D3';
}

.icon-zS:before {
  content: '\E0D4';
}

.icon-zSin:before {
  content: '\E0D5';
}

.icon-zT:before {
  content: '\E0D6';
}

.icon-zTan:before {
  content: '\E0D7';
}

.icon-zTextA:before {
  content: '\E0D8';
}

.icon-zTextC:before {
  content: '\E0D9';
}

.icon-zU:before {
  content: '\E0DA';
}

.icon-zV:before {
  content: '\E0DB';
}

.icon-zW:before {
  content: '\E0DC';
}

.icon-zX:before {
  content: '\E0DD';
}

.icon-zY:before {
  content: '\E0DE';
}

.icon-zZ:before {
  content: '\E0DF';
}

.icon-zaa:before {
  content: '\E0E0';
}

.icon-zalpha:before {
  content: '\E0E1';
}

.icon-zbb:before {
  content: '\E0E2';
}

.icon-zbeta:before {
  content: '\E0E3';
}

.icon-zcc:before {
  content: '\E0E4';
}

.icon-zclose:before {
  content: '\E0E5';
}

.icon-zdd:before {
  content: '\E0E6';
}

.icon-zdelete:before {
  content: '\E0E7';
}

.icon-zee:before {
  content: '\E0E8';
}

.icon-zero:before {
  content: '\E0E9';
}

.icon-zff:before {
  content: '\E0EA';
}

.icon-zgamma:before {
  content: '\E0EB';
}

.icon-zgg:before {
  content: '\E0EC';
}

.icon-zhh:before {
  content: '\E0ED';
}

.icon-zii:before {
  content: '\E0EE';
}

.icon-zjj:before {
  content: '\E0EF';
}

.icon-zkk:before {
  content: '\E0F0';
}

.icon-zlearnclass:before {
  content: '\E0F1';
}

.icon-zll:before {
  content: '\E0F2';
}

.icon-zln:before {
  content: '\E0F3';
}

.icon-zlog:before {
  content: '\E0F4';
}

.icon-zmm:before {
  content: '\E0F5';
}

.icon-znn:before {
  content: '\E0F6';
}

.icon-znotEqual:before {
  content: '\E0F7';
}

.icon-zoo:before {
  content: '\E0F8';
}

.icon-zpi:before {
  content: '\E0F9';
}

.icon-zpp:before {
  content: '\E0FA';
}

.icon-zqq:before {
  content: '\E0FB';
}

.icon-zrr:before {
  content: '\E0FC';
}

.icon-zseeme:before {
  content: '\E0FD';
}

.icon-zss:before {
  content: '\E0FE';
}

.icon-ztheta:before {
  content: '\E0FF';
}

.icon-ztt:before {
  content: '\E100';
}

.icon-zuu:before {
  content: '\E101';
}

.icon-zvv:before {
  content: '\E102';
}

.icon-zww:before {
  content: '\E103';
}

.icon-zxx:before {
  content: '\E104';
}

.icon-zyy:before {
  content: '\E105';
}

.icon-zzz:before {
  content: '\E106';
}
