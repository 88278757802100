//**********************登录、注册、找回必码、重置密码 begin**************
// 加上这个登录、注册页body\html 才有高度
html {
  height: 100%;
}
// 默认button
button {
  border: none;
  background: none;
}
//input error 提示动画
@keyframes inScale {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
// 盒子
.box-frame {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -180px;
  margin-top: -250px;
  padding: 20px 29px 20px;
  width: 360px;
  text-align: center;
  @extend %clear-fix;
  @extend %white-board;
  button {
    margin: 0 auto;
    width: 210px;
    height: 34px;
    @extend %btn-size-16;
  }
  .btn-width-auto {
    width: auto;
  }
  //input盒子
  .row-box {
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    //对错提示
    .svgsignup {
      position: absolute;
      top: 16px;
      right: 15px;
      opacity: 0;
      transform: scale(0.85);
      @extend %pointer;
      &.show {
        animation: inScale 0.1s forwards;
      }
    }
  }

  .verification {
    input[type='phoneVerificationCode'] {
      padding-left: 19px;
      width: 162px;
      height: 36px;
      display: inline-block;
      line-height: 36px;
      border: 2px solid $gray-2;
      transition: border 0.1s;
      @extend %font-16;
      @extend %font-bold;
      @extend %radius-18;
      @extend %gray-5;
    }

    input[type='phoneVerificationCode']:focus {
      border: 2px solid $gray-4;
    }
    input[type='phoneVerificationCode']:disabled {
      border: 2px solid $gray-2;
      background-color: $gray-2;
    }
    button {
      margin-left: 12px;
      display: inline-block;
      width: 119px;
      font-size: 14px !important;
    }
  }

  .row-list {
    padding-bottom: 38px;
    li {
      display: inline-block;
      width: 49%;
    }
    .p {
      text-align: left;
      padding-left: 18px;
      span {
        @extend %gray-3;
        @extend %pointer;
        font-weight: 500;
      }
    }
    .p2 {
      text-align: right;
      padding-right: 18px;
      span {
        position: relative;
        @extend %blue-5;
        @extend %pointer;
        font-weight: 500;
      }
    }
  }
  .pass-p {
    padding-bottom: 38px;
    padding-left: 18px;
    text-align: left;
    span {
      @extend %gray-3;
      @extend %pointer;
    }
  }
  .pass-p2 {
    padding-bottom: 38px;
    padding-left: 18px;
    text-align: left;
    a {
      @extend %gray-3;
      @extend %pointer;
    }
  }
  .social_icon_QQ {
    position: relative;
    top: -4px;
    width: 24px;
    height: 24px;
    @extend %pull-left;
  }
  .link-p {
    padding-top: 16px;
    text-align: center;
    @extend %font-16;
    span {
      margin: 0;
      @extend %btn-size-16;
      @extend %font-normal;
    }
  }
  .hr-5 {
    margin-top: 54px;
    margin-bottom: 20px;
  }
  .btn-line {
    margin-left: 12px;
    padding-left: 13px;
    display: block;
    border-left: 2px solid $gray-2;
    @extend %pull-left;
  }
  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }

  .blue {
    @extend %blue-5;
  }
  .green {
    @extend %green-5;
  }
  // 间隙
  .space-1 {
    margin-top: 100px;
    margin-bottom: 11px;
  }
  .space-2 {
    margin-bottom: 17px;
  }
  .space-3 {
    margin-top: 23px;
    margin-bottom: 44px;
  }
  .space-4 {
    display: block;
    margin-top: 70px;
    margin-bottom: 19px;
  }
  .space-5 {
    margin-top: 30px;
    margin-bottom: 12px;
  }
  .space-6 {
    margin-top: 35px;
    margin-bottom: 25px;
  }
  .space-7 {
    margin-bottom: 22px;
  }
  .space-8 {
    margin-top: 80px;
    margin-bottom: 11px;
  }
  // btn盒子
  .btn-box {
    padding-top: 10px;
  }
  .btn-box-2 {
    button {
      width: auto;
    }
  }
  .btn-box-3 {
    padding-top: 9px;
    button {
      margin-bottom: 10px;
    }
  }
  .btn-box-4 {
    margin-top: 84px;
  }
  .btn-box-5 {
    margin-top: 77px;
  }
}
//input error 提示动画
@keyframes inShow {
  0% {
    opacity: 0;
    transform: translateX(-4px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
//注册
.label-space {
  margin-left: 36px;
}
//单选
.radio-box {
  padding-top: 17px;
  padding-bottom: 7px;
  // text-align: left;
  input {
    display: none;
  }
  // p {
  // 	@extend %font-16;
  // 	@extend %gray-3;
  // }
}
// 复选
.checkbox-box {
  padding-top: 2px;
  padding-bottom: 37px;
  input {
    display: none;
  }
}
// **************input单选框 点击有光圈效果 begin ******************
.theme-radio {
  // margin-top: 34px;
  font-size: 0;
  @extend %pointer;
  // @extend %block;
  span {
    position: relative;
    top: 2px;
    margin-right: 10px;
    display: inline-block;
    width: 18px;
    height: 18px;
    @extend %radius-50p;
    i {
      position: absolute;
      left: 1px;
      top: 1px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      opacity: 0;
      transform: scale(1, 1);
      transform-origin: center center;
      opacity: 1;
      @extend %bg-gray-1;
    }
    em {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 2px solid $gray-3;
      transition: all 0.2s linear;
      @extend %radius-50p;
      @extend %bg-white;
    }
  }
  strong {
    @extend %font-18;
  }
  //点击或选中加类current 时
  &:active,
  &.current {
    span {
      em {
        border: 5px solid $blue-5;
        @extend %white;
      }
      i {
        animation: highlight 0.2s linear 1;
      }
    }
  }
}
// 高光光圈动画
@keyframes highlight {
  0% {
    transform: scale(1, 1);
    transform-origin: center center;
    opacity: 1;
  }
  100% {
    transform: scale(4, 4);
    transform-origin: center center;
    opacity: 0;
  }
}
// 高光光圈动画
// @keyframes highlight-2 {
// 	0% {
// 		transform: scale(1,1);
// 		opacity: 1;
// 	}
// 	100% {
// 		transform: scale(20,20);
// 		opacity: 0;
// 	}
// }
// **************input单选框 点击有光圈效果 end ******************

// **************input复选框 点击有光圈效果 begin ******************
.theme-checkbox {
  font-size: 0;
  @extend %pointer;
  span {
    position: relative;
    top: 4px;
    margin-right: 10px;
    display: inline-block;
    width: 18px;
    height: 18px;
    @extend %radius-50p;
    i {
      position: absolute;
      left: 1px;
      top: 1px;
      display: inline-block;
      width: 16px;
      height: 16px;
      opacity: 0;
      @extend %bg-gray-1;
      @extend %radius-50p;
    }
    em {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 2px solid $gray-3;
      transition: all 0.2s linear;
      @extend %bg-white;
    }
  }
  strong {
    @extend %gray-3;
    @extend %font-14;
  }
  //
  &:active,
  &.current {
    span {
      em {
        border: 0;
        @extend %bg-blue-5;
        &:after {
          border-color: #e5f8ff;
          border-width: 3px !important;
          box-sizing: border-box;
          transform: rotate(45deg);
          position: absolute;
          left: 6px;
          top: 2px;
          display: table;
          width: 6px;
          height: 12px;
          border-width: 2px;
          border-style: solid;
          border-top: 0;
          border-left: 0;
          content: '';
        }
      }
      i {
        animation: highlight 0.2s linear 1;
      }
    }
  }
}
// **************input复选框 点击有光圈效果 end ******************

// **************btn 点击有光圈效果 begin ******************
// 按钮光圈 （注册按钮加了）
// button {
// .light {
// left: 50%;
// top: 50%;
// margin-top: -8px;
// margin-left: -8px;
// display: block;
// width: 16px;
// height: 16px;
// border-radius: 50%;
// opacity: 0;
// @extend %bg-gray-1;
// }
// 点击按钮时的光圈效果
// &:active {
// 	.light {
// 		animation: highlight-2 .3s linear 1;
// 	}
// }
// }
// **************btn 点击有光圈效果 end ******************

//找回密码
.frame-2 {
  height: 500px;
}
// 大标题
.bf-headline {
  @extend %font-28;
  @extend %gray-7;
}
// 小标题
.bf-subtitle {
  @extend %font-16;
  @extend %font-normal;
}
// 小标题-2
.bf-subtitle-2 {
  line-height: 24px;
  @extend %font-16;
  @extend %gray-3;
  @extend %font-normal;
}
// 验证码
.verification-code {
  width: 300px;
  height: 56px;
  @extend %center;
  @extend %bg-gray-1;
  img {
    display: block;
    width: 300px;
    height: 56px;
  }
}
// CHROM关闭密码大写提示icon
#password,
#confirm {
  // display: block;
  @extend %inline-block;
}
//**********************登录、注册、找回必码、重置密码 end**************
//**********************未登录云、飞船 end**************
.clouds-box {
  width: 100%;
  min-width: 1024px;
  height: 190px;
  position: relative;
}
@keyframes BackgroundAnimated {
  from {
    background-position: 1440px 0;
  }
  to {
    background-position: －1440px 0;
  }
}
@keyframes BackgroundAnimated-b {
  from {
    background-position: 920px 0;
  }
  to {
    background-position: -144px 0;
  }
}
@keyframes BackgroundAnimated-c {
  from {
    background-position: 1300px 0;
  }
  to {
    background-position: -200px 0;
  }
}
@keyframes BackgroundAnimated-d {
  from {
    background-position: 1520px 0;
  }
  to {
    background-position: -174px 0;
  }
}
@keyframes BackgroundAnimated-areoboat {
  from {
    background-position: 1190px 0;
  }
  to {
    background-position: -160px 0;
  }
}

//**********************未登录云、飞船 end**************
//**********************登录信息异常 begin**************
.infor-box {
  height: 82px;
}
.infor-img {
  position: relative;
  margin-top: 10px;
  width: 56px;
  height: 56px;
  @extend %block;
  @extend %pull-left;
  img {
    width: 56px;
    height: 56px;
    @extend %radius-50p;
    @extend %block;
  }
  i {
    position: absolute;
    right: -7px;
    bottom: 3px;
    width: 26px;
    height: 26px;
    @extend %block;
  }
}
.infor-text {
  margin-top: 26px;
  width: 240px;
  height: 24px;
  @extend %pull-left;
  @extend %overflow;
  i {
    margin-left: 20px;
    margin-right: 6px;
    width: 24px;
    height: 24px;
    @extend %block;
    @extend %pull-left;
  }
  span {
    line-height: 28px;
    @extend %font-14;
    @extend %gray-3;
    @extend %block;
    @extend %pull-left;
  }
}
.infor-p {
  padding-top: 28px;
  padding-bottom: 15px;
  line-height: 24px;
  text-align: left;
  @extend %font-16;
  @extend %gray-5;
}
.box-frame .infor-mart {
  margin-top: 24px;
  margin-bottom: 10px;
}
//
//
//**********************登录信息异常 end**************
//**********************btn 摇摆 end**************
.animatedBtn {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.shakeBtn {
  animation-name: shakeBtn;
}
@keyframes shakeBtn {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10% {
    transform: translate3d(-8px, 0, 0);
  }
  30% {
    transform: translate3d(8px, 0, 0);
  }
  50% {
    transform: translate3d(-5px, 0, 0);
  }
  70% {
    transform: translate3d(5px, 0, 0);
  }
}

//**********************btn 摇摆 end**************
//********************** 选择你的身份  begin **********************
.ident-space {
  line-height: 1;
  margin-top: 100px;
}
.box-frame .ident-btn {
  margin-bottom: 100px;
}

.box-frame #roleErr {
  top: 32px;
}
.identity-box {
  padding-top: 4px;
  padding-bottom: 42px;
  text-align: left;
  .theme-radio {
    margin-top: 36px;
    width: 178px;
    @extend %block;
    @extend %center;
    p {
      padding-left: 28px;
      line-height: 1;
      @extend %font-16;
      @extend %gray-3;
    }
  }
}

//********************** 选择你的身份  end **********************
//********************** 绑定洋蔥账号  begin **********************
.bindId-space {
  line-height: 1;
  padding-top: 48px;
}
.bindId-subtitle {
  margin-top: 12px;
  padding-bottom: 30px;
  line-height: 26px;
  @extend %gray-5;
  @extend %font-16;
  @extend %font-normal;
}
.box-frame .bd-space {
  padding-bottom: 43px;
}

.box-frame .marb10 {
  margin-bottom: 10px;
}
//********************** 绑定洋蔥账号  end **********************
//********************** 放弃绑定  begin **********************
.middle-box {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9000;
  margin-left: -270px;
  margin-top: -78px;
  padding: 35px 35px 0;
  width: 540px;
  min-height: 156px;
  @extend %white-board;
  //内容
  .dialog-body {
    p {
      @extend %font-16;
    }
  }
  //ft btn
  .dialog-ft {
    padding-top: 22px;
    padding-bottom: 16px;
    text-align: right;
    button {
      margin: 0;
      @extend %btn-size-16;
    }
  }
}

//********************** 放弃绑定  end **********************

//********************** width小于等于800px时 单页： 找密  begin **********************
@media all and (max-width: 800px) {
  .box-frame .error-text {
    background-color: transparent;
    position: static;
    left: auto;
    top: auto;
    right: 320px;
    color: $red-5 !important;
    &::before {
      content: '';
    }
  }
  .clouds-box {
    display: none;
  }
  .mobile-signup {
    position: static;
    margin: 0 20px 20px;
    width: auto;
    input {
      width: 100%;
    }
    .label-space {
      margin-left: auto;
    }
    .radio-box {
      strong {
        font-size: 17px !important;
      }
    }
  }
  .mobile-signup-logo {
    display: block;
    height: 100px;
    margin: 10px auto;
  }
  #signupBtn {
    width: 80%;
  }
}

//********************** width小于等于800px时 单页： 找密  end **********************

//**********************未登录：学生首页 begin**************
// 首页-未登录 防止横向出滚动条
html {
  overflow-x: hidden;
}
//body {
//  padding-bottom: 0;
//}
//公共header
.header-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3000;
  width: 100%;
  height: 60px;
  @extend %white-board-bottom;
}
// header
.logo-div {
  @extend %pull-left;
  @extend %pointer;
  margin: 18px 40px 0 2px;
  width: 140px;
}
.logo {
  display: block;
  width: 96px;
  @extend %pull-left;
  @extend %pointer;
  h1 {
    display: none;
  }
}
// 学生、教师 開关
.switch-box {
  position: relative;
  margin-top: 17px;
  width: 140px;
  height: 26px;
  border: 1px solid $gray-2;
  border-radius: $font-20;
  @extend %pull-left;
  span {
    position: relative;
    z-index: 3;
    display: block;
    width: 50%;
    height: 26px;
    line-height: 26px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
    @extend %pull-left;
    @extend %gray-6;
    &.current {
      @extend %white;
    }
  }
  i {
    position: absolute;
    // left: -1px;
    top: -1px;
    z-index: 1;
    display: block;
    width: 50%;
    height: 26px;
    border-radius: $font-20;
    @extend %bg-blue-5;
  }
}
// 登录、注册 右上角
.nav-login {
  margin-top: 22px;
  @extend %pull-right;
  span {
    @extend %pull-left;
    @extend %gray-5;
    @extend %pointer;
    &:hover {
      @extend %blue-5;
    }
  }
  i {
    position: relative;
    margin: 0 12px;
    width: 1px;
    height: 14px;
    @extend %overflow;
    @extend %pull-left;
    @extend %bg-gray-5;
  }
}
// 几屏公共样式
.screen-box {
  position: relative;
  width: 100%;
  height: 720px;
  text-align: center;
  @extend %clear-fix;
}
// 大标题
.headline-yc {
  margin-top: 104px;
  margin-bottom: 5px;
  @extend %gray-7;
  @extend %font-34;
  .green {
    @extend %green-5;
  }
  .blue {
    @extend %blue-5;
  }
}
// 副标题
.subhead-yc {
  @extend %font-16;
  @extend %font-normal;
  a {
    @extend %blue-5;
  }
}
// btn盒子
.btn-yc {
  font-size: 0;
  padding-top: 21px;
  width: 714px;
  @extend %center;
  @extend %btn-size-16;
  @extend %clear-fix;
  button {
    @extend %btn-size-16;
  }
}
// 按钮列表
.btn-list {
  position: relative;
  padding: 0 10px;
  @extend %pull-left;
  .layer-hint.layer-4,
  .layer-hint.layer-3 {
    padding-top: 30px;
    top: 50px;
    height: 250px;
    .layer-hint-angle {
      top: -4px;
    }
  }
  .layer-hint.layer-3 {
    padding-top: 18px;
    margin-left: -150px;
    width: 300px;
  }
}
// 第1屏
.screen-1 {
  margin-top: -30px;
  height: 700px;
  background: linear-gradient(to bottom, $gray-1 0, #f0f0f0 100%);
  h2 {
    margin-top: 76px;
  }
  p {
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
  }
}
//第一屏 内容置于上层
.yc-pos {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 90;
}
// 第一屏 图
.student-1-1 {
  top: -37px;
  margin-left: -720px;
  width: 1440px;
  height: 700px;
}
.student-1-2 {
  margin-left: -720px;
  width: 1440px;
  height: 700px;
}
.student-1-3 {
  margin-left: -720px;
  width: 1440px;
  height: 700px;
}
.student-1-4 {
  top: 280px;
  bottom: auto;
  margin-left: -330px;
  width: 320px;
  height: 80px;
  img {
    width: 320px;
    height: 80px;
    @extend %block;
  }
}
// 学生-第1屏动画:进入
.come {
  .student-1-1 {
    animation: translate-1 0.8s cubic-bezier(0, 0, 0.1, 1) 1 forwards;
  }
  .student-1-2 {
    animation: translate-2 0.8s cubic-bezier(0, 0, 0.1, 1) 1 0.45s forwards;
  }
  .student-1-3 {
    animation: translate-2 0.8s cubic-bezier(0, 0, 0.1, 1) 1 0.9s forwards;
  }
  .student-1-4 {
    animation: translate-3 0.8s linear 1 1.4s forwards;
  }
}

// 第2屏
.screen-2,
.screen-3 {
  height: 780px;
  //@extend %bg-white;
}

.screen-2 {
  .btn-yc {
    padding: 0;
    button {
      margin-top: 171px;
    }
  }
}
.screen-3 {
  border-top: 2px dashed $gray-2;
  // 右侧内容
  .task-content-right {
    padding: 23px 40px 0 0;
    width: 320px;
    text-align: left;
    @extend %pull-right;
    // 提交按钮盒子
    .btn-yc {
      margin-top: 30px;
      padding: 0;
      width: 100%;
      text-align: right;
    }
    .btn-bg-green {
      margin: 0;
    }
  }
  // 选择题答案
  .task-option {
    position: relative;
    margin-bottom: 20px;
    padding-left: 60px;
    width: 280px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    border: 1px solid $gray-1;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    transition: box-shadow 0.2s ease-in-out;
    //@extend %bg-white;
    @extend %font-bold;
    @extend %pointer;
    @extend %gray-4;
    &:hover {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    }
    &.current {
      @extend %white;
      @extend %bg-gray-5;
      .task-num {
        border-color: $white;
        @extend %white;
      }
    }
  }
  .task-option .svgsignup,
  .task-answer .svgsignup {
    display: none;
    position: absolute;
    left: -15px;
    top: -2px;
    width: 32px;
    height: 32px;
    &.show {
      @extend %show;
    }
  }
  // 应用题答案
  .task-answer {
    position: relative;
    margin-bottom: 20px;
    min-height: 150px;
    border-radius: 10px;
    //@extend %bg-white;
    @extend %font-bold;
    @extend %pointer;
  }
  .task-num {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    display: block;
    width: 47px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-right: 1px solid $gray-1;
    @extend %font-16;
    @extend %gray-6;
    // @extend %font-bold;
  }
}

// 学生-第2、3屏动画:进入  ,.student-3-1
.come {
  .student-2-1 {
    animation: stu-scale-1 0.5s ease-out 1 forwards;
  }
  .student-2-3 {
    animation: tada 1s ease-out 1 0.5s forwards;
  }
}

// 动画
@keyframes stu-scale-1 {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(0.9);
    opacity: 1;
  }
}

// 学生-第4屏动画:进入
// .come {
// 	.student-0-1 {
// 		animation: fadeInUp .8s ease-out 1 forwards;
// 	}
// 	.student-0-2 {
// 		animation: fadeInDown .8s ease-out 1 forwards;
// 	}
// 	.student-0-3 {
// 		animation: fadeInUp .8s ease-out 1 forwards;
// 	}
// 	.student-0-4 {
// 		animation: fadeInDown .8s ease-out 1 forwards;
// 	}
// 	.student-0-5 {
// 		animation: fadeInUp .8s ease-out 1 forwards;
// 	}
// }

// 第2、3屏 图
.student-2-1,
.student-3-1 {
  position: absolute;
  left: 50%;
  margin-left: -564px;
  top: 123px;
  width: 1128px;
  height: 500px;
  opacity: 0;
  transform: scale(0.8);
}
.student-3-1 {
  margin-left: -579px;
  width: 1159px;
  height: 511px;
  opacity: 1;
  transform: scale(1);
}
.student-2-2,
.student-3-2 {
  position: absolute;
  left: 50%;
  top: 223px;
  z-index: 2;
  margin-left: -380px;
  width: 760px;
  height: 486px;
}
// 白盒子
.yc-white-box {
  position: absolute;
  top: 283px;
  left: 50%;
  margin-left: -360px;
  z-index: 3;
  width: 720px;
  height: 386px;
  @extend %bg-white;
  @extend %radius-5;
}
.student-3-3 {
  display: block;
  width: 720px;
  height: 74px;
}
.student-3-4 {
  display: block;
  width: 160px;
  height: 132px;
  @extend %center;
}
// 问题 左盒子
.problem-left {
  padding: 20px 10px 0 40px;
  width: 50%;
  @extend %pull-left;
  @extend %clear-fix;
  p {
    margin-bottom: 40px;
    text-align: left;
    line-height: 26px;
    width: 280px;
    @extend %font-16;
    @extend %font-bold;
  }
}

// 第4屏
.screen-4,
.teacher-6 {
  @extend %bg-blue-5;
  h2 {
    @extend %white;
  }
  h3 {
    @extend %white;
  }
}

// // 动画
@keyframes translate-1 {
  0% {
    transform: translateY(-26px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes translate-2 {
  0% {
    transform: translateY(34px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes translate-3 {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  33% {
    transform: translateY(0);
    opacity: 1;
  }
  54% {
    transform: scale(1);
  }
  66% {
    transform: scale(1.07);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

// footer
.footer-box {
  height: 60px;
  width: 100%;
  @extend %bg-blue-6;
}
.footer {
  text-align: center;
  width: 1306px;
  margin-left: auto;
  margin-right: auto;
}
.footer-ul {
  li {
    position: relative;
    display: inline;
    margin: 0 10px;
    line-height: 60px;
    color: rgba(229, 248, 255, 0.8);
    @extend %font-12;
    em {
      @extend %pointer;
      @extend %overflow;
      &:hover {
        color: rgba(229, 248, 255, 1);
        .icon-png {
          display: none;
        }
        .icon-white {
          display: inline-block;
        }
        .student-sina-white {
          left: 0px;
        }
        .student-sina-icon2 {
          display: none;
        }
        .student-sina-white2 {
          display: block;
        }
        .student-qq-icon2 {
          display: none;
        }
        .student-qq-white2 {
          display: block;
        }
        .student-WeChat-icon2 {
          display: none;
        }
        .student-WeChat-white2 {
          display: block;
        }
      }
    }
    .lineHeight {
      line-height: 18px;
    }
  }
  .blue4 {
    @extend %blue-4;
  }
  //png图标
  .icon-ft {
    position: relative;
    padding: 0 5px;
  }
  .icon-png,
  .icon-white {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 16px;
    height: 16px;
  }
  .icon-white {
    display: none;
  }
}
// 层
// 按钮上面 白色 提示层
.layer-hint {
  display: none;
  position: absolute;
  bottom: 27px;
  left: 50%;
  z-index: 300;
  margin-left: -105px;
  padding: 14px;
  width: 210px;
  line-height: 18px;
  text-align: left;
  @extend %white-board-2;
  @extend %gray-5;
  @extend %font-14;
  .layer-hint-angle {
    position: absolute;
    bottom: -4px;
    left: 50%;
    margin-left: -4px;
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    @extend %white-board-3;
  }
  // 友情链接
  &.layer-1 {
    margin-left: -75px;
    width: 150px;
    line-height: 30px;
    button {
      margin: 4px 0;
      @extend %btn-size-14;
    }
  }
  // 联系我们
  &.layer-2 {
    padding: 30px 10px 14px 30px;
    h4 {
      @extend %font-normal;
      @extend %gray-3;
    }
    p {
      padding-top: 4px;
      padding-bottom: 12px;
      @extend %blue-5;
    }
    a,
    a:hover,
    a:active,
    a:visited {
      @extend %blue-5;
    }
  }
  // 关于
  &.layer-3 {
    margin-left: -180px;
    padding: 16px 30px 23px 30px;
    width: 360px;
    p {
      padding-top: 10px;
      line-height: 24px;
      text-indent: 30px;
      @extend %font-14;
    }
  }
  // 扫码关注
  &.layer-4 {
    padding: 22px 0 26px;
    width: 180px;
    margin-left: -90px;
    text-align: center;
    p {
      padding-top: 4px;
      // @extend %font-normal;
    }
  }
  .qrcode-box {
    margin-top: 20px;
    width: 120px;
    height: 120px;
    @extend %center;
    span {
      width: 120px;
      height: 120px;
      @extend %block;
    }
  }
  &.layer-5 {
    margin-left: -90px;
    z-index: 10;
    width: 180px;
    padding: 30px 20px 31px 30px;
    h4 {
      @extend %gray-7;
    }
    h5 {
      padding-top: 16px;
      padding-bottom: 6px;
      font-weight: normal;
    }

    p {
      padding-top: 5px;
      padding-bottom: 10px;
      @extend %gray-5;
    }
    .space-1 {
      padding-bottom: 0;
    }

    button {
      margin: 0;
      line-height: 18px !important;
      @extend %btn-size-12;
      @extend %btn-bg-blue;
    }
    img {
      margin-top: 10px;
      width: 120px;
      height: 120px;
    }
    .student-qqimg {
      position: relative;
      top: 0;
      margin: 0;
      margin-right: 6px;
      width: 18px;
      height: 18px;
      @extend %radius-50p;
      @extend %pull-left;
    }
  }
}
// 设备
.equipment {
  position: relative;
  margin-top: 63px;
  width: 950px;
  height: 300px;
  @extend %center;
  p {
    position: absolute;
    bottom: 0;
  }
  // 图
  .student-0-1 {
    left: 0;
    z-index: 5;
    width: 81px;
    height: 164px;
  }
  .student-0-2 {
    left: 98px;
    z-index: 4;
    width: 83px;
    height: 164px;
  }
  .student-0-3 {
    left: 142px;
    z-index: 3;
    width: 276px;
    height: 190px;
  }
  .student-0-4 {
    right: 403px;
    z-index: 2;
    width: 179px;
    height: 252px;
  }
  .student-0-5 {
    right: 0;
    z-index: 1;
    width: 492px;
    height: 290px;
  }
}
//**********************未登录：学生首页 end**************

.video-mask {
  position: fixed;
  height: 100%;
  left: 0px;
  display: none;
  z-index: 3000;
  top: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.guide-video {
  width: 960px;
  height: 540px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -480px;
  margin-top: -270px;
  z-index: 10000;
}

.windows-modified {
  text-align: center;
  height: 230px !important;
  p {
    text-align: left;
  }
}

//************************ 首页 登录、注册 begin ************************
.login-enter {
  position: absolute;
  left: 50%;
  top: 100px;
  z-index: 90;
  margin-left: 100px;
  width: 440px;
  @extend %clear-fix;
}
// 左侧二维码
.le-left {
  position: absolute;
  right: 187px;
  margin-top: 40px;
  width: 184px;
  border-radius: 10px 0 0 10px;
  box-shadow: $box-shadow;
  background-color: $white;
  transition: right 400ms cubic-bezier(0.19, 1, 0.22, 1);
  @extend %clear-fix;
  @extend %pointer;
  .img-2 {
    margin: 16px 0 0 0;
    width: 114px;
    height: 114px;
    @extend %block;
    @extend %pull-left;
  }
}
.le-ewm-sma {
  width: 50px;
  padding-bottom: 16px;
  @extend %pull-left;
  @extend %overflow;
  .img-1 {
    margin: 16px auto 10px;
    width: 20px;
    height: 20px;
    @extend %block;
  }
  span {
    line-height: 14px;
    text-align: center;
    @extend %block;
    @extend %font-bold;
  }
}
//交替
.turn.le-left {
  right: 318px;
}

// 右侧登录、注册
.le-right {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;
  width: 320px;
  min-height: 300px;
  @extend %clear-fix;
}
//背影、阴影
.le-bg {
  @extend %white-board;
  @extend %clear-fix;
}

// 卡片
.le-tab {
  position: relative;
  z-index: 10;
  margin: 16px 30px 0;
  height: 40px;
  text-align: center;
  li {
    width: 50%;
    line-height: 40px;
    @extend %font-20;
    @extend %font-bold;
    @extend %gray-3;
    @extend %pull-left;
    @extend %pointer;
    &.current {
      @extend %blue-5;
    }
  }
  .line-blue {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    border-bottom: 2px solid $blue-5;
    transition: left 600ms cubic-bezier(0.19, 1, 0.22, 1);
    @extend %block;
  }
  &[data-active-index='1'] {
    .line-blue {
      left: 130px;
    }
  }
  &[data-active-index='1'].alone {
    .line-blue {
      left: 151px;
    }
  }
}

.verificationLogin {
  input[type='phoneVerificationCode'] {
    padding-left: 19px;
    width: 127px !important;
    height: 36px;
    display: inline-block;
    line-height: 36px;
    border: 2px solid $gray-2;
    transition: border 0.1s;
    @extend %font-16;
    @extend %font-bold;
    @extend %radius-18;
    @extend %gray-5;
  }
  input[type='phoneVerificationCode']:focus {
    border: 2px solid $gray-4;
  }
  button {
    margin-left: 12px;
    display: inline-block;
    width: 119px !important;
    font-size: 14px !important;
  }
}

// 卡片 盒子
.le-tab-box {
  position: relative;
  top: -1px;
  border-top: 1px solid $gray-1;
  margin-left: 29px;
  margin-right: 29px;
  @extend %clear-fix;
  .box-frame {
    display: none;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none !important;
    position: static;
    top: 0;
    left: 0;
    margin: 0;
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding-bottom: 26px;
    input {
      width: 100%;
    }
    button {
      width: 100%;
    }
    .pass-p {
      padding-top: 6px;
      padding-bottom: 27px;
      @extend %font-bold;
    }
    .error-text {
      left: auto;
      right: 274px;
      &::before {
        left: auto;
        transform: rotate(180deg);
        right: -7px;
      }
    }
  }
  .show {
    display: block;
  }
  .btn-mart {
    margin-top: 20px;
    .social_icon_QQ {
      margin-left: 36px;
    }
  }
  .radio-box {
    position: relative;
    padding-top: 7px;
    .error-text {
      top: 5px;
      margin-right: 0;
    }
  }
  .checkbox-box {
    padding-bottom: 27px;
  }
}
.input-validateCode {
  float: left;
  width: 50% !important;
  margin-bottom: 20px;
}
.button-validateCode {
  float: left;
  width: 40% !important;
  margin-left: 25px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.height-row {
  height: 40px;
}
.margin-i {
  margin-top: 17px;
}
//老师喊你来洋蔥學園
.form-teacher {
  position: relative;
  text-align: right;
  h3 {
    padding: 30px 29px 8px 0;
    line-height: 20px;
    @extend %font-20;
    @extend %gray-7;
  }
  h4 {
    padding: 0 30px 18px 0;
    line-height: 14px;
    @extend %font-normal;
  }
  .blue {
    @extend %blue-5;
  }
  button {
    margin: 0 30px 26px 0;
    @extend %btn-size-16;
  }
  .form-teac {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 120px;
    height: 150px;
    @extend %block;
  }
}

//************************ 首页 登录、注册 end ************************
//************************老师给了你 begin ************************
.alone {
  margin: 0;
}
.tab-cont {
  display: none;
  padding-top: 30px;
  padding-bottom: 20px;
  .btn-cont {
    margin-top: 30px;
  }
  &.show {
    display: block;
  }
}
.cont-space {
  padding-top: 20px;
  line-height: 1;
}
.cont-space-2 {
  line-height: 1;
}
.border-top-box {
  position: relative;
  top: -1px;
  border-top: 1px solid $gray-1;
}
//************************老师给了你 end ************************
//
.headline-yc.z-dbt {
  position: relative;
  left: -412px;
  padding-bottom: 4px;
  padding-top: 76px;
  font-size: 44px;
}
.subhead-yc.z-xbt {
  position: relative;
  left: -442px;
  line-height: 32px;
  @extend %font-20;
  span {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: 200px;
    @extend %block;
  }
}
.student-3-33 {
  top: 150px !important;
}
.dialog-content-resetPassword {
  .row-box {
    width: 354px;
  }
  .row-box .svgsignup {
    top: 41px;
  }
  .error-text {
    left: 367px;
    top: 39px;
  }
  .dialog-container {
    //width: 600px;
  }
  .btn-color-blue {
    float: right;
    width: 127px;
  }
  .tip-wrape {
    top: -117px;
    left: 55%;
  }
}
.signup-box {
  input[type='text'],
  input[type='password'] {
    font-size: 14px;
    padding-left: 13px;
  }
}
