.model {
  width: 1172px;
  height: 741px;
  background-image: url('../../../../assets/deliberate-practice/modal_sign_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .close {
    width: 108px;
    height: 55px;
    background-image: url('../../../../assets/deliberate-practice/close.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: 1px;
    top: 0;
    cursor: pointer;
  }
  .title {
    display: block;
    width: 299px;
    height: 35px;
    margin: 38px auto 45px;
  }
  .intro_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      background-image: url('../../../../assets/deliberate-practice/modal_sign_left_k.png');
    }
    .right {
      overflow: hidden;
      background-image: url('../../../../assets/deliberate-practice/modal_sign_right_k.png');
    }
    .item {
      width: 527px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding: 25px 25px 1px 25px;
      height: 455px;
      min-height: 455px;
      overflow: auto;
      &:first-child {
        margin-right: 37px;
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
      .item_block {
        width: 476px;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
        img {
          width: 103px;
          height: 33px;
          display: block;
          margin-bottom: 16px;
        }
        .group {
          width: 59px;
        }
        .text_intro {
          font-size: 16px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          line-height: 24px;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .bouns {
          width: 476px;
          height: 276px;
          border-radius: 12px;
          overflow: hidden;
          margin: 24px 0;
        }
      }
    }
  }
  .show {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(69, 81, 112, 0.3);
    box-shadow: 0px 0px 40px 0px rgba(28, 37, 63, 0.6);
    margin-top: 19px;
    padding: 12px 24px;
    .num_block {
      font-size: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      .num {
        color: #ffb400;
        font-family: DINAlternate-Bold;
      }
    }
    .button_block {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      width: 166px;
      height: 44px;
      background: rgba(65, 90, 255, 1);
      box-shadow: inset 0px -3px 0px 0px rgba(38, 63, 228, 1);
      border-radius: 22px;
      border: 2px solid rgba(28, 37, 62, 1);
      img {
        width: 28px;
        height: 28px;
        display: block;
        margin-right: 10px;
      }
    }
  }
}
.hack {
  overflow: auto;
  height: 422px;
  min-height: 422px;
  margin-bottom: 3px;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-height: 700px) {
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .model {
    transform: scale(0.5);
  }
}
