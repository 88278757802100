.hookTB {
  padding-top: 11.2vh;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 40, 75, 1);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .img {
    width: 200px;
    height: 200px;
  }
  .explain {
    margin-top: 12px;
    font-size: 22px;
    font-family: NotoSansTC-Bold;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
  }
}
.practice_h1 {
  font-size: 24px;
  font-weight: bold;
  color: rgba(45, 60, 99, 1);
}
.play_block {
  margin-left: 30px;
  display: inline-block;
  width: 195px;
  height: 34px;
  cursor: pointer;
  margin-top: 21px;
  img {
    display: block;
    width: 195px;
    height: 34px;
  }
}
.progress_block {
  top: 37px !important;
}
.block_hight64 {
  height: 64px !important;
}
.good {
  position: absolute;
  right: 0;
  top: 86px;
  z-index: 888;
  overflow: hidden;
  width: 115px;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  transform: translateX(115px);
  opacity: 0;
  transition: transform 0.3s linear, opacity 0.1s linear;
  img {
    display: block;
    width: 150px;
    height: 120px;
  }
}
.enter_active {
  opacity: 1;
  transform: translateX(0);
}
.enter_leave {
  opacity: 0;
  transform: translateX(115px);
}
.error_black {
  position: absolute;
  left: 30px;
  bottom: 15px;
  .error_block {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: NotoSansTC-Bold;
    font-weight: bold;
    color: rgba(148, 161, 184, 1);
  }
  .error_items {
    position: absolute;
    bottom: -4px;
    left: 0;
    padding: 15px 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 0px rgba(0, 21, 65, 0.1);
    border-radius: 10px;
    width: 110px;
    z-index: 5;
    .error {
      width: 110px;
      height: 40px;
      font-size: 14px;
      font-family: NotoSansTC-Medium;
      font-weight: 500;
      color: rgba(148, 161, 184, 1);
      text-align: center;
      line-height: 40px;
    }
  }
}
.quit_block {
  position: absolute;
  width: 56px;
  height: 99px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  border-radius: 0 12px 12px 0;
  right: -56px;
  top: 68px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 13px 0;
  img {
    display: block;
    width: 30px;
    height: 30px;
  }
  .quit_text {
    writing-mode: vertical-rl;
    font-size: 16px;
    color: rgba(183, 192, 207, 1);
    letter-spacing: 5px;
    margin-top: 3px;
  }
}
.full_block {
  width: 56px;
  height: 156px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  border-radius: 0 12px 12px 0;
  position: absolute;
  right: -56px;
  top: 45px;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 13px 0;
  z-index: 999;
  img {
    display: block;
    width: 30px;
    height: 30px;
  }
  .full_text {
    writing-mode: vertical-rl;
    font-size: 16px;
    color: rgba(183, 192, 207, 1);
    letter-spacing: 5px;
    margin-top: 3px;
  }
}
