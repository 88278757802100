$column: column;
$row: row;
.#{$column}-tab {
  position: relative;
}
.#{$column}-hack {
  position: absolute;
  left: 0;
  top: 0;
}
.#{$column}-tab,
.#{$column}-hack {
  .#{$column}--hack_item {
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    line-height: 42px;
    width: 42px;
    height: 104px;
    writing-mode: vertical-rl;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 5px 0;
  }
  .#{$column}--tab_item {
    transform: skewY(165deg);
  }
  .#{$column}--tab_item2 {
    transform: skewY(165deg);
    width: 42px;
    height: 104px;
    background: linear-gradient(
      180deg,
      rgba(35, 46, 77, 0.9) 0%,
      rgba(25, 34, 57, 0.96) 100%
    );
    border: 2px solid rgba(82, 134, 176, 1);
    transition: all 0.3s ease;
    &:not(:first-child) {
      border-top: none;
    }
  }
  .#{$column}--tab_item1 {
    background: linear-gradient(
      180deg,
      rgba(29, 180, 255, 0.7) 0%,
      rgba(29, 180, 255, 1) 100%
    );
  }
}
.#{$row}-tab {
  position: relative;
  display: flex;
}
.#{$row}-hack {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
}
.#{$row}-tab,
.#{$row}-hack {
  .#{$row}--tab_item2 {
    transform: skewX(165deg);
    width: 122px;
    height: 48px;
    background: rgba(69, 81, 112, 1);
    border: 2px solid rgba(82, 134, 176, 1);
    transition: all 0.3s ease;
    &:not(:first-child) {
      border-left: none;
    }
  }
  .#{$row}--tab_item1 {
    background: linear-gradient(
      180deg,
      rgba(29, 180, 255, 0.7) 0%,
      rgba(29, 180, 255, 1) 100%
    );
  }
  .#{$row}--hack_item {
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    line-height: 48px;
    width: 122px;
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 5px;
  }
}
