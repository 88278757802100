@charset "utf-8";
// -- css reset begin --
*,
:after,
:before {
  box-sizing: border-box;
  outline: none;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
  line-height: 1;
}
// 标题自定义
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: bold;
}
h3 {
  color: #888888;
}
address,
caption,
cite,
code,
dfn,
em,
i,
th,
var {
  font-style: normal;
  // font-weight: 500;
}
// 去掉li前符号，li 会继承
ol,
ul {
  list-style: none;
}
// 默认不显示下划线
ins,
a,
a:hover {
  text-decoration: none;
}
// 去掉默认边框
fieldset,
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  box-sizing: content-box;
  height: 0;
}

// -- css reset end --
// 重设 HTML5 标签, IE 需要在 js 中 createElement(TAG)
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  padding: 0;
  margin: 0;
}
// HTML5 媒体文件与 img 保持一致
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
// 表单元素不继承父级 font
body,
button,
input,
select,
textarea {
  //font-family: $font-family-base !important;
  font-size: $font-14;
}
button,
input,
select,
textarea {
  font-size: 100%;
  color: inherit;
  font: inherit;
}
textarea {
  resize: none !important;
}
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  appearance: button;
  //-webkit-appearance: button;
}
input[type='password'] {
  display: inline-block;
  letter-spacing: 4px;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
del {
  text-decoration: line-through;
}
mark {
  background: $gold-4;
}
// 统一上标和下标
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
// 去掉 Table cell 单元格间隙、单元格边距
table {
  border-collapse: collapse;
  border-spacing: 0;
}
// IE bug fixed: th 不继承 text-align
th {
  text-align: inherit;
}
// ie6 7 8(q) bug 为行内状态
iframe {
  display: block;
}

// 防止用户自定义背景颜色 、webkit 文字平滑
body {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  //padding-top: 90px;
  //padding-bottom: 160px !important;
  //color: $gray-5 !important;
  //background: url('./assets/detele/bg.svg'), url('./assets/bg-bottom.png');
  //background-color: $gray-1;
  //background-repeat: no-repeat;
  //background-position: center bottom;
  //background-image: linear-gradient(-180deg, #002FEF 3%, #0085FF 91%);
  //background-attachment: fixed;
  //transform: translateZ(0);
}
html {
  overflow-x: auto !important;
  overflow-y: auto !important;
}
html,
body {
  height: 100%;
}

// 自动换行、英文强制换行
// td,th,div {
//   word-break: break-all;
//   word-wrap: break-word
// }
//因angularjs-material的tooltip滚动时位置变问题，加的这个样式
#onion-content {
  position: absolute;
  padding-top: 110px;
  padding-bottom: 160px;
  width: 100%;
  @extend %clear-fix;
}
//兼容IE下底端也有间隙160px
#onion-content-box {
  position: relative;
  height: 100%;
  overflow-y: auto;
  @extend %clear-fix;
}

.katex-higher {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  margin-bottom: 5px;
}
.katex-flat {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
