// **************** 大、小标题 begin ***************
// 大标题
.headline-1 {
  padding: 35px 0 10px 35px;
  @extend %gray-7;
  @extend %font-26;
}
// 小标题1
.subhead,
.subhead-1 {
  padding: 0 0 35px 35px;
  @extend %font-16;
}
.subhead {
  @extend %font-normal;
}
// 小标题-1
.subhead-1 {
  padding: 0 0 30px 35px;
  @extend %font-normal;
  i {
    position: relative;
    top: 2px;
    display: inline-block;
    margin: 0 12px;
    width: 1px;
    height: 16px;
    background-color: #f3f3f3;
  }
}
// **************** 大、小标题 end ***************
// **************** 班级数据、班级管理 右侧菜单 begin ***************
.class-list-box {
  height: 300px;
  overflow-y: auto;
}
.class-list-outer {
  .ant-tabs-bar {
    margin: 0 30px !important;
  }
}
.user-nav {
  padding-top: 9px;
  margin-bottom: 18px;
  width: 100%;
  @extend %clear-fix;
  li {
    width: 100%;
    height: 48px;
    @extend %pull-left;
    .button {
      position: relative;
      margin: 0;
      padding: 0 0 0 30px;
      border-radius: 0;
      width: 100%;
      height: 48px !important;
      line-height: 48px !important;
      text-align: left;
      @extend %btn-size-16;
      @extend %btn-color-gray;
      img {
        position: absolute;
        top: 50%;
        left: 264px;
        transform: translate(0, -50%);
      }
    }
  }
  .current {
    .button {
      margin: 0;
      padding: 0 0 0 30px;
      border-radius: 0;
      @extend %btn-color-gray;
      color: $blue-5 !important;
    }
  }
  .icon {
    margin: 13px 15px 0 30px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 23px;
    @extend %pull-left;
  }
  .icon-binding {
    position: relative;
    top: 2px;
  }
}
.btn-box-wrap {
  padding-top: 12px;
  margin-bottom: 30px;
  text-align: center;
}
// **************** 班级数据、班级管理 右侧菜单 end ***************
.wid-box {
  margin: 10px 30px 30px;
  @extend %clear-fix;
}
.wid-h2 {
  font-size: 24px;
  margin: 20px 30px;
  border-left: 4px solid $blue-5;
  padding-left: 10px;
  @extend %gray-7;
  .red {
    @extend %red-5;
    @extend %font-14;
  }
}
.h3-wid {
  padding: 10px 24px;
  @extend %font-20;
  @extend %gray-7;
}
.wid-html {
  p {
    line-height: 24px;
    border-bottom: 1px solid $gray-2;
    @extend %clear-fix;
    i {
      width: 100px;
      @extend %blue-5;
      @extend %block;
      @extend %pull-left;
    }
    em {
      width: 400px;
      padding-right: 20px;
      @extend %block;
      @extend %pull-left;
    }
    span {
      width: 140px;
      @extend %block;
      @extend %pull-left;
    }
  }
  .green {
    @extend %green-5;
  }
  .red {
    @extend %red-5;
  }
}
.wid-icon {
  font-size: 32px;
}
.wid-white {
  padding: 30px;
  @extend %bg-white;
}

// 条纹按钮
%stripe-blue,
.stripe-blue {
  background-image: repeating-linear-gradient(
    135deg,
    $blue-5,
    $blue-5 11px,
    #1c9ef0 11px,
    #1c9ef0 22px
  ) !important;
}
%stripe-green,
.stripe-green {
  background-image: repeating-linear-gradient(
    135deg,
    $green-5,
    $green-5 11px,
    #7bc600 11px,
    #7bc600 22px
  ) !important;
}
%stripe-red,
.stripe-red {
  background-image: repeating-linear-gradient(
    135deg,
    $red-5,
    $red-5 11px,
    #ed5523 11px,
    #ed5523 22px
  ) !important;
}
$gold-line-1: #fbcf00;
$gold-line-2: #ffc800;
%stripe-gold,
.stripe-gold {
  background-image: repeating-linear-gradient(
    135deg,
    $gold-line-1,
    $gold-line-1 11px,
    $gold-line-2 11px,
    $gold-line-2 22px
  ) !important;
}

// 主题页按钮
.btn-210-34 {
  width: 210px;
  height: 30px;
  .svgchapter.vip {
    position: relative;
    top: -2px;
    margin-right: 4px;
  }
}

// 商城btn-固定宽高
.rule-btn {
  width: 150px;
  height: 30px;
  .svglist {
    position: relative;
    top: -2px;
    margin-right: 5px;
  }
  span {
    position: relative;
    top: -1px;
  }
}

//***************** 2s后提示成功 begin ***********
.pos-wrape {
  position: relative;
  // @extend %inline-block;
}
.tip-wrape {
  position: absolute;
  z-index: 10;
  top: -50px;
  left: 14px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  color: #fff;
  content: attr(data-balloon);
  font-size: 14px;
  font-weight: bold;
  padding: 11px 14px 10px;
  white-space: nowrap;
  margin-bottom: 11px;
  @extend %inline-block;
}
//***************** 2s后提示成功 end ***********

// **************input单选框 点击有光圈效果 begin ******************
.theme-radio {
  font-size: 0;
  @extend %pointer;
  @extend %inline-block;
  input {
    display: none;
  }
  .sign {
    position: relative;
    top: 2px;
    margin-right: 10px;
    display: inline-block;
    width: 18px;
    height: 18px;
    @extend %radius-50p;
    i {
      position: absolute;
      left: 1px;
      top: 1px;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      opacity: 0;
      transform: scale(1, 1);
      transform-origin: center center;
      opacity: 1;
      @extend %bg-gray-1;
    }
    em {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      border: 2px solid $gray-3;
      transition: all 0.2s linear;
      @extend %radius-50p;
      @extend %bg-white;
    }
  }
  strong {
    @extend %font-18;
  }
  //点击或选中加类current 时
  &:active,
  &.current {
    .sign {
      em {
        border: 5px solid $blue-5;
        @extend %white;
      }
      i {
        animation: highlight 0.2s linear 1;
      }
    }
  }
}
//16号字
.size-16 {
  .theme-radio {
    position: relative;
    top: 7px;
    .sign {
      margin-right: 8px;
    }
    strong {
      position: relative;
      top: -2px;
      font-size: 16px !important;
    }
  }
  .theme-radio:nth-child(2n) {
    margin-right: 15px;
  }
}
// 高光光圈动画
@keyframes highlight {
  0% {
    transform: scale(1, 1);
    transform-origin: center center;
    opacity: 1;
  }
  100% {
    transform: scale(4, 4);
    transform-origin: center center;
    opacity: 0;
  }
}
// **************input单选框 点击有光圈效果 end ******************

// **************input复选框 点击有光圈效果 begin ******************
.theme-checkbox {
  font-size: 0;
  @extend %pointer;
  input {
    display: none;
  }
  .sign {
    position: relative;
    top: 4px;
    margin-right: 10px;
    display: inline-block;
    width: 18px;
    height: 18px;
    @extend %radius-50p;
    i {
      position: absolute;
      left: 1px;
      top: 1px;
      display: inline-block;
      width: 16px;
      height: 16px;
      opacity: 0;
      @extend %bg-gray-1;
      @extend %radius-50p;
    }
    em {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border: 2px solid $gray-3;
      transition: all 0.2s linear;
      @extend %bg-white;
    }
  }
  strong {
    @extend %gray-3;
    @extend %font-14;
  }
  //
  &:active,
  &.current {
    .sign {
      em {
        border: 0;
        @extend %bg-blue-5;
        &:after {
          border-color: #e5f8ff;
          border-width: 3px !important;
          box-sizing: border-box;
          transform: rotate(45deg);
          position: absolute;
          left: 6px;
          top: 2px;
          display: table;
          width: 6px;
          height: 12px;
          border-width: 2px;
          border-style: solid;
          border-top: 0;
          border-left: 0;
          content: '';
        }
      }
      i {
        animation: highlight 0.2s linear 1;
      }
    }
  }
}
// **************input复选框 点击有光圈效果 end ******************

// *******************input文字输入框及提示 begin*****************
// .row-box {
//   position: relative;
//   padding: 0 0 30px 209px;
//   min-height: 66px;
//   .error-text {
//     position: absolute;
//     left: 522px;
//     top: 3px;
//     white-space:nowrap;
//     padding: 0 13px;
//     height: 30px;
//     line-height: 30px;
//     text-align: left;
//     background-color: rgba(60,60,60,.8);
//     opacity: .9;
//     transform: translateX(-2px);
//     @extend %white;
//     @extend %radius-5;
//     &::before {
//       position: absolute;
//       left: -7px;
//       content: url(./assets/prompt.svg);
//     }
//     &.show {
//       animation: inShow .4s forwards;
//     }
//   }
//   //对错提示
//   .svgsignup {
//     position: absolute;
//     top: 6px;
//     left: 478px;
//     opacity: 0;
//     transform: scale(.85);
//     @extend %pointer;
//     &.show {
//       animation: inScale .1s forwards;
//     }
//   }
// }
// *******************input文字输入框及提示 end*****************
.td-copy-div {
  position: relative;
}
.tip-bottom {
  position: absolute;
  bottom: -30px;
  padding: 10px 10px 8px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  @extend %white;
  @extend %radius-10;
}
.tip-center {
  position: fixed;
  left: calc(50% - 40px);
  top: calc(50% - 10px);
  padding: 10px 10px 8px;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  @extend %white;
  @extend %radius-10;
}
//****************** 加载中 begin ****************
.loading {
  height: 40px;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 10px);
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 888;
}

.loading span {
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 10px;
  display: inline-block;
  transition: all 400ms ease;
  animation: wave 2.5s ease infinite;
  @extend %bg-blue-5;
}

.loading span:nth-child(2) {
  animation-delay: 100ms;
}

.loading span:nth-child(3) {
  animation-delay: 200ms;
}

.loading span:nth-child(4) {
  animation-delay: 300ms;
}

.loading span:nth-child(5) {
  animation-delay: 400ms;
}

@keyframes wave {
  0%,
  100% {
    transform: translate(0, 0);
    @extend %bg-blue-5;
  }
  40% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  10% {
    transform: translate(0, -20px);
    @extend %bg-blue-1;
  }
}
//****************** 加载中 end ****************

//****************** 下拉菜单 begin ****************
//下拉菜单 16号字
.select-wrap {
  position: relative;
  @extend %inline-block;
  @extend %pointer;
  .chosen {
    position: relative;
    top: 3px;
    height: 24px;
    line-height: 24px;
    transition: color 0.4s ease;
    // @extend %overflow;
    @extend %gray-4;
    @extend %block;
    @extend %font-16;
    &:hover {
      @extend %gray-5;
      .icon {
        @extend %gray-5;
      }
    }
    .icon {
      font-size: 24px;
      @extend %pull-right;
    }
  }
  //文字
  .chose-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    @extend %inline-block;
  }
  //头像图片
  .sel-img {
    position: relative;
    top: -9px;
    margin-right: 14px;
    width: 35px;
    height: 35px;
    @extend %block;
    @extend %pull-left;
    @extend %radius-50p;
  }
  //下拉菜单 14号字
  &.select-sma {
    .chosen {
      height: 20px;
      line-height: 18px;
      @extend %font-14;
    }
    button {
      margin: 0;
      padding-right: 10px;
      padding-left: 23px;
      width: 100%;
      border-radius: 0;
      text-align: left;
      @extend %btn-size-14;
      @extend %btn-color-gray;
    }
  }
  //下拉菜单宽度 2
  &.select-w-2 {
    .select-value {
      width: auto;
    }
  }
}
//默认下拉层
.select-value {
  position: absolute;
  left: 0;
  top: -10px;
  z-index: 3000;
  display: none;
  padding: 10px 0;
  opacity: 0;
  width: 112px;
  @extend %clear-fix;
  @extend %white-board;
  li {
    width: 100%;
    @extend %pull-left;
    &.current {
      button {
        margin: 0;
        @extend %btn-color-blue;
      }
    }
  }
  &.show {
    opacity: 1;
    transition: opacity linear 0.4s;
  }
  &.hide {
    opacity: 0;
    transition: opacity linear 0.4s;
  }
  button {
    margin: 0;
    padding-right: 23px;
    padding-left: 23px;
    width: 100%;
    border-radius: 0;
    text-align: left;
    white-space: nowrap;
    @extend %btn-size-16;
    @extend %btn-color-gray;
  }
}
//下拉菜单宽 142 教师端管理班级
.sel-w-142 {
  .select-value {
    left: auto;
    right: 0;
    width: 142px;
  }
}
//header 带图片的下拉菜单

.header-right {
  .chose-text {
    max-width: 91px;
    @extend %overflow;
  }
  .select-value {
    left: auto;
    right: 0;
    width: 103px;
  }
}
//****************** 下拉菜单 end ****************

//******************** 按钮 波纹效果  begin *******************
@keyframes circle {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }
  10% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(1.6);
  }
}

@keyframes circle-icon {
  0% {
    opacity: 0.1;
    transform: scale(0);
  }
  10% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.3;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
//默认按钮
.button,
%button {
  background: none;
  border: none;
  transform: translateZ(0);
  .circle-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  // 光圈
  .circle {
    position: absolute;
    margin: -50px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: circle 1500ms cubic-bezier(0, 0.11, 0.18, 1);
    @extend %bg-gray-1;
  }
  // 默认ICON按钮
  &.fab {
    background: none;
    padding: 0;
    font-size: 32px;
    overflow: visible;
    display: inline-block;
    transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transition-property: background-color, color;
    @extend %gray-4;
    @extend %pointer;
    &:hover {
      @extend %gray-5;
    }
    //无背景蓝色icon
    &.c-blue {
      @extend %blue-5;
      &:hover {
        @extend %blue-5;
      }
      .circle {
        @extend %bg-blue-2;
      }
    }

    // 白背+icon hover带动画
    &.bg-white {
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 46px;
      box-shadow: 0 1px 3px $gray-2 !important;
      transform: scale(1);
      transition: box-shadow 0.2s linear, transform 0.2s linear;
      @extend %radius-50p;
      @extend %bg-white;
      &:hover {
        box-shadow: 0 1px 8px $gray-2 !important;
        transform: scale(1.1);
      }
    }

    // blue背+icon hover带动画
    &.bg-blue {
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 46px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3) !important;
      transform: scale(1);
      transition: box-shadow 0.2s linear, transform 0.2s linear;
      @extend %white;
      @extend %radius-50p;
      @extend %bg-blue-5;
      &:hover {
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3) !important;
        transform: scale(1.1);
      }
    }
    //w-32
    &.w-32 {
      width: 32px;
      height: 32px;
      font-size: 32px;
      line-height: 32px;
      @extend %overflow;
    }
    // 默认ICON按钮光圈
    .circle {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -16px;
      width: 32px;
      height: 32px;
      @extend %bg-gray-2;
      animation: circle-icon 800ms cubic-bezier(0, 0.11, 0.18, 1);
    }
  }
}
//******************** 按钮 波纹效果  end *******************
//******************** 弹出层 关闭 begin *******************
// 关闭 X icon
.task-close {
  position: absolute;
  top: -15px;
  right: -13px;
  z-index: 10;
  width: 32px;
  height: 32px;
  transform: scale(1);
  transition: transform 0.2s ease;
  @extend %radius-50p;
  @extend %pointer;
  @extend %block;
  @extend %bg-gray-7;
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -14px;
    margin-top: -14px;
    content: url(./assets/close-white.svg);
    width: 28px;
    height: 28px;
  }
  &:hover {
    transform: scale(1.1);
  }
}

// 关闭 文字
.task-close-2 {
  position: absolute;
  right: -56px;
  top: 80px;
  padding-top: 9px;
  width: 56px;
  height: 96px;
  border-radius: 0 12px 12px 0;
  text-align: center;
  @extend %bg-white;
  @extend %pointer;
  i {
    font-size: 26px;
    line-height: 32px;
    @extend %gray-4;
  }
  span {
    line-height: 19px;
    @extend %font-16;
    @extend %gray-4;
    @extend %block;
    @extend %font-bold;
  }
  // 练习切换到螢幕
  &.switch-full-screen {
    top: 194px;
    height: 138px;
  }
}
//******************** 弹出层 关闭 end *******************

//******************** 弹出层  begin *******************

//遮罩 灰背景
.dialog-mask {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}
.dialog-container {
  display: none;
  position: fixed;
  z-index: 8000;
  left: 50%;
  top: 50%;
  width: 540px;
  margin-left: -270px;
  transform: translate3d(0, -50%, 0);
  //margin-top: -82px;
  @extend %white-board;
  //第二种宽度
  &.dialog-big {
    width: 720px;
    margin-left: -360px;
    //margin-top: -270px;
  }
  // 宽度800
  &.dialog-bigger {
    width: 800px;
    margin-left: -360px;
  }
}
.dialog-content {
  padding: 35px 35px 18px;
  @extend %clear-fix;
  .dia-p {
    line-height: 26px;
    margin-bottom: 13px;
    @extend %font-16;
  }
  .error-text {
    padding: 10px 0 0 20px;
  }
  input {
    width: 100%;
  }
  button {
    margin-left: 12px;
  }
}
//******** 校园版
.dialog-content-basic {
  padding: 35px 35px 25px;
  @extend %clear-fix;
  .blue-space {
    margin: 0 5px;
    @extend %blue-5;
  }
  .select-value {
    width: auto;
  }
}
//弹出层-小标题-纯文字
.pure-text {
  margin-bottom: -5px;
  line-height: 24px;
  text-align: left;
  @extend %font-16;
}
// 弹出层-描述下有控件
.text-bottom-control {
  margin-bottom: 8px;
  line-height: 24px;
  text-align: left;
  @extend %font-16;
}
// ******** 弹出层-表单控件 begin ***********

.dialog-controls.user-box {
  .row-box {
    position: relative;
    text-align: left;
    padding: 12px 0 13px 105px;
    width: 100%;
    .error-text {
      padding: 10px 13px 0;
      text-align: left;
      @extend %red-5;
    }
    //对错提示
    .svgform {
      position: absolute;
      top: 9px;
      right: 148px;
      opacity: 0;
      transform: scale(0.85);
      @extend %pointer;
      &.show {
        animation: inScale 0.1s forwards;
      }
    }
    .tel-box {
      position: relative;
      top: 3px;
      margin-right: 15px;
      @extend %font-16;
      @extend %font-bold;
      @extend %inline-block;
    }
  }

  //显示的个人资料
  .userdata {
    line-height: 36px;
    @extend %font-bold;
  }
  label {
    position: absolute;
    left: 0;
    top: 22px;
    text-align: right;
    width: 169px;
    @extend %pull-left;
    @extend %font-16;
    @extend %font-bold;
    @extend %gray-7;
    .centif-span {
      line-height: 16px;
      @extend %inline-block;
    }
    .ident-middle {
      position: relative;
      top: -2px;
      margin-right: 5px;
    }
  }
  .size-16 {
    padding-bottom: 38px;
  }
}
// ******** 弹出层-表单控件 end ***********
// 弹出层 -表单控件-定制
.dialog-controls.user-box {
  .row-box:last-child {
    padding-bottom: 0;
    width: 100%;
  }
  label {
    width: 85px;
  }
  input[type='text'] {
    width: 100%;
  }
  .size-16,
  .box-select {
    height: 61px;
    .select-wrap {
      top: 2px;
    }
  }
  //最后一个下拉菜单
  .last-select-row {
    height: 48px;
  }
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding-bottom: 26px;
  padding-right: 10px;
  button {
    @extend %btn-size-16;
  }
}
// 巩固题
.strengthen {
  height: 500px;
  overflow-y: auto;
}
//******************** 弹出层  end *******************
//********************** 弹出层(模拟angularjs-material) 动画 begin **********************
// 弹出层
.dialog-wrap {
  transform: translate3d(0, -50%, 0);
  // opacity: 0;
  // transform: translate3d(0, 0, 0) scale(.5);
}
// 弹出层遮罩
.dialog-mask {
  // opacity: 0;
  // transform: translate3d(0, 0, 0) scale(1);
}
.dialog-mask.dialog-transition-in,
.dialog-wrap.dialog-transition-in {
  display: block;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
.dialog-wrap.dialog-transition-out {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0.5);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
.dialog-mask.dialog-transition-out {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(1);
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
//********************** 弹出层(模拟angularjs-material) 动画 end **********************
// ************* 弹出层动画 begin *************
.popup-enter {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0.5);
}
.popup-enter.popup-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
.popup-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
.popup-leave.popup-leave-active {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(0.5);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
// 遮罩动画
.popup-2-enter {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(1);
}
.popup-2-enter.popup-2-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
.popup-2-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}
.popup-2-leave.popup-2-leave-active {
  opacity: 0;
  transform: translate3d(0, 0, 0) scale(1);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition-property: transform;
}
// ************* 弹出层动画 end *************
// ************* tab动画 begin *************
.tab-enter {
  animation: fadeInFromRight 1s;
  animation-timing-function: cubic-bezier(0.25, 0.01, 0.18, 1);
  opacity: 0;
  transition: opacity 1s linear;
}
.tab-enter.tab-enter-active {
  opacity: 1;
}
.tab-leave {
  animation: fadeOutFromLeft 1s;
  animation-timing-function: cubic-bezier(0.25, 0.01, 0.18, 1);
  opacity: 1;
  transition: opacity 1s linear;
}
.tab-leave.tab-leave-active {
  opacity: 0;
}
@keyframes fadeInFromRight {
  from {
    transform: translate3d(500px, 0, 0);
  }
  to {
    transform: none;
  }
}
.fadeInFromRight {
  animation-name: fadeInFromRight;
}
@keyframes fadeOutFromLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-500px, 0, 0);
  }
}
.fadeInFromRight {
  animation-name: fadeOutFromLeft;
}
// ************* tab动画 end *************
// ************* tip动画 end *************
.tip {
  position: fixed;
  z-index: 10;
  padding: 10px 10px 8px;
  background-color: black;
  opacity: 0.5;
  @extend %radius-10;
  @extend %white;
}
.tip-enter {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}
.tip-enter.tip-enter-active {
  opacity: 0.5;
  transform: translate3d(0, 0, 0);
  transition: 0.18s ease-out !important;
  transition-property: transform;
}
.tip-leave {
  opacity: 0.5;
  transform: translate3d(0, 0, 0);
}
.tip-leave.tip-leave-active {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: 0.18s ease-out !important;
  transition-property: transform;
}
// ************* tip动画 end *************
//*********** 教师-班级数据-引导 begin ***********
// 遮罩
.dialog-mask.show {
  opacity: 1;
  z-index: 1000;
}
// 步1盒
.guide-box {
  @extend %inline-block;
}
.guide-box.show {
  position: relative;
  z-index: 1000;
  padding: 10px 8px 4px 12px;
  @extend %bg-white;
  @extend %radius-10;
  .step-div {
    display: block;
  }
}

// 个人中心-手机-引导
.guide-box-mobile.show {
  position: relative;
  z-index: 9999;
  padding: 10px 8px 0 12px;
  height: 60px;
  @extend %bg-white;
  @extend %radius-10;
  .step-div {
    padding-left: 24px;
    padding-top: 17px;
    display: block;
    left: 190px;
    top: 90px;
    width: 380px;
    height: 136px;
    h6 {
      padding-top: 5px;
      padding-left: 122px;
      line-height: 26px;
      @extend %font-16;
      @extend %gray-4;
    }
    .step-btn {
      left: 148px;
      top: 102px;
    }
  }
  .img-mobile {
    padding-top: 4px;
    @extend %pull-left;
  }
}

//*********** 教师-班级数据-引导 end ***********
//*********** 物理视频中、视频后-弹出层 begin ***********
.questionnaire-1 {
  padding-top: 12px;
  padding-bottom: 20px;
  width: 100%;
  @extend %clear-fix;
  li {
    padding-bottom: 16px;
    width: 50%;
    @extend %pull-left;
  }
  .theme-radio span {
    top: 4px;
  }
  .theme-radio strong {
    font-size: 16px !important;
  }
}
.questionnaire-2 {
  li {
    width: 33.3333333%;
  }
}
// 视频退出
.questionnaire-3 {
  padding-top: 6px;
  padding-bottom: 76px;
  width: 470px;
  li {
    height: 37px;
  }
  li:nth-child(2n + 1) {
    width: 240px;
  }
  li:nth-child(2n) {
    width: 220px;
  }
}
.cry-onion-img {
  position: absolute;
  right: 35px;
  top: 88px;
  width: 188px;
  height: 148px;
}
//*********** 物理视频中、视频后-弹出层 end ***********
.img-barrage {
  width: 100px;
}

// *********** 右下角二维码 begin ***********
@keyframes translateX {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.ewm-wrap {
  height: 200px;
}
.problem-icon {
  font-size: 24px;
  position: fixed;
  bottom: 10px;
}
// *********** 右下角二维码 begin ***********
