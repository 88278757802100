#problemFull:-webkit-full-screen {
  background-color: white;
}
#problemFull:fullscreen {
  background-color: white;
}
#problemFull:-moz-full-screen {
  background-color: white;
}
.data-board {
  margin-top: 70px;
  background-color: #fff;
  border-radius: 10px;
}
.tab-font {
  color: $gray-8;
  padding: 20px 45px 0;
}
.ant-tabs-tab {
  font-weight: bold;
  font-size: 16px;
}
.ant-tabs-tab-active {
  color: #1696ea !important;
  font-weight: bold !important;
}
.font-12.ant-progress-show-info .ant-progress-outer {
  width: 45%;
  .ant-progress-inner {
    width: 58px;
    height: 8px;
    .ant-progress-bg {
      height: 8px !important;
    }
  }
}
.ant-tabs-ink-bar {
  background-color: #1696ea !important;
  height: 3px !important;
}
.noData-tab-board {
  .ant-tabs-tab-active {
    color: rgba(0, 0, 0, 0.25) !important;
    font-weight: bold !important;
  }
  .ant-tabs-ink-bar {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }
}
.problem-report-detail-scroll {
  overflow: auto;
}
.report-content-div {
  h1 {
    font-size: 22px;
    margin-bottom: 25px;
    color: #3c3c3c;
  }
  h3 {
    font-size: 18px;
    color: #2c2c2c;
    margin-bottom: 25px;
  }
  h5 {
    font-size: 12px;
    color: $gray-8;
    font-weight: unset;
  }
}
.problem-chart {
  margin-bottom: 40px;
}
.homework-red {
  color: #ef5e45;
}
.homework-green {
  color: #8fcb31;
}
.homework-blue {
  color: #1797eb;
}
.problems {
  margin-top: 5px;
  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
  }
  .ant-anchor {
    margin: 27px 0.5px;
    font-size: 12px;
    .description {
      margin-left: 30px;
    }
  }
  .guide-title {
    font-size: 14px;
    color: #2c2c2c;
    position: absolute;
  }
}
.problem-guide-fixed {
  position: fixed;
  top: 15%;
}
.problem-guide-unset {
  position: unset;
}
.problems-div {
  float: right;
}
.answer-triangle {
  position: absolute;
  right: 20px;
  z-index: 10;
  width: 20px;
  background: #fff url(./assets/triangle.svg) no-repeat center center;
  background-size: 20px;
  margin-top: -11px;
}
.problem-student-answer {
  margin: 20px 0;
  padding: 21px 30px 24px;
  border: 2px solid #e5e5e5;
  border-radius: 12px;
  .problem-answer {
    display: flex;
    float: top;
    margin-bottom: 20px;
    overflow: hidden;
    .answer-no {
      width: 167px;
      display: inline-block;
      .arrow {
        margin-left: 10px;
        margin-right: 40px;
      }
      span {
        vertical-align: top;
      }
    }
    .blank-answer {
      line-height: 20px;
    }
    .choice-answer-no {
      width: 100px;
    }
    .answer-number {
      display: inline-block;
      margin-left: 20px;
      color: #000000;
      vertical-align: top;
    }
    .choice-answer-number {
      margin-top: 5px;
    }
  }
  .answer-student {
    flex: 1;
    display: inline-block;
    vertical-align: top;
    align-self: center;
    span {
      margin-right: 20px;
      margin-bottom: 3px;
      color: #888888;
      display: inline-block;
      word-break: break-all;
      word-wrap: break-word;
      vertical-align: top;
    }
  }
  .border {
    border-bottom: 1px solid #e5e5e5;
  }
  .answer-data {
    margin-bottom: 20px;
    .blank-num {
      color: #2c2c2c;
      font-weight: bold;
      margin-bottom: 20px;
      .blank-num-content {
        width: 20px;
        text-align: center;
        @extend %inline-block;
        i {
          width: 15px;
          height: 15px;
          line-height: 15px;
          font-size: 13px !important;
          padding-top: 1px;
          @extend %inline-block;
          @extend %radius-50p;
          @extend %bg-gray-3;
          @extend %gray-1;
          @extend %font-14;
          @extend %font-bold;
        }
      }
    }
  }
}
.bc-url-red {
  background: url(./assets/circle_red.svg) no-repeat;
}
.bc-url-green {
  background: url(./assets/circle_green.svg) no-repeat;
}
.choice {
  background-size: 22px;
  color: white;
  font-size: 16px;
  padding: 2px 10px 3px 6px;
  line-height: 25px;
}
.unfinished-student {
  @extend %bg-gray-1;
  border-radius: 8px;
  padding: 25px 20px 15px 30px;
  img {
    padding-right: 5px;
  }
  span {
    display: inline-block;
    color: #3b3b3b;
    font-weight: bold;
    font-size: 16px;
  }
  .students {
    margin-top: 14px;
    padding-left: 2px;
    button {
      margin-right: 8px;
      padding: 0;
      float: right;
      width: 126px;
      height: 38px;
    }
  }
  p {
    padding-right: 10px;
    display: inline-block;
    word-break: break-all;
    word-wrap: break-word;
    width: 78px;
    height: 24px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.finished-student {
  margin-top: 40px;
  margin-bottom: 30px;
}
.over-time {
  color: #e24217;
  font-size: 12px;
}
.padding-20 {
  margin-right: 10%;
  color: red;
  width: 1%;
}
.gray-border-btn {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  font-weight: unset;
  width: 166px;
  height: 32px;
  font-size: 14px;
}
.white-board.practice-full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4000;
  border-radius: 0;
}
.problem-report-detail {
  position: relative;
  z-index: 2;
  @extend %clear-fix;
  img {
    padding-right: 8px;
  }
}
.class-model {
  color: #1797eb;
  cursor: pointer;
}
.add-minus-box {
  border-bottom: 1px solid $gray-1;
  text-align: right;
  padding-top: 20px;
  margin-bottom: 20px;
  .icon {
    font-size: 24px;
    padding-right: 5px;
    @extend %inline-block;
    @extend %pointer;
  }
  .isDisable {
    @extend %gray-1;
  }
  .font-slider {
    width: 130px;
    margin-bottom: 1px;
    @extend %inline-block;
  }
  em {
    padding: 0 15px 20px;
    font-size: 16px;
    @extend %inline-block;
    @extend %font-bold;
  }
  .width-2-vertical {
    width: 2px;
    height: 28px;
    margin: 0 20px -10px 20px;
    @extend %inline-block;
    @extend %bg-gray-1;
  }
}
.anchor-problem {
  margin-top: -29px;
}
.practice-quit-fullscreen {
  padding: 0 24px 0 0;
  font-size: 16px;
  @extend %pointer;
  @extend %inline-block;
  @extend %font-bold;
}

// *********** topic-report begin***********
.topic-report {
  .ant-tabs-bar {
    border: 0 !important;
  }
  .ant-tabs-ink-bar {
    height: 0 !important;
  }
  .ant-tabs-nav-wrap {
    margin-bottom: 0 !important;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0 !important;
    width: 293px;
    height: 179px;
    border: 1.5px solid #f4f4f4;
    border-radius: 9.6px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    border: 1.5px solid #1289dc;
    border-radius: 9.6px;
  }
}
// *********** topic-report end***********
