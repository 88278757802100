.ant-modal-content {
  border-radius: 12px;
  .ant-modal-header {
    border-radius: 12px !important;
  }
  .ant-modal-footer {
    border-top: 0;
    padding: 20px 20px 25px;
  }
}
