.model {
  width: 1172px;
  height: 635px;
  background-image: url('../../../../assets/deliberate-practice/modal_rank_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1172px;
  .close {
    width: 108px;
    height: 55px;
    background-image: url('../../../../assets/deliberate-practice/modal_rank_close.png');
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: -2px;
    top: 0;
    cursor: pointer;
  }
  .title {
    width: 295px;
    height: 35px;
    margin: 38px 0 45px;
  }
  .rank_block {
    display: flex;
    align-items: center;
    justify-content: center;
    .rank {
      width: 527px;
      height: 384px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 25px 25px 5px 25px;
      margin-right: 37px;
      &:last-child {
        margin-right: 0;
      }
      img {
        display: block;
        width: 106px;
        height: 42px;
        margin-bottom: 32px;
      }
      .list {
        overflow: auto;
        width: 100%;
        height: 280px;
        margin-right: 280px;
        &::-webkit-scrollbar {
          display: none;
        }
        .list_item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .info,
          .score {
            display: flex;
            align-items: center;
            font-size: 28px;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            .rank_num {
              font-family: DINAlternate-Bold;
              margin-right: 26px;
            }
          }
        }
      }
    }
    .rank_0 {
      margin-right: 37px;
      background-image: url('../../../../assets/deliberate-practice/modal_rank_simple_bg.png');
    }
    .rank_1 {
      background-image: url('../../../../assets/deliberate-practice/modal_rank_d_bg.png');
    }
  }
  .fb {
    width: 244px;
    height: 48px;
    margin: 30px auto 0;
    cursor: pointer;
    img {
      display: block;
      width: 100%;
    }
  }
}
@media screen and (max-width: 1440px) {
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-height: 700px) {
  .model {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1439px) and (min-width: 853px) {
  .model {
    transform: scale(0.5);
  }
}
