// *******************  begin *********************
//数据管理上部
.data-sel-box {
  padding: 35px 40px 26px;
}
//内容盒子
.data-content {
  @extend %clear-fix;
}
.data-nav {
  margin-left: 40px;
  width: 156px;
  @extend %pull-left;
}
.data-ul {
  width: 100%;
  @extend %clear-fix;
  li {
    width: 100%;
    height: 36px;
    line-height: 36px;
    @extend %pull-left;
    @extend %font-bold;
    @extend %overflow;
    @extend %pointer;
    @extend %gray-4;
    &:hover {
      transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
      transition-property: color;
      @extend %gray-5;
    }
    &.current {
      &:hover {
        @extend %blue-5;
      }
      @extend %blue-5;
    }
  }
  //金牌提分标识
  .gold-medal-sma {
    position: relative;
    top: -6px;
    margin-left: 2px;
  }
}
// 右侧图表区域
.data-right-wrape {
  margin-right: 40px;
  padding-bottom: 30px;
  width: 460px;
  @extend %pull-right;
  @extend %clear-fix;
}
.data-figure {
  width: 460px;
  margin-bottom: 30px;
  @extend %clear-fix;
}
//数据标题区域
.data-figure-hd {
  height: 68px;
  h3 {
    padding: 22px 0 0 22px;
    @extend %pull-left;
    @extend %font-20;
    @extend %gray-7;
    @extend %font-bold;
  }
  .icon-btn-right {
    padding: 17px 22px 0 0;
    @extend %pull-right;
    .icon-seat {
      position: relative;
      top: -4px;
      margin-right: 8px;
    }
    .button.fab {
      width: 32px;
      height: 32px;
    }
    .icon-snow {
      position: relative;
      top: 2px;
      font-size: 28px;
    }
  }
}
// 取值范围盒子
.data-scope {
  position: relative;
  margin: 0 22px 0;
  height: 165px;
  padding-bottom: 26px;
  border-bottom: 1px solid $gray-1;
  @extend %clear-fix;
  .data-tip-div {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
    width: 258px;
    height: 138px;
  }
}

.scope-box {
  position: relative;
  width: 400px;
  height: 214px;
  @extend %overflow;
  @extend %pull-left;

  // 旋转的图
  .chart-bg-gray {
    position: absolute;
    left: 0;
    top: 1px;
    width: 400px;
    height: 214px;
  }
  .chart-line-img {
    position: absolute;
    left: 50%;
    bottom: 9px;
    margin-left: -158px;
    width: 316px;
    height: 165px;
  }
  .chart-pointer {
    position: absolute;
    left: 195px;
    bottom: -1px;
    width: 166px;
    height: 23px;
    transform: rotate(-180deg);
    transform-origin: left center;
    transition: all .3s ease-in;
  }
  .chart-blue-bg {
    position: absolute;
    left: 50%;
    bottom: 9px;
    margin-left: -189px;
    width: 372px;
    height: 186px;

    transform: rotate(-180deg);
    transform-origin: center bottom;
    transition: all .3s ease-in;
  }
}

//取值范围数据
.scope-num {
  position: relative;
  width: 150px;
  height: 138px;
  @extend %pull-right;
  .scope-num-1 {
    position: absolute;
    bottom: 56px;
    width: 100%;
    text-align: center;
    strong {
      font-size: 56px;
      @extend %gray-7;
    }
    em {
      font-size: 30px;
      @extend %gray-7;
      @extend %font-bold;
    }
  }
  .scope-num-text {
    position: absolute;
    bottom: 24px;
    width: 100%;
    text-align: center;
    @extend %font-16;
  }
}
//*********** 视频+ 补充练习 begin ***********
.bottle-box {
  margin: 0 20px 16px 22px;
  @extend %clear-fix;
}
.bottle {
  width: 192px;
  @extend %pull-left;
  @extend %clear-fix;
}
.bottle-hd {
  position: relative;
  height: 64px;
  h3 {
    position: absolute;
    left: 0;
    top: 16px;
    @extend %font-16;
    @extend %gray-7;
  }
  p {
    position: absolute;
    left: 0;
    top: 42px;
  }
  .bottle-hd-right {
    position: absolute;
    top: 16px;
    right: 0;
    button {
      margin-bottom: 10px;
      @extend %block;
      @extend %font-14;
    }
  }
}
// 瓶子图
.bottle-chart {
  position: relative;
  width: 192px;
  height: 125px;
  &.bottle-3-2 {
    .bottle-gold {
      left: 77px;
    }
  }
}
.bottle-wrape {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 36px;
  height: 110px;
  em {
    position: absolute;
    top: -16px;
    width: 36px;
    text-align: center;
    @extend %font-16;
    @extend %font-bold;
    @extend %gray-7;
    @extend %block;
  }
  p {
    position: absolute;
    bottom: -16px;
    width: 36px;
    text-align: center;
    @extend %font-12;
    @extend %gray-3;
    @extend %block;
  }
}
.bottle-base {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 36px;
  height: 36px;
  border: 4px solid $gray-1;
  @extend %radius-50p;
  i {
    position: absolute;
    left: -1px;
    bottom: -7px;
    font-size: 30px;
  }
}
// 瓶杆
.bottle-stem {
  position: absolute;
  left: 50%;
  bottom: 30px;
  margin-left: -11px;
  width: 22px;
  height: 80px;
  border: 4px solid $gray-1;
  border-bottom: none;
  border-radius: 11px 11px 0 0;
  @extend %overflow;
  .icon-dashed {
    position: absolute;
    right: 1px;
    bottom: -16px;
    font-size: 70px;
  }
}
//灰瓶子
.bottle-gray {
  left: 0;
  .bottle-base {
    @extend %bg-gray-2;
    i {
      color: rgba(190, 190, 190, 0.5);
    }
  }
  .bottle-stem {
    @extend %bg-gray-2;
    .icon-dashed {
      color: rgba(190, 190, 190, 0.5);
    }
  }
}
//金瓶子
.bottle-gold {
  left: 52px;
  .bottle-base {
    @extend %bg-gold-3;
    i {
      color: rgba(246, 193, 21, 0.5);
    }
  }
  .bottle-stem {
    @extend %bg-gold-3;
    .icon-dashed {
      color: rgba(246, 193, 21, 0.5);
    }
  }
}
//蓝-3-瓶子
.bottle-blue-3 {
  left: 104px;
  .bottle-base {
    @extend %bg-blue-3;
    i {
      @extend %white;
    }
  }
  .bottle-stem {
    @extend %bg-blue-3;
    .icon-dashed {
      color: rgba(22, 150, 234, 0.5);
    }
  }
}
//蓝-5-瓶子
.bottle-blue-5 {
  left: 156px;
  .bottle-base {
    @extend %bg-blue-5;
    i {
      @extend %white;
    }
  }
  .bottle-stem {
    @extend %bg-blue-5;
    .icon-dashed {
      color: rgba(17, 126, 109, 0.5);
    }
  }
}
// 垂直线
.line-vertical {
  margin: 16px 16px 0;
  width: 1px;
  height: 190px;
  @extend %overflow;
  @extend %bg-gray-1;
  @extend %pull-left;
}
//*********** 视频+ 补充练习 end ***********
// 无视频、无补充练习
.bottle-info {
  padding-top: 36px;
  text-align: center;
  @extend %font-16;
  @extend %gray-3;
  p {
    padding-bottom: 10px;
  }
}

// *******************  end *********************

// ******************4.0升级提示 begin*******************
.upgrade-div {
  padding: 30px 40px 24px;
  margin-bottom: 30px;
  @extend %bg-gray-4;
  @extend %radius-10;
  @extend %white;
  h5 {
    padding-bottom: 12px;
    @extend %font-20;
  }
  p {
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    @extend %font-16;
  }
}
//弹出层-完成情况-名字变黄提示
.text-right {
  @extend %pull-right;
  @extend %font-16;
  @extend %gray-5;
  @extend %pointer;
  i {
    position: relative;
    top: 3px;
    margin-right: 8px;
  }
}
.prompt-div {
  position: relative;
  top: -8px;
  padding: 20px 20px 11px;
  margin-bottom: 18px;
  @extend %bg-gray-1;
  @extend %radius-10;
  p {
    line-height: 20px;
    padding-bottom: 6px;
  }
}
//右侧学习情况
.learning-situation {
  padding: 9px 30px 25px;
  p {
    line-height: 26px;
    @extend %font-16;
  }
}
// ******************4.0升级提示 end*******************
// 升级完成提示
.upgrade-complete {
  padding: 30px 30px 26px;
  h5 {
    padding-bottom: 8px;
    @extend %font-22;
    @extend %gray-7;
  }
  p {
    line-height: 22px;
    @extend %font-16;
  }
}
