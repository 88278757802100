//******************** 教师 begin ****************
@font-face {
  font-family: 'DIN Alternate Bold';
  src: url("assets/fonts/DINAlternateBold.ttf") format('truetype');
}
* {
  font-family: 'Noto Sans TC', sans-serif, 'DIN Alternate Bold';
}
//* {
//    font-family: Noto Sans TC;
//}

// 通栏广告
.full-column-img {
  position: relative;
  margin: 0 auto 30px;
  width: 1090px;
  height: 120px;
  @extend %pointer;
  img {
    width: 1090px;
  }
  .full-column-closed {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
    width: 30px;
    height: 30px;
    @extend %block;
    @extend %pointer;
  }
}
.school-yard {
  font-size: 0;
  @extend %overflow;
  img {
    width: 100%;
  }
}
// 主页
.user-headline.font-26 {
  @extend %font-26;
}
.my-class-2 {
  padding-left: 25px;
  width: 100%;
  @extend %clear-fix;
  li {
    margin: 0 15px 40px;
    width: 230px;
    height: 250px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.18);
    @extend %bg-blue-5;
    @extend %radius-8;
    @extend %pull-left;
  }
  h4 {
    margin-top: 34px;
    margin-bottom: 20px;
    padding: 0 13px;
    height: 52px;
    line-height: 26px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %white;
    @extend %font-22;
  }
  h5 {
    line-height: 26px;
    @extend %blue-3;
    @extend %font-16;
  }
  .btn-size-16 {
    margin-top: 28px;
    width: 190px;
    height: 34px !important;
    overflow: hidden;
  }
  .example {
    position: relative;
    @extend %bg-gray-5;
    h5 {
      @extend %gray-3;
    }
  }
  //pngsprite
  .help {
    position: absolute;
    top: 0;
    right: 0;
    width: 72px;
    height: 72px;
    @extend %block;
    span {
      position: absolute;
      top: 5px;
      right: 3px;
      @extend %font-28;
      @extend %white;
    }
  }
  .add-class {
    box-shadow: none;
    padding-top: 76px;
    @extend %bg-gray-1;
    h5 {
      margin-top: 18px;
      @extend %gray-3;
    }
    .button.fab.bg-white {
      @extend %overflow;
    }
  }
}
// 活动焦点图
.activities-box {
  position: relative;
  margin: 0 40px 40px;
  width: 1010px;
  height: 178px;
  .button {
    position: absolute;
    top: 50%;
    margin-top: -24px;
    &.hidden {
      @extend %hidden;
    }
  }
  .pre {
    left: -22px;
  }
  .next {
    right: -22px;
  }
}
.activities-img {
  position: relative;
  width: 1010px;
  height: 178px;
  overflow: hidden;
}
.activities-ul {
  position: absolute;
  left: 0;
  top: 0;
  width: 10000px;
  li {
    width: 346px;
    height: 178px;
    @extend %pull-left;
    @extend %radius-8;
  }
  img {
    width: 318px;
    height: 178px;
    @extend %radius-8;
    @extend %bg-gray-1;
  }
}

//******************** 教师 end ****************
// 视频、练习 顶部加 “让学生加入” 提示条
.let-students-join {
  font-size: 0;
  position: relative;
  width: 860px;
  height: 60px;
  @extend %center;
  @extend %bg-gray-4;
  @extend %radius-10;
  p {
    padding-left: 30px;
    line-height: 60px;
    @extend %font-16;
    @extend %white;
    @extend %font-bold;
  }
  margin-bottom: 40px;
}
.students-join-btn {
  position: absolute;
  top: 13px;
  right: 30px;
  button {
    margin: 0;
    margin-left: 6px;
    @extend %btn-size-16;
  }
}
