.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.my-homework-list {
  .ant-tabs-bar {
    margin: 0 0 30px;
    padding: 20px 40px 0;
    @extend %white-board;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    &::after {
      content: '';
      position: absolute;
      top: 20px;
      right: 40px;
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #ccc;
    }
  }
}
.ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
  display: none !important;
}
.ant-btn {
  border: none;
  text-shadow: 0 0 0;
  box-shadow: 0 0 0;
  color: #909090;
  @extend %font-16;
  @extend %font-bold;
  &:hover {
    color: #909090;
  }
}
.ant-btn-primary {
  color: #1797eb;
  background-color: #fff;
  &:hover {
    color: #1797eb;
    background-color: #fff;
  }
}
.tabs-outer-box {
  .ant-tabs-bar {
    margin: 20px 40px 15px;
  }
}
.ant-modal-mask,
.ant-modal-wrap {
  z-index: 9999;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow svg {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 257px !important;
  width: 16px;
  height: 16px;
  background: url('./assets/arrow.svg') no-repeat center center;
  top: 14px !important;
  transform: rotate(180deg) !important;
  transition: transform 0.24s;
  svg {
    display: none;
  }
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header[aria-expanded='true']
  .anticon-right {
  transform: rotate(0deg) !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 15px 0 15px 40px !important;
  line-height: 16px;
}
.ant-calendar-picker-container {
  z-index: 9999;
}
.hw-info-box .hw-info .ant-col-8,
.hw-info-box .hw-info .ant-col-12 {
  line-height: 34px;
  @extend %overflow;
  .ant-checkbox-wrapper {
    margin-left: 5px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border: 0;
  }
  i {
    position: relative;
    top: -1px;
  }
  img {
    position: relative;
    top: -2px;
    margin-right: 8px;
    @extend %inline-block;
  }
}
.ant-modal-header {
  padding: 0;
  border-bottom: 0;
}
.ant-modal-title {
  padding: 35px 35px 15px;
  color: #3c3c3c;
  @extend %font-24;
  @extend %font-bold;
}
.ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 0;
}
.ant-modal-close {
  .anticon svg {
    display: none;
  }
  position: absolute;
  top: -15px;
  right: -13px;
  z-index: 10;
  width: 32px;
  height: 32px;
  background-color: #3b3b3b;
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.2s ease;
  &::before {
    position: absolute;
    left: 50%;
    top: 50%;
    display: inline-block;
    margin-left: -14px;
    margin-top: -14px;
    content: url(./assets/close-white.svg);
    width: 28px;
    height: 28px;
  }
}
.sel-box-hd {
  margin: 0 30px;
  padding: 30px 10px;
  border-bottom: 1px solid #eaeaea;
  height: 84px;
}
.fixed-left-box {
  position: fixed;
  height: 600px;
  //height: calc(100vh - 150px);
}
.fixed-self-adaption-left-box {
  position: fixed;
  max-height: 600px;
  //height: calc(100vh - 150px);
}
.problem-num {
  padding-left: 35px;
  font-size: 14px;
}
.chapters-box {
  height: 500px;
  //height: calc(100vh - 400px);
  overflow-y: auto;
}
.section-list {
  padding-left: 41px;
  line-height: 48px;
  height: 48px;
  font-weight: normal !important;
  @extend %font-16;
  @extend %pointer;
  @extend %gray-5;
  &.current {
    @extend %blue-5;
    background-color: #f4f4f4;
  }
  &:hover {
    background-color: #f4f4f4;
  }
}
.practiceType {
  padding: 40px 40px 24px 40px;
  color: #2c2c2c;
  @extend %font-18;
  @extend %font-bold;
}
.title-msg-box {
  padding-top: 10px;
  text-align: center;
  height: 50px;
}
.title-info-msg {
  @extend %font-12;
  @extend %red-5;
}
.section-headings {
  position: relative;
  text-align: center;
  color: #3c3c3c;
  height: 36px;
  @extend %font-28;
  @extend %font-bold;

  .editableText {
    background: transparent;
    position: absolute;
    top: 0px;
    left: 50%;
    padding: 0;
    transform: translate(-50%, -50%);
    border-bottom: 3px solid white;
    //color: #3C3C3C;
    color: transparent;
    text-align: center;
    white-space: nowrap;
    @extend %inline-block;
    @extend %font-28;
    @extend %font-bold;
    &:after {
      position: relative;
      top: -3px;
      margin-left: 25px;
      content: '';
      width: 14px;
      height: 14px;
      background: url('./assets/editor.svg') no-repeat center center;
      @extend %inline-block;
    }
  }
  .editableText.noText {
    &:after {
      display: none;
    }
  }
  .editableInput {
    background: transparent;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border-radius: 0;
    border: 0;
    border-bottom: 3px solid white;
    color: #3c3c3c;
    //color: transparent;
    text-align: center;
    width: 430px;
    @extend %inline-block;
    @extend %font-28;
    @extend %font-bold;
    &.error-border {
      border-bottom: 3px solid $red-5;
    }
    &:focus {
      border-bottom: 3px solid #3c3c3c;
    }
  }
}
.practice-sum {
  padding-top: 15px;
  text-align: center;
  color: #909090;
  @extend %font-12;
}
.set-btn-box {
  padding: 15px 40px;
  text-align: right;
  @extend %bg-white;
  button {
    margin-left: 20px;
    padding-left: 13px;
  }
}
.ant-modal-body {
  padding: 0 !important;
  //padding: 20px 0 !important;
}
.hw-info-box {
  li {
    position: relative;
    padding-left: 120px;
    margin-bottom: 25px;
    height: 34px;
    line-height: 34px;
    .info-name {
      position: absolute;
      left: 35px;
      top: 0;
      color: #2c2c2c;
      @extend %font-bold;
      i {
        @extend %red-5;
      }
    }
    &.height-auto {
      height: auto;
    }
    &.textarea-has-msg {
      margin-bottom: 0;
    }
  }
  &.add-problem {
    li {
      margin-bottom: 0;
      padding-left: 107px;
    }
  }
  .hw-info {
    position: relative;
    line-height: 34px;
    .ant-col-8 {
      height: 34px;
    }
  }
  .hw-class-box {
    height: 120px;
    overflow-y: auto;
  }
  .textarea-border {
    padding: 16px;
    width: 400px;
    height: 116px;
    border: 1.5px solid #eaeaea;
    border-radius: 4px;
    @extend %gray-5;
    @extend %font-bold;
  }
  .input-square {
    border: 1px solid #eaeaea;
    border-radius: 4px;
    width: 400px;
  }
  .ant-input {
    height: 34px;
  }
}
.show-div {
  display: block;
}
.hide-div {
  display: none;
}
.hw-success-container {
  padding: 110px 0 115px;
  text-align: center;
}
.success-img-hw {
  width: 75px;
}
.hw-success-title {
  padding: 5px 0 10px;
  color: #3c3c3c;
  @extend %font-24;
  @extend %font-bold;
}
.hw-success-info {
  padding: 12px 18px 11px;
  background: #f4f4f4;
  border-radius: 12px;
  color: #909090;
  @extend %font-14;
  @extend %inline-block;
}
.hw-success-div {
  padding-top: 18px;
}
.hw-success-ul {
  padding-left: 277px;
  li {
    position: relative;
    padding: 2px 0 30px 90px;
    text-align: left;
    strong {
      position: absolute;
      top: 0;
      left: 0;
      color: #2c2c2c;
      @extend %font-14;
      @extend %font-bold;
    }
  }
}
.hw-success-class {
  span {
    padding-right: 10px;
  }
}
.set-hw-hd {
  position: relative;
}
.modal-info-box {
  padding: 0 35px;
  @extend %font-16;
}

.create-class-banner {
  position: relative;
  padding: 23px 160px 0 121px;
  margin-bottom: 40px;
  height: 60px;
  background: #b3b3b3;
  border-radius: 12px;
  .onionBaby-img {
    position: absolute;
    left: 0;
    top: -20px;
    width: 121px;
  }
  p {
    @extend %font-16;
    @extend %white;
    @extend %font-bold;
  }
  button {
    position: absolute;
    top: 12px;
    right: 31px;
    height: 38px;
  }
}
.add-problem-box {
  height: 500px;
  overflow-y: auto;
}

.problem-box-outer {
  position: relative;
}
.del-btn {
  position: absolute;
  right: 35px;
  bottom: 15px;
  color: #b3b3b3;
  @extend %font-13;
  @extend %pointer;
}
.no-problems-msg {
  padding: 120px 0 120px;
  text-align: center;
  p {
    padding-top: 15px;
    color: #909090;
    @extend %font-16;
    @extend %font-bold;
  }
}
.showBox {
  display: block !important;
}
.hideBox {
  display: none !important;
}
.textarea-msg-box {
  height: 14px;
}
