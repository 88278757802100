.onion_bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-180deg, #002fef 3%, #0085ff 91%);
  overflow: hidden;
  z-index: -9999;
}
.img1 {
  position: absolute;
  height: 47.22%;
  left: 0;
  top: 52.78%;
  z-index: -9999;
}
.img2 {
  position: absolute;
  width: 45.36%;
  left: 4.06%;
  bottom: 0;
  z-index: -9999;
}
.img3 {
  position: absolute;
  width: 32.66%;
  right: 6.56%;
  bottom: 0;
  z-index: -9999;
}
.img4 {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: -9999;
}
.img5 {
  position: absolute;
  width: 5.52%;
  left: 10.78%;
  bottom: 23.52%;
  z-index: -9999;
}
.img6 {
  position: absolute;
  width: 5.21%;
  left: 7.19%;
  bottom: 61.39%;
  z-index: -9999;
}
.img7 {
  position: absolute;
  width: 5.21%;
  left: 22.81%;
  bottom: 77.5%;
  z-index: -9999;
}
.img8 {
  position: absolute;
  width: 5.21%;
  left: 53.28%;
  bottom: 43.15%;
  z-index: -9999;
}
.img9 {
  position: absolute;
  width: 6.77%;
  left: 81.77%;
  bottom: 83.43%;
  z-index: -9999;
}
.img10 {
  position: absolute;
  width: 2.81%;
  left: 93.7%;
  bottom: 79.07%;
  z-index: -9999;
}
.img11 {
  position: absolute;
  width: 20%;
  left: 78.8%;
  bottom: 17.96%;
  z-index: -9999;
}
.img12 {
  position: absolute;
  height: 53.8%;
  left: 86.56%;
  bottom: 22.78%;
  z-index: -9999;
}
.img121 {
  position: absolute;
  width: 4.53%;
  left: 82.55%;
  bottom: 44.63%;
  z-index: -9999;
}
.img13 {
  position: absolute;
  /*     width: 32.4%; */
  height: 33.7%;
  right: 0;
  /*     left: 67.6%; */
  bottom: 0;
  z-index: -9999;
}
.img14 {
  position: absolute;
  width: 13.33%;
  left: 74.64%;
  bottom: 53.33%;
  z-index: -9999;
}
.img15 {
  position: absolute;
  width: 1.09%;
  left: 86.46%;
  bottom: 66.48%;
  z-index: -9999;
}
.img16 {
  position: absolute;
  width: 1.82%;
  left: 81.35%;
  bottom: 41.48%;
  z-index: -9999;
}
.img17 {
  position: absolute;
  width: 1.35%;
  left: 84.58%;
  top: 64.91%;
  right: 14.06%;
  bottom: 32.69%;
  z-index: -9999;
}
