.practice-full-screen {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  .task-content {
    min-height: 700px !important;
  }
  .multi {
    text-align: center;
    transform: scale(1.5);
    transform-origin: center bottom;
  }
  .multi-num {
    left: 18px;
    transform: scale(1.3);
    transform-origin: left center;
  }
  .show-answer {
    width: 100%;
    font-size: 22px;
    .answer-div {
      padding-top: 2px;
      padding-bottom: 0;
      min-height: 34px;
      line-height: 34px;
    }
  }
  .multi-answer {
    font-size: 22px;
  }
  .problem-footer .explains {
    font-size: 22px;
  }
  .topic-practice {
    .bc-triangle {
      top: -21px;
    }
    .task-num {
      font-size: 24px;
    }
  }
  // 普通键盘
  .keyboard {
    bottom: -400px;
    height: 400px;
  }
  .keyboard-ul li {
    width: 9.3%;
    margin-top: 18px;
    height: auto;
    i {
      font-size: 34px;
      width: 55px;
      height: 55px;
      &:after {
        right: -6px;
      }
    }
  }
  // 高中键盘
  .highKeyboard {
    bottom: -460px;
    height: 460px;
  }
  .keyboard-ul-hight {
    li {
      width: 9.2%;
      margin-top: 18px;
      height: auto;
      border-radius: 100%;
      background-color: transparent;
      margin-left: 2px;
      i {
        font-size: 24px;
        width: 40px;
        height: 40px;
        background-color: #f3f3f3;
        &:hover {
          background-color: #eaeaea;
        }
      }
    }
    .keyboard__li-math {
      width: 15.96%;
      border-radius: 75px;
      background-color: #f3f3f3;
      margin-right: 2px;
      &:hover {
        background-color: #eaeaea;
      }
      i {
        width: 52px;
        background-color: transparent;
      }
    }
    .keyboard__li-include {
      width: 15.8%;
      border-radius: 75px;
      background-color: #f3f3f3;
      margin-right: 2px;
      &:hover {
        background-color: #eaeaea;
      }
      i {
        width: 79px;
        background-color: transparent;
      }
    }
  }
}
