.svglist {
  font-size: 16px;
}

.svglist:before {
  content: ' ';
  vertical-align: middle;
  display: inline-block;
  background-image: url('./assets/svglist1479360395898-inner.svg');
  //background-image: url("./assets/practice/practice_right.svg");
  //background-size: 100% 100%;
  //background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 26.25em 296.625em;
}

// .no-svg .svglist:before {
//     background-image: url("");
// }

.svglist.Number_label:before {
  background-position: -0.625em -0.625em;
  width: 3.25em;
  height: 2.125em;
}

.svglist.Onion_coin:before {
  background-position: -0.625em -4em;
  width: 2.25em;
  height: 2.25em;
}

.svglist.Onion_coin_full:before {
  background-position: -0.625em -7.5em;
  width: 2.25em;
  height: 2.25em;
}

.svglist.Social_icon_QQ_group:before {
  background-position: -0.625em -11em;
  width: 4.375em;
  height: 4.375em;
}

.svglist.Social_icon_Wechat:before {
  background-position: -0.625em -16.625em;
  width: 4.375em;
  height: 4.375em;
}

.svglist.Social_icon_Weibo:before {
  background-position: -0.625em -22.25em;
  width: 4.375em;
  height: 4.375em;
}

.svglist.Star-One-full:before {
  background-position: -0.625em -27.875em;
  width: 1.875em;
  height: 2.0625em;
}

.svglist.Star-One-zero:before {
  background-position: -0.625em -31.1875em;
  width: 1.875em;
  height: 2.0625em;
}

.svglist.Star-Two-full:before {
  background-position: -0.625em -34.5em;
  width: 1.875em;
  height: 3.25em;
}

.svglist.Star-Two-part:before {
  background-position: -0.625em -39em;
  width: 1.875em;
  height: 3.25em;
}

.svglist.Star-Two-zero:before {
  background-position: -0.625em -43.5em;
  width: 1.875em;
  height: 3.25em;
}

.svglist.Target_Selected:before {
  background-position: -0.625em -48em;
  width: 2.25em;
  height: 2.25em;
}

.svglist.Target_Star:before {
  background-position: -0.625em -51.5em;
  width: 2.375em;
  height: 2.375em;
}

.svglist.Updating:before {
  background-position: -0.625em -55.125em;
  width: 3.5em;
  height: 1.5em;
}

.svglist.arrow_drop_right:before {
  background-position: -0.625em -57.875em;
  width: 0.3125em;
  height: 0.5em;
}

.svglist.bg-caption:before {
  background-position: -0.625em -59.625em;
  width: 11.25em;
  height: 3.75em;
}

.svglist.close:before {
  background-position: -0.625em -64.625em;
  width: 2em;
  height: 2em;
}

.svglist.coins:before {
  background-position: -0.625em -67.875em;
  width: 1.125em;
  height: 1.125em;
}

.svglist.coins-gray:before {
  background-position: -0.625em -70.25em;
  width: 1.125em;
  height: 1.125em;
}

.svglist.combo:before {
  background-position: -0.625em -72.625em;
  width: 1.875em;
  height: 2.5em;
}

.svglist.down-01:before {
  background-position: -0.625em -76.375em;
  width: 4.375em;
  height: 4.375em;
}

.svglist.heart_gray:before {
  background-position: -0.625em -82em;
  width: 1.875em;
  height: 1.875em;
}

.svglist.heart_red:before {
  background-position: -0.625em -85.125em;
  width: 1.875em;
  height: 1.875em;
}

.svglist.icon_Female:before {
  background-position: -0.625em -88.25em;
  width: 1.125em;
  height: 1.125em;
}

.svglist.icon_Male:before {
  background-position: -0.625em -90.625em;
  width: 1.125em;
  height: 1.125em;
}

.svglist.icon_jifen:before {
  background-position: -0.625em -93em;
  width: 1.5em;
  height: 1.5em;
}

.svglist.icon_star:before {
  background-position: -0.625em -95.75em;
  width: 1.5em;
  height: 1.375em;
}

.svglist.img_arrow:before {
  background-position: -0.625em -98.375em;
  width: 1.25em;
  height: 1em;
}

.svglist.img_connect_QQ:before {
  background-position: -0.625em -100.625em;
  width: 3.6875em;
  height: 4.125em;
}

.svglist.img_stamp_red:before {
  background-position: -0.625em -106em;
  width: 5em;
  height: 5em;
}

.svglist.locked:before {
  background-position: -0.625em -112.25em;
  width: 2.5em;
  height: 2.5em;
}

.svglist.logo:before {
  background-position: -0.625em -116em;
  width: 6em;
  height: 1.475em;
}

.svglist.mascot_failed:before {
  background-position: -0.625em -118.75em;
  width: 15.625em;
  height: 14.375em;
}

.svglist.mascot_pass:before {
  background-position: -0.625em -134.375em;
  width: 15.625em;
  height: 14.375em;
}

.svglist.not-enough-student:before {
  background-position: -0.625em -150em;
  width: 4.6875em;
  height: 3.5em;
}

.svglist.onion_coin_sma:before {
  background-position: -0.625em -154.75em;
  width: 1.5em;
  height: 1.5em;
}

.svglist.practice_failed:before {
  background-position: -0.625em -157.5em;
  width: 8.75em;
  height: 9.375em;
}

.svglist.practice_golden:before {
  background-position: -0.625em -168.125em;
  width: 2.5em;
  height: 2.5em;
}

.svglist.practice_gray:before {
  background-position: -0.625em -171.875em;
  width: 2.5em;
  height: 2.5em;
}

.svglist.practice_pass:before {
  background-position: -0.625em -175.625em;
  width: 8.75em;
  height: 9.375em;
}

.svglist.progress_golden:before {
  background-position: -0.625em -186.25em;
  width: 1.5em;
  height: 0.625em;
}

.svglist.progress_gray:before {
  background-position: -0.625em -188.125em;
  width: 1.5em;
  height: 0.625em;
}

.svglist.qq_group_sma:before {
  background-position: -0.625em -190em;
  width: 1.125em;
  height: 1.125em;
}

.svglist.quiz_failed:before {
  background-position: -0.625em -192.375em;
  width: 8.75em;
  height: 9.375em;
}

.svglist.quiz_golden:before {
  background-position: -0.625em -203em;
  width: 3.125em;
  height: 3.125em;
}

.svglist.quiz_gray:before {
  background-position: -0.625em -207.375em;
  width: 3.125em;
  height: 3.125em;
}

.svglist.quiz_locked:before {
  background-position: -0.625em -211.75em;
  width: 3.125em;
  height: 3.125em;
}

.svglist.quiz_pass:before {
  background-position: -0.625em -216.125em;
  width: 8.75em;
  height: 9.375em;
}

.svglist.quiz_progress_golden:before {
  background-position: -0.625em -226.75em;
  width: 3em;
  height: 1.5em;
}

.svglist.quiz_progress_gray:before {
  background-position: -0.625em -229.5em;
  width: 3em;
  height: 1.5em;
}

.svglist.ribbon_blue:before {
  background-position: -0.625em -232.25em;
  width: 17.5em;
  height: 4.375em;
}

.svglist.ribbon_blue_golden:before {
  background-position: -0.625em -237.875em;
  width: 21.25em;
  height: 4.375em;
}

.svglist.right_answer:before {
  // changeCSS
  background-position: -0.625em -243.5em;
  width: 3.75em;
  height: 3.75em;
  //margin-left: 17px;
}

.svglist.right_answer_sma:before {
  background-position: -0.625em -248.5em;
  width: 1.5em;
  height: 1.5em;
}

.svglist.share_qzone:before {
  background-position: -0.625em -251.25em;
  width: 1.5em;
  height: 1.5em;
}

.svglist.share_sina:before {
  background-position: -0.625em -254em;
  width: 1.5em;
  height: 1.5em;
}

.svglist.social_icon_QQ:before {
  background-position: -0.625em -256.75em;
  width: 1.5em;
  height: 1.5em;
}

.svglist.stars_large:before {
  background-position: -0.625em -259.5em;
  width: 25em;
  height: 5.625em;
}

.svglist.stars_small:before {
  background-position: -0.625em -266.375em;
  width: 13.75em;
  height: 3.75em;
}

.svglist.video_golden:before {
  background-position: -0.625em -271.375em;
  width: 2.5em;
  height: 2.5em;
}

.svglist.video_gray:before {
  background-position: -0.625em -275.125em;
  width: 2.5em;
  height: 2.5em;
}

.svglist.video_pass:before {
  background-position: -0.625em -278.875em;
  width: 8.75em;
  height: 9.375em;
}

.svglist.wrong_answer:before {
  background-position: -0.625em -289.5em;
  width: 3.75em;
  height: 3.75em;
}

.svglist.wrong_answer_sma:before {
  background-position: -0.625em -294.5em;
  width: 1.5em;
  height: 1.5em;
}
