.table-header-class {
  padding: 40px;
}
.left-border {
  border-left: 1px solid #eaeaea;
  border-top-left-radius: 5px !important;
}
.right-border {
  border-right: 1px solid #eaeaea;
  border-top-right-radius: 5px !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.ant-table-thead {
  background-color: #1797eb;
}
.text-hover {
  cursor: pointer;
}
